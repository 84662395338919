import React from 'react'
import axios from 'axios'
import routes from '../routes'
import { SOCIAL_PLATFORMS_NAMES, API_STATUS_CODES } from '../constants/appSettings'
import sessionCacheService from './sessionCacheService'
import { toast } from 'react-toastify'
import { searchContentTypes } from '../constants/search'

import { globalUseDispatch } from '../utils/globalDispatch'
import { changeGrants, logoutUser, showNoPlanModal } from '../redux/ducks/userDucks'
import { LoggedMultipleToast } from '../components/toasts/LoggedMultiple'

const influDataSource = 'influDataApp'

class HttpService {
  constructor(cachedPlatform) {
    this.abortController = null
    this.platform = cachedPlatform || SOCIAL_PLATFORMS_NAMES.instagram.name

    this.searchResultsUrlsMap = {
      [SOCIAL_PLATFORMS_NAMES.instagram.name]: routes.collab.api.getDataIG,
      [SOCIAL_PLATFORMS_NAMES.tiktok.name]: routes.collab.api.getDataTT,
      [SOCIAL_PLATFORMS_NAMES.youtube.name]: routes.collab.api.getDataYT,
      [searchContentTypes.CONTENT]: routes.collab.api.getDataContent,
    }

    this.getRequestWithAbort = this.abortGetDecorator()
    this.postRequestWithAbort = this.abortPostDecorator()
    this.unathorizedCB = null
  }

  // platform change should happen in appSettings actions and the routes will be taken depending on platform
  configurePlatformForApi(newPlatform) {
    this.platform = newPlatform
  }

  registerCallbackForUnathorized(cb) {
    if (!this.unathorizedCB) {
      this.unathorizedCB = cb
    }
  }

  whenConflictRequest() {
    globalUseDispatch(logoutUser())
    toast.error(<LoggedMultipleToast />, { autoClose: false, closeButton: false })
  }

  abortGetDecorator() {
    let cancelToken = axios.CancelToken.source()
    return async function(url) {
      if (cancelToken) cancelToken.cancel()
      if (!url) return
      cancelToken = axios.CancelToken.source()
      return await this.getRequest(url, { cancelToken: cancelToken.token })
    }
  }

  abortPostDecorator() {
    let cancelToken = axios.CancelToken.source()
    return async function(url, data) {
      if (cancelToken) cancelToken.cancel()
      if (!url) return
      cancelToken = axios.CancelToken.source()
      return await this.postRequest(url, { cancelToken: cancelToken.token, ...data })
    }
  }

  // reusable get
  async getRequest(url, options = {}) {
    try {
      const response = await axios.get(url, options)

      return response.data
    } catch (err) {
      if (err.response && err.response.status === API_STATUS_CODES.alreadyExists) {
        this.whenConflictRequest()
      }

      if (err.response && err.response.status === API_STATUS_CODES.notAuthorized) {
        this.unathorizedCB && this.unathorizedCB()
      }
      if (err.response && err.response.status === API_STATUS_CODES.tooManyRequests) {
        return toast.error(
          `You reached the request limit. Please try again later or contact our support.`
        )
      }
      if(err.response && err.response.status === API_STATUS_CODES.upgradeRequired) {
        const isUpgradedRequired = true
        globalUseDispatch(showNoPlanModal(isUpgradedRequired))
        globalUseDispatch(changeGrants({
          no_plan: isUpgradedRequired
        }))
      }
      throw err
    }
  }

  async postRequest(url, data = {}, fullResponse) {
    try {
      const response = await axios.post(url, { ...data })

      return fullResponse ? response : response.data
    } catch (err) {
      if (err?.response?.status === API_STATUS_CODES.alreadyExists) {
        this.whenConflictRequest()
      }

      if (err.response && err.response?.status === API_STATUS_CODES.notAuthorized) {
        this.unathorizedCB && this.unathorizedCB()
      }
      if (err.response && err.response?.status === API_STATUS_CODES.tooManyRequests) {
        return toast.error(
          `You reached the request limit. Please try again later or contact our support.`
        )
      }
      if(err.response && err.response?.status === API_STATUS_CODES.upgradeRequired) {
        const isUpgradedRequired = true
        globalUseDispatch(showNoPlanModal(isUpgradedRequired))
        globalUseDispatch(changeGrants({
          no_plan: isUpgradedRequired
        }))
      }
      throw err
    }
  }

  // service avaliable requests
  async signin(body) {
    const response = await fetch(encodeURI(routes.sign.in), { method: 'POST', body })
    return response
  }

  async signout() {
    return await axios.put(routes.sign.out)
  }

  async deleteAccount(customerId) {
    return await this.postRequest(`${routes.sign.deleteAccount}/${customerId}`)
  }

  async fetchIsTakenEmail(email) {
    const response = await fetch(`${routes.sign.takenEmail}/${email}/false`)
    return response
  }

  async signup(body) {
    const response = await fetch(routes.sign.subscribe, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body,
    })
    return response
  }

  async signupAndStartTrialCampaign(body) {
    const response = await fetch(routes.sign.signupAndStartTrialCampaign, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body,
    })
    return response
  }

  async createNewCustomer({
    username,
    code,
    companyData,
    azureIdToken,
    googleAnalyticsData = {},
    campaignSelfService = false,
    signUpMethod = 'chargebee',
    pass_through,
    locale,
    isDirectTrialAvailable,
  }) {
    return await this.postRequest(routes.collab.api.createNewInfludataCustomer, {
      username,
      azureIdToken,
      code,
      companyData,
      source: influDataSource,
      googleAnalyticsData,
      campaignSelfService,
      signUpMethod,
      pass_through,
      locale,
      isDirectTrialAvailable,  
    })
  }

  async reactivateActiveSubscription({ subId }) {
    return await fetch(`${routes.sign.reactivateActiveSubscription}/${subId}`, {
      method: 'POST',
    })
  }

  async reactivateInactiveSubscription({ subId }) {
    return await fetch(`${routes.sign.reactivateInactiveSubscription}/${subId}`, {
      method: 'POST',
    })
  }

  async forceTrialActivation({ subId }) {
    return await fetch(`${routes.sign.forceImmediateActivation}/${subId}`, { method: 'POST' })
  }

  async fetchCurrentSubscription(username) {
    return await this.getRequest(`${routes.sign.subscriptions}`)
  }

  async fetchAvaliablePlans() {
    return await this.getRequest(routes.sign.plans)
  }

  async fetchPlanUpgrade({ subId, toPlanId, customerId, currentPlan }) {
    return await fetch(`${routes.sign.upgrade}/${subId}/${toPlanId}/${customerId}/${currentPlan}`, {
      method: 'POST',
    })
  }

  async changeSeatsOfTracking({ subId, newSeats }) {
    return await fetch(`${routes.sign.changeSeatsOfTracking}/${subId}/${newSeats}`, {
      method: 'POST',
    })
  }

  async fetchForgetPassword(mail) {
    return await this.postRequest(routes.collab.api.forgetPassword, { mail })
  }

  async fetchResetPassword(body) {
    return await fetch(routes.sign.setPassword, {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      body,
    })
  }

  async fetchStartUpData({ collectionId, collectionPage }) {
    const response = await this.getRequest(
      `${routes.collab.api.getStartupData}?collectionId=${collectionId}&page=${collectionPage}`
    )
    return response
  }

  async fetchSearchResults(
    stringifiedQuery = null,
    currentSearchType = searchContentTypes.CREATOR
  ) {
    const isContentSearchType = currentSearchType === searchContentTypes.CONTENT
    const searchUrl = isContentSearchType ? searchContentTypes.CONTENT : this.platform
    return await this.getRequestWithAbort(
      stringifiedQuery && `${this.searchResultsUrlsMap[searchUrl]}?${stringifiedQuery}`
    )
  }

  async fetchCitiesForCountry(country, options) {
    return await this.getRequest(
      `${routes.collab.api.getCitiesForCountry}?country=${country}`,
      options
    )
  }

  async fetchCountriesCities(countriesStringedArray, options) {
    return await this.getRequest(
      `${routes.collab.api.getCountriesCities}?countries=${countriesStringedArray}`,
      options
    )
  }

  async fetchUserById({ profileId, source }) {
    return await this.getRequest(
      `${routes.collab.api.getUserById}?objectId=${profileId}&source=${source}`
    )
  }

  async changeTypeUserProfile({ profileId, switchBrandCreator }) {
    return await this.getRequest(
      `${routes.collab.api.getUserById}?objectId=${profileId}&switchBrandCreator=${switchBrandCreator}`
    )
  }

  async fetchUserByIdWithToken({ profileId, token, source }) {
    return await this.getRequest(
      `${routes.collab.api.getUserById}?objectId=${profileId}&token=${token}&source=${source}`
    )
  }

  async fetchUserByUsername(username) {
    return await this.getRequestWithAbort(
      `${routes.collab.api.getUserByUsername}?username=${username}`
    )
  }

  async fetchCreateCollection({ collectionName, description, access }) {
    return await this.postRequest(routes.collab.api.createCollection, {
      collectionName,
      description,
      access,
    })
  }

  async fetchAllCollections() {
    return await this.getRequest(routes.collab.api.getAllCollections)
  }

  async fetchSuggestedUsers(inputLetters, userindex, creatorType) {
    const queryString = creatorType
      ? `${routes.collab.api.getSuggestedUsers}?letters=${inputLetters}&index=${userindex}&creatorType=${creatorType}`
      : `${routes.collab.api.getSuggestedUsers}?letters=${inputLetters}&index=${userindex}`

    return await this.getRequest(queryString)
  }

  async fetchAudienceSuggestedUsers(inputLetters, userindex, creatorType) {
    const queryString = creatorType
      ? `${routes.audiences.getUserSuggestionByLetters}?letters=${inputLetters}&index=${userindex}&creatorType=${creatorType}`
      : `${routes.audiences.getUserSuggestionByLetters}?letters=${inputLetters}&index=${userindex}`

    return await this.getRequestWithAbort(queryString)
  }

  async getExploreData({ mentions = undefined, index = 'instagram' }) {
    return await this.getRequestWithAbort(
      `${routes.collab.api.getExploreData}?mentions=${mentions}&index=${index}`
    )
  }

  async fetchCollection(collectionId, collectionPage = 1) {
    return await this.getRequestWithAbort(
      `${routes.collab.api.getCollection}?collectionId=${collectionId}&page=${collectionPage}`
    )
  }

  async fetchEditCollection(collectionId, newValue, description) {
    return await this.postRequest(routes.collab.api.renameCollection, {
      collectionId,
      newValue,
      description,
    })
  }

  async changeOrderCollection(payload) {
    return await this.postRequest(routes.collab.api.changeOrderCollection, {
      collectionIdArray: payload,
    })
  }

  async changeSortCollectionView(payload) {
    return await this.postRequest(routes.collab.api.changeSortCollectionView, { ...payload })
  }

  async deleteCollection(collectionId) {
    return await this.postRequest(routes.collab.api.deleteCollection, { collectionId })
  }

  async addElementToCollection(body) {
    return await this.postRequest(routes.collab.api.addElementToCollection, body)
  }

  async removeElementFromCollection(body) {
    return await this.postRequest(routes.collab.api.removeElementFromCollection, body)
  }

  async fetchCSV(collectionId) {
    return await fetch(`${routes.collab.api.getCSVData}?collectionId=${collectionId}`)
  }

  async fetchUserEmail(id) {
    return await this.getRequest(`${routes.collab.api.getMailAddress}?objectId=${id}`)
  }

  async changeProfileElement({ objectId, changeField, newValue }) {
    return await this.postRequest(routes.collab.api.changeElement, {
      objectId,
      changeField,
      newValue,
    })
  }

  async fetchSimilarUsers({
    userId = '',
    username = '',
    platform = '',
    language = '',
    country = '',
    skipCount = 0,
    abort = false,
  }) {
    if (abort) {
      return await this.getRequestWithAbort()
    }
    return await this.getRequestWithAbort(
      `${routes.collab.api.getSimilarUsers}?objectId=${userId}&username=${username}&skipCount=${skipCount}&platform=${platform}&language=${language}&country=${country}`
    )
  }

  async fetchContactUs(contactData) {
    return await this.postRequest(routes.collab.api.contactUs, { ...contactData })
  }

  async sendFeedback(feedbackData) {
    return await this.postRequest(routes.collab.api.sendFeedback, { ...feedbackData })
  }

  async fetchCreateCampaign(newCampaignData) {
    return await this.postRequest(routes.collab.api.createCampaign, { ...newCampaignData })
  }

  async fetchCampaign(campaignId) {
    return await this.getRequest(`${routes.collab.api.getCampaign}?campaignId=${campaignId}`)
  }

  async fetchDeleteCampaign(campaignId) {
    return await this.postRequest(routes.collab.api.deleteCampaign, { campaignId })
  }

  async fetchEditCampaign(updatedCamapaignData) {
    return await this.postRequest(routes.collab.api.renameCampaign, { ...updatedCamapaignData })
  }

  async addElementToCampaign(campaignData) {
    return await this.postRequest(routes.collab.api.addElementToCampaign, { ...campaignData })
  }

  async removeElementFromCampaign(campaignData) {
    return await this.postRequest(routes.collab.api.removeElementFromCampaign, { ...campaignData })
  }

  async fetchAccountDetails(customerId) {
    return await this.getRequest(`${routes.sign.getProfileDetails}/${customerId}`)
  }
  async updateBillingInfo(billingInfo) {
    return await this.postRequest(routes.sign.updateBillingInfo, { ...billingInfo })
  }
  async getPaymentMethod(customerId) {
    return await this.getRequest(`${routes.sign.managePaymentSources}/${customerId}`)
  }
  async addProfileToInfludata({ profileName, withNotify, platform, _id }) {
    return await fetch(routes.collab.api.addProfileToInfludata, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        username: profileName,
        notification: withNotify,
        platform,
        _id
      }),
    })
  }

  async unlockAudienceAnalysisReport({ objectIds, platform }) {
    return await this.postRequest(routes.collab.api.unlockAudienceAnalysisReport, {
      objectIds,
      platform,
    })
  }
  async fetchComparedUserById(profileId) {
    return await this.getRequest(
      `${routes.collab.api.getComparedUserByIds}?profileIds=${[profileId]}`
    )
  }

  async fetchTimelineForContent(query) {
    return await this.getRequest(`${routes.collab.api.getTimelineForContent}?${query}`)
  }

  async removeTeamMember(email) {
    return await this.postRequest(routes.collab.api.removeTeamMember, { email })
  }

  async changeCollectionAccess({ collectionId, accessType }) {
    return await this.postRequest(routes.collab.api.changeCollectionAccess, {
      collectionId,
      accessType,
    })
  }

  async purchaseOneTimeItems({ subId, addonId }) {
    return await this.postRequest(routes.sign.purchaseOneTimeItems, { subId, addonId })
  }

  async notifyWhenAudienceAnalysisAvailable({ username, userid, platform, notify}) {
    return await this.postRequest(routes.collab.api.addNotification, {
      topic: 'AUDIENCE',
      username,
      userid,
      platform,
      notify
    })
  }

  async addAdditionalField({ label, type, allowedValues = null }) {
    return await this.postRequest(routes.collab.api.addAdditionalField, {
      label,
      type,
      allowedValues,
    })
  }

  async editAdditionalFields({ fieldCode, fieldProperty, newValue }) {
    return await this.postRequest(routes.collab.api.editAdditionalFields, {
      fieldCode,
      fieldProperty,
      newValue,
    })
  }

  async getGoogleLocationByLetters({ letters }) {
    return await this.getRequestWithAbort(
      `${routes.collab.api.getGoogleLocationByLetters}?letters=${letters}`
    )
  }

  async addSavedContentToCollection({ contentIds }) {
    return await this.postRequest(routes.collab.api.addElementToCollection, { contentIds })
  }

  async removeSavedContentFromCollection({ contentIds }) {
    return await this.postRequest(routes.collab.api.removeElementFromCollection, { contentIds })
  }

  async getDataCampaignDashboard({ campaignId, dashboardId }) {
    return await this.getRequestWithAbort(
      `${routes.collab.api.getCampaignDashboard}?campaignId=${campaignId}&selectedDashboard=${dashboardId}`
    )
  }

  async getPreviewDataCampaignDashboard({ campaignId, dashboardId, dashboardToken }) {
    return await this.getRequestWithAbort(
      `${routes.collab.api.getPreviewCampaignDashboard}?campaignId=${campaignId}&dashboardId=${dashboardId}&dashboardToken=${dashboardToken}`
    )
  }

  async getAllNewCampaigns() {
    return await this.getRequest(routes.collab.api.getAllNewCampaigns)
  }

  async getNewCampaignById({ campaignId }) {
    return await this.getRequest(`${routes.collab.api.getNewCampaignById}?campaignId=${campaignId}`)
  }

  async getContentList({
    campaignId,
    page,
    startingDate,
    endingDate,
    selectedCreators,
    filterContentTypes,
  }) {
    const baseUrl = `${routes.collab.api.getContentList}?campaignId=${campaignId}&page=${page}`
    let query = ''

    if (selectedCreators) {
      query = `&creatorArray=${selectedCreators}`
    }

    if (filterContentTypes) {
      query += `&filterContentTypes=${filterContentTypes}`
    }

    if (startingDate && endingDate) {
      query += `&startingDate=${startingDate}&endingDate=${endingDate}`
    } else if (startingDate) {
      query += `&startingDate=${startingDate}`
    } else if (endingDate) {
      query += `&endingDate=${endingDate}`
    }

    const fullQuery = query ? `${baseUrl}${query}` : baseUrl
    return await this.getRequestWithAbort(fullQuery)
  }

  async editContent(obj) {
    return await this.postRequest(`${routes.collab.api.editContent}`, obj)
  }

  async getCreatorList({ campaignId }) {
    return await this.getRequest(`${routes.collab.api.getCreatorList}?campaignId=${campaignId}`)
  }

  async addCreatorToCampaign({ creatorId, campaignId, defaultAxiosFetch = false }) {
    if (defaultAxiosFetch) {
      return await axios.post(`${routes.collab.api.addCreatorToCampaign}`, {
        creatorId,
        campaignId,
      })
    } else {
      return await this.postRequestWithAbort(`${routes.collab.api.addCreatorToCampaign}`, {
        creatorId,
        campaignId,
      })
    }
  }

  async changeCreator(obj) {
    return await this.postRequest(routes.collab.api.changeCreator, obj)
  }

  async getCalendarData({ campaignId }) {
    return await this.getRequest(`${routes.collab.api.getCalendarData}?campaignId=${campaignId}`)
  }

  async addEditCalendarData(obj) {
    return await this.postRequest(`${routes.collab.api.addEditCalendarData}`, obj)
  }

  async getCampaignGoals({ campaignId }) {
    return await this.getRequest(`${routes.collab.api.getCampaignGoals}?campaignId=${campaignId}`)
  }

  async addChangeRemoveCampaignGoals(objPayload) {
    return await this.postRequest(routes.collab.api.editCampaignGoals, objPayload)
  }

  async createNewCampaign(obj) {
    return await this.postRequest(`${routes.collab.api.createNewCampaign}`, obj)
  }

  async createNewCampaignWithStatusCode(obj) {
    return await axios.post(`${routes.collab.api.createNewCampaign}`, obj)
  }

  async editCampaignSettings(obj) {
    return await this.postRequest(`${routes.collab.api.editCampaignById}`, obj)
  }

  async getTrackedMetrics({ campaignId }) {
    return await this.getRequest(`${routes.collab.api.getTrackedMetrics}?campaignId=${campaignId}`)
  }

  async editTrackedMetrics(obj) {
    return await this.postRequest(routes.collab.api.editTrackedMetrics, obj)
  }

  async getCustomFields({ campaignId }) {
    return await this.getRequest(`${routes.collab.api.getCustomFields}?campaignId=${campaignId}`)
  }

  async editCustomFields(obj) {
    return await this.postRequest(routes.collab.api.editCustomFields, obj)
  }

  async addCustomFieldValueManually(obj) {
    return await this.postRequest(routes.collab.api.addCustomFieldValueManually, obj)
  }

  async removeContentFromTracking(obj) {
    return await this.postRequest(routes.collab.api.removeContentFromTracking, obj)
  }

  async editNotifications(obj) {
    return await this.postRequest(routes.collab.api.editNotifications, obj)
  }

  async getNonRecognizedContent({ creatorId, campaignId, filterContentTypes }) {
    const baseUrl = `${routes.collab.api.getNonRecognizedContent}?campaignId=${campaignId}`
    let query = ''

    if (creatorId) {
      query = `&creatorId=${creatorId}`
    }

    if (filterContentTypes) {
      query += `&filterContentTypes=${filterContentTypes}`
    }

    const fullQuery = query ? `${baseUrl}${query}` : baseUrl
    return await this.getRequestWithAbort(fullQuery)
  }

  async addContentToRecognizedContent({ contentIds, campaignId }) {
    return await this.postRequest(routes.collab.api.addContentToRecognizedContent, {
      contentIds,
      campaignId,
    })
  }

  async adjustCPMValues(obj) {
    return await this.postRequest(routes.collab.api.adjustCPMValues, { cpmValues: obj })
  }

  async adjustCPMValuesForCampaign(obj, campaignId) {
    return await this.postRequest(routes.collab.api.adjustCPMValuesForCampaign, { cpmValues: obj, campaignId })
  }

  async editDashboards({
    selectedCreators,
    startingDate,
    endingDate,
    campaignId,
    visibleGraphElements,
    dashboardId,
    method,
    isShared,
  }) {
    return await this.postRequest(routes.collab.api.editDashboards, {
      selectedCreators,
      startingDate,
      endingDate,
      visibleGraphElements,
      campaignId,
      dashboardId,
      method,
      isShared,
    })
  }

  async getPipelineData({ campaignId }) {
    return await this.getRequest(`${routes.collab.api.getPipelineData}?campaignId=${campaignId}`)
  }

  async addEditColumnPipeline(obj) {
    return await this.postRequest(`${routes.collab.api.addEditPipelineColumns}`, obj)
  }

  async addEditPipelineCards(obj) {
    return await this.postRequest(`${routes.collab.api.addEditPipelineCards}`, obj)
  }

  async addEditPipelineData(obj) {
    return await this.postRequest(`${routes.collab.api.addEditPipelineData}`, obj)
  }

  async addRemovePipelineComment(obj) {
    return await this.postRequest(`${routes.collab.api.addRemovePipelineComment}`, obj)
  }

  async addProfilesToOverlap(obj) {
    return await this.postRequest(`${routes.collab.api.addProfilesToOverlap}`, obj)
  }

  async getAllOverlaps() {
    return await this.getRequest(routes.collab.api.getAllOverlaps)
  }

  async removeDemoCampaign() {
    return await this.postRequest(routes.collab.api.removeDemoCampaign)
  }

  async getDataContentProfile(stringifiedQuery) {
    if (!stringifiedQuery) return
    return await this.getRequest(`${routes.collab.api.getDataContent}?${stringifiedQuery}`)
  }

  async requestSignupToken({ email }) {
    return await this.postRequest(routes.collab.api.requestSignupToken, { email })
  }

  async verifySignupToken({ email, code }) {
    return await this.postRequest(routes.collab.api.verifySignupToken, { email, code })
  }

  async createNewTeamMember({ email, role }) {
    return await this.postRequest(routes.collab.api.createNewTeamMember, { email, role })
  }

  async changeTeamMemberRole({ email, newRole }) {
    return await this.postRequest(routes.collab.api.changeTeamMemberRole, { email, role: newRole })
  }

  async addMoreSignupInfo({ customerId, platforms, features, businessType }) {
    return await this.postRequest(routes.collab.api.addMoreSignupInfo, {
      customerId,
      platforms,
      features,
      businessType,
    })
  }

  async getContentDownloadLinks({ platform, id }) {
    return await this.getRequest(
      `${routes.collab.api.getContentDownloadLinks}?platform=${platform}&code=${id}`
    )
  }

  async getSuggestedUsersById({ queryId }) {
    return await this.getRequest(`${routes.collab.api.getSuggestedUsersById}?id=${queryId}`)
  }

  async buildLookalike({ platform = 'instagram', label, userList = [] }) {
    return await this.postRequest(routes.collab.api.buildLookalike, {
      platform,
      userList,
      label,
    })
  }

  async deleteLookalikeList({ id }) {
    return await this.postRequest(routes.collab.api.deleteLookalikeList, {
      id,
    })
  }

  async catchOMR(obj) {
    return await this.postRequest(routes.collab.api.catchOMR, obj)
  }

  async audiencesLogin(magicLinkId) {
    return await this.getRequest(routes.audiences.login, {
      headers: {
        Authorization: `Bearer ${magicLinkId}`,
      },
    })
  }
  async audiencesGetReport({ instagramId = '', username = '', teamId = '' }) {
    let url = `${routes.audiences.getReport}`

    if (instagramId && username) {
      url += `?instagramId=${instagramId}&username=${username}`
    } else if (instagramId) {
      url += `?instagramId=${instagramId}`
    } else if (username) {
      url += `?username=${username}`
    }
    if (teamId) {
      url += `&teamId=${teamId}`
    }

    return await this.getRequest(url)
  }

  async joinTeam({ magicId = '', joinCode = '' }) {
    let url = `${routes.audiences.joinTeam}`
    return await this.postRequest(url, { magicId, joinCode })
  }

  async renewSubscriptionNow() {
    return await this.postRequest(routes.audiences.renewSubscriptionNow)
  }

  async createCampaignByCopy({ campaignId, newCampaignName }) {
    return await this.postRequest(routes.collab.api.createCampaignByCopy, {
      campaignId,
      newCampaignName,
    })
  }

  async getSearchBySmartText({ inputParam, platform, method }) {
    return await this.getRequest(`${routes.collab.api.getSearchBySmartText}?inputParam=${inputParam}&platform=${platform}&method=${method}`)
  }

  async receiveSurveyResult({ inputParam, platform, method, result }) {
    return await this.postRequest(routes.collab.api.receiveSurveyResult, {
      inputParam,
      platform,
      method,
      result
    })
  }

  async enforceSameAzureTenant({ isEnforced = false }) {
    return await this.postRequest(routes.collab.api.enforceSameAzureTenant, 
      {
        isEnforced
      }
    )
  }

  async getLocationByIp({ ip = '' }) {
    return await this.getRequest(`${routes.collab.api.getLocationByIp}?ip=${ip}`)
  }
  
  async searchCampaignContentByLink({ campaignId, link }) {
    return await this.getRequest(`${routes.collab.api.searchCampaignContentByLink}?campaignId=${campaignId}&link=${link}`)
  }

  async forceImmediateActivation() {
    return await this.postRequest(routes.chargebee.forceImmediateActivation, { validateStatus: () => true }, true)
  }

  async cancelSubscriptionAccountSettings() {
    return await this.postRequest(routes.chargebee.cancelSubscription, { validateStatus: () => true }, true)
  }

  async removeScheduledCancellation() {
    return await this.postRequest(routes.chargebee.removeScheduledCancellation, { validateStatus: () => true }, true)
  }

  async reactivateCancelledSubscription (subId) {
    return await this.postRequest(routes.chargebee.reactivateCancelledSubscription,{
      subId
    })
  }
  
  async uploadFileGetUrlForSimilarContent(formData) {
    return await axios.post(routes.collab.api.uploadFileGetUrlForSimilarContent, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
  }

  async pullNotifications() {
    return await this.getRequest(routes.collab.api.pullNotifications)
  }

  async markNotificationsAsSeen({ location = '' }) {
    return await this.postRequest(routes.collab.api.markNotificationsAsSeen, { location })
  }

  async getLookalikeLists() {
    return await this.getRequest(routes.collab.api.getLookalikeLists)
  }
  
  async requestChangeEmail({ email }) {
    return await this.postRequestWithAbort(routes.collab.api.requestChangeEmail, { email })
  }

  async verifyChangeEmail({ token }) {
    return await this.postRequest(routes.collab.api.verifyChangeEmail, { token }, true)
  }

  async getTeamMembersAndSeats() {
    return await this.getRequest(routes.collab.api.getTeamMembersAndSeats)
  }

  async getAllOrganizations() {
    return await this.getRequest(routes.collab.api.getAllOrganizations)
  }

  async createNewOrganization({ companyName = '' }) {
    return await this.postRequest(routes.collab.api.createNewOrganization, { companyName })
  }

  async switchOrganizationalAccount({ orgId = '' }) {
    return await this.postRequest(routes.sign.switchOrganizationalAccount, { orgId })
  }
}

const cachedPlatform = sessionCacheService.getCachedSocialPlatform()

export default new HttpService(cachedPlatform)
