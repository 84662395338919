import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Select, MenuItem, Checkbox, ListItemText, FormControl } from '@mui/material'
import { ADDITIONAL_FIELD_PROP_TYPE } from '../../../../constants/additionalFields'

const AdditionalFieldMultiSelect = ({ additionalFieldData, onChange }) => {
  const { value: selectedOptionIds, allowedValues: options } = additionalFieldData

  const [selectedOptions, setSelectedOptions] = useState(selectedOptionIds || [])

  useEffect(() => {
    setSelectedOptions(selectedOptionIds || [])
  }, [selectedOptionIds])

  const handleChange = event => {
    const {
      target: { value },
    } = event
    setSelectedOptions(value)
    onChange(value)
  }

  return (
    <FormControl size="small" fullWidth sx={{ height: '31px' }}>
      <Select
        size="small"
        multiple
        value={selectedOptions}
        onChange={handleChange}
        renderValue={selected =>
          selected?.map(id => options.find(option => option.id === id)?.value).join(', ')
        }
        sx={{
          height: '31px',
          color: '#442424',
          fontWeight: 600,
          fontSize: '15px',
          fontFamily: 'Inter',
          '& svg': {
            fill: '#442424',
          },

          '&:focus': {
            border: '1px solid #F4E8E8',
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #F4E8E8',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #F4E8E8',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #F4E8E8',
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: '#fff9f9',
              color: '#442424',
              fontFamily: 'Inter',
              '& svg': {
                fill: '#442424',
              },
              '& .MuiTypography-root': {
                fontSize: '15px',
                fontFamily: 'Inter',
              },
            },
          },
        }}
      >
        {options?.map(option => (
          <MenuItem sx={{ color: '#442424' }} disableGutters key={option.id} value={option.id}>
            <Checkbox checked={selectedOptions.indexOf(option.id) > -1} />
            <ListItemText primary={option.value} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

AdditionalFieldMultiSelect.propTypes = {
  additionalFieldData: ADDITIONAL_FIELD_PROP_TYPE.isRequired,
  onChange: PropTypes.func,
}

export default AdditionalFieldMultiSelect
