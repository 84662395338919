import React, { useState } from 'react'
import {
  Stack,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  Button,
  Box,
  Alert,
} from '@mui/material'
import PropTypes from 'prop-types'
import { validateEmail } from '../../utils'
import useTranslation from '../../localization/useTranslation'
import { useGoogleLogin } from '@react-oauth/google'
import { useMsal } from '@azure/msal-react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useDispatch } from 'react-redux'
import { setUserAuthError } from '../../redux/ducks/userDucks'
import { LOGIN_REQUEST } from '../../authConfig'
import Iconify from '../../new-ui/components/iconify'
import axios from 'axios'
import { NavbarInfludataIcon } from '../../new-ui/components/navbar/navbarIcons'

const StylesFormTitle = {
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '18.8px',
  textAlign: 'center',
  fontFamily: 'Inter',
  background: `linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)`,
  backgroundClip: `text`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  py: 0.5,
}

const StyledLoginBtn = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '16px',
  color: '#FFF5F5',
  fontFamily: 'Inter',
  minWidth: '100px',
  width: 'fit-content',
  height: '46px',
  textTransform: 'uppercase',
  border: 'none',
  background:
    'transparent linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)',
}

const LoginForm = ({ loginUser }) => {
  const dispatch = useDispatch()
  const { labelStrings } = useTranslation()
  const { instance } = useMsal()

  const [formDataObj, setFormDataObj] = useState({
    email: '',
    password: '',
    isValidEmail: true,
    isValidPassword: true,
    showPassword: false,
    backendLoginFail: false,
  })

  const changeFormDataObj = newValueObj => setFormDataObj({ ...formDataObj, ...newValueObj })

  const onEmailChange = ({ target }) => changeFormDataObj({ email: target.value })
  const onPasswordChange = ({ target }) => changeFormDataObj({ password: target.value })
  const toggleShowPassword = () => changeFormDataObj({ showPassword: !formDataObj.showPassword })

  const validatePassword = () => !!formDataObj.password

  const resetValidation = ({ type }) =>
    (!formDataObj[type] || formDataObj.backendLoginFail) &&
    changeFormDataObj({ [type]: true, backendLoginFail: false })

  const resetEmailValidation = () => resetValidation({ type: 'isValidEmail' })
  const resetPasswordValidation = () => resetValidation({ type: 'isValidPassword' })

  const onSubmit = async e => {
    e.preventDefault()
    if (formDataObj.backendLoginFail) return
    const isValidEmail = !!formDataObj.email && validateEmail(formDataObj.email.trim())
    const isValidPassword = !!formDataObj.password && validatePassword()

    if (!isValidEmail || !isValidPassword) {
      changeFormDataObj({ isValidEmail, isValidPassword })
      return
    }

    const { email, password } = formDataObj
    dispatch(loginUser({ email: email.toLowerCase().trim(), password, method: 'chargebee' }))
  }

  const onSubmitGoogle = async googleData => {
    const token = googleData.access_token
    const userInfo = await axios
      .get('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => res.data)

    dispatch(loginUser({ email: userInfo.email, password: token, method: 'google' }))
  }

  const handleAzureLogin = async () => {
    try {
      const signupResponse = await instance.loginPopup(LOGIN_REQUEST)
      const { accessToken, account } = signupResponse || {}
      const { idToken, username } = account || {}

      const userEmail = validateEmail(username) ? username : ''

      dispatch(loginUser({ email: userEmail, password: idToken, method: 'azuread' }))
    } catch (err) {
      console.error(err)
    }
  }

  const errResponseGoogle = response => {
    dispatch(setUserAuthError(!!response))
    // console.log('ERROR WITH GOOGLE SIGNUP', response)
    // send the token to your server for verification
    // fetch('/api/signup', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ token: response.tokenId }),
    // });
  }

  const googleLogin = useGoogleLogin({
    onSuccess: onSubmitGoogle,
    onError: errResponseGoogle,
  })

  return (
    <>
      <Box sx={{ pt: 5, px: 3 }}>
        {formDataObj.backendLoginFail && (
          <Alert
            sx={{
              fontSize: '12px',
              padding: '2px 6px',
              '& .MuiAlert-message': {
                padding: '2px 0',
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'pre-line',
              },
              '& .MuiAlert-icon': {
                display: 'flex',
                alignItems: 'center',
                marginRight: '8px',
              },
            }}
            variant="outlined"
            severity="error"
          >
            {labelStrings.loginFail}
          </Alert>
        )}
      </Box>
      <Stack alignItems="center" sx={{ py: 2 }} spacing={1}>
        <Box>
          <NavbarInfludataIcon width={43} height={59} />
        </Box>
        <Typography sx={StylesFormTitle}>{labelStrings.loginToInfludata}</Typography>
      </Stack>

      <form
        onSubmit={onSubmit}
        style={{
          display: 'flex',
          flexDirection: `column`,
          justifyContent: `space-around`,
          marginTop: '28px',
          width: `100%`,
        }}
      >
        <Stack sx={{ px: 3, pb: 2 }} spacing={1}>
          <Stack spacing={1}>
            <TextField
              size="small"
              sx={{
                width: '100%',
                borderRadius: '8px',
                borderColor: '#A06E6E',
                backgroundColor: '#FDFCFF',
                '& .MuiInputLabel-root': {
                  fontFamily: 'Inter',
                  color: '#A77D7D !important',
                  '&:focused, &:active': {
                    color: '#A77D7D',
                  },
                },
                '& .MuiOutlinedInput-root': {
                  height: '46px',
                  '& fieldset': {
                    borderColor: '#A06E6E',
                  },
                  '&:hover fieldset': {
                    borderColor: '#A06E6E',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#A06E6E',
                  },
                },
              }}
              label={labelStrings.email}
              id="email_input"
              type="text"
              onChange={onEmailChange}
              onFocus={resetEmailValidation}
              value={formDataObj.email}
              error={!formDataObj.isValidEmail}
              helperText={!formDataObj.isValidEmail ? labelStrings.notValidEmail : null}
            />

            <TextField
              size="small"
              sx={{
                width: '100%',
                borderRadius: '8px',
                borderColor: '#A06E6E',
                backgroundColor: '#FDFCFF',
                '& .MuiInputLabel-root': {
                  fontFamily: 'Inter',
                  color: '#A77D7D !important',
                  '&:focused, &:active': {
                    color: '#A77D7D',
                  },
                },
                '& .MuiOutlinedInput-root': {
                  height: '46px',
                  '& fieldset': {
                    borderColor: '#A06E6E',
                  },
                  '&:hover fieldset': {
                    borderColor: '#A06E6E',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#A06E6E',
                  },
                },
              }}
              label={labelStrings.password}
              id="password_input"
              type={formDataObj.showPassword ? 'text' : 'password'}
              onChange={onPasswordChange}
              onFocus={resetPasswordValidation}
              value={formDataObj.password}
              error={!formDataObj.isValidPassword}
              helperText={!formDataObj.isValidPassword ? labelStrings.passwordCantBeEmpty : null}
              inputProps={{ maxLength: 40 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ color: '#784E4E' }}
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={e => e.preventDefault()}
                      edge="end"
                    >
                      {formDataObj.showPassword ? <VisibilityOff /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack sx={{ width: '100%' }} direction="row" alignItems="end" justifyContent="end">
            <Button size="small" sx={StyledLoginBtn} variant="contained" type="submit">
              {labelStrings.login}
            </Button>
          </Stack>
        </Stack>

        <Stack sx={{ width: '100%' }} justifyContent="center" alignItems="center">
          <Divider sx={{ height: '1px', width: '100%', borderColor: '#FBEBEB' }} />

          <Stack spacing={1} sx={{ my: 1.5 }} alignItems="center" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => googleLogin()}
              startIcon={<Iconify width={22} icon={'flat-color-icons:google'} />}
              sx={{
                minWidth: '221px',
                height: '46px',
                fontSize: '15px',
                fontFamily: 'Inter',
                borderRadius: '60px',
                border: 'unset',
                color: '#1F1F1F',
                backgroundColor: '#F2F2F2',
                fontWeight: 600,
                '&:hover': {
                  border: 'unset',
                  opacity: 0.7,
                },
              }}
            >
              {labelStrings.continueWithGoogle}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleAzureLogin}
              startIcon={<Iconify width={16} icon={'devicon:azure'} />}
              sx={{
                minWidth: '221px',
                height: '46px',
                fontSize: '15px',
                fontFamily: 'Inter',
                borderRadius: '60px',
                border: 'unset',
                color: '#1F1F1F',
                backgroundColor: '#F2F2F2',
                fontWeight: 600,
                '&:hover': {
                  border: 'unset',
                  opacity: 0.7,
                },
              }}
            >
              {labelStrings.signInAzureAdLabel}
            </Button>
          </Stack>
        </Stack>
      </form>
    </>
  )
}

LoginForm.propTypes = {
  loginUser: PropTypes.func,
}

export default LoginForm
