import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import {
  userGrantSelector,
  isUserAuthenticatedSelector,
  isUserDetailsPresentSelector,
  isUserDetailsLoadingSelector,
  // isSubUserSelector,
  userRolesSelector,
} from "../../redux/selectors"
import { withReduxActions } from "../../hocs/withRedux"
import { getUserDetails } from "../../redux/ducks/userDucks"

import DeleteAccountContainer from "./deleteAccount/DeleteAccountContainer"
import AccountSettingsContainer from "./accountSettings/AccountSettingsContainer"
import BillingDetailsContainer from "./billingDetails/BillingDetailsContainer"
import PaymentsContainer from "./payments/PaymentsContainer"
import AddOnsContainer from "./addOns/AddOnsContainer"
import SubUsersContainer from "./subUsers/SubUsersContainer"
import SubUserSettingsTip from "./SubUserSettingsTip"
import {
  SectionsDivider,
  SettingsContainerWrapper,
} from "../common/styledWrappers"

import { Box } from '@mui/material';
import OrganizationsContainer from "./organizations/OrganizationsContainer"


const SettingsContainer = ({ pageId = "", getUserDetails }) => {
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const {
    package_free: isFreeUser,
    no_plan: isNoPlan,
    no_payment_method,
    in_trial_infludata,
    non_renewing_infludata,
    ...otherGrants
  } = useSelector(userGrantSelector)
  const roles = useSelector(userRolesSelector)

  const isShowOrganizations = roles?.includes('owner')

  const isCanceledStatus = Object.keys(otherGrants)?.some((grant) =>
    grant?.startsWith("cancelled_"),
  )

  const isUserDetailsPresent = useSelector(isUserDetailsPresentSelector)
  const isUserDetailsLoading = useSelector(isUserDetailsLoadingSelector)
  // const isSubUser = useSelector(isSubUserSelector)

  const [isOpenEditDetails, setIsOpenEditDetails] = useState(false)

  const showDeleteAccount = (in_trial_infludata && non_renewing_infludata) || (roles && roles.includes('member') || (isNoPlan && no_payment_method))

  useEffect(() => {
    if (isUserAuthenticated) {
      !isUserDetailsPresent && !isUserDetailsLoading && getUserDetails()
    }
  }, [isUserAuthenticated])

  return (
    <Box sx={{ backgroundColor: 'white', paddingTop: '30px' }}>
      <SettingsContainerWrapper>
        <>
          {isUserAuthenticated && (
            <>
              <AccountSettingsContainer
                isCanceledStatus={isCanceledStatus}
                setIsOpenEditDetails={setIsOpenEditDetails}
              />
              {roles && roles?.includes('admin') && (
                <>
                  <BillingDetailsContainer isOpenEditDetails={isOpenEditDetails} />
                  <PaymentsContainer />
                </>
              )}
              {false && !isFreeUser && roles?.includes('admin') && <AddOnsContainer />}
              {!isFreeUser && roles?.includes('admin') && <SubUsersContainer />}
              {!isFreeUser && isShowOrganizations && <OrganizationsContainer /> }
            </>
          )}
          {roles && !roles?.includes('admin') && <SubUserSettingsTip />}
        </>
        <SectionsDivider width="90%" />
        {isUserAuthenticated && (
          <>
            {showDeleteAccount && (
              <>
                <SectionsDivider width="90%" />
                <DeleteAccountContainer />
              </>
            )}
          </>
        )}
      </SettingsContainerWrapper>
    </Box>
  )
}

SettingsContainer.propTypes = {
  pageId: PropTypes.string,
  getUserDetails: PropTypes.func,
}

export default withReduxActions({ getUserDetails })(SettingsContainer)
