import React from 'react'
import styled from 'styled-components'
import useTranslation from '../../../../../localization/useTranslation'
import { FAV_COLLECTION_NAME } from '../../../../../constants/collections'
import { NewAddPersonToCollectionIcon } from '../../../../../components/common/icons'
import WithTip from '../../../../../components/common/popups/WithTip'
import WithUpgradeTip from '../../../../../components/common/popups/WithUpgradeTip'
import AddToCollectionModal from '../../../../../components/collections/collectionTools/AddToCollectionModal'
import EditCollectionModal from '../../../../../components/collections/collectionTools/EditCollectionModal'
import { shallowEqual, useSelector } from 'react-redux'
import { collectionsSelector } from '../../../../../redux/selectors'

const IconWrapper = styled.span`
  position: relative;
  ${props => props.count && props.theme.withBubbledCouter(props)}

  ${props => !props.enableCollections && props.theme.disableBlock};
`

const ProfileCollectionsTool = ({
  user,
  enableCollections,
  isUserAuthenticated,
  isQuickPreview,

  isAddToCollectionOpened,
  isCreateCollectionOpened,
  setIsAddToCollectionOpened,
  setIsCreateCollectionOpened,
}) => {
  const { labelStrings } = useTranslation()

  const collectionArray = useSelector(collectionsSelector, shallowEqual)

  const currentAddedCollectionsCount = !!user?.collectionArray?.length
    ? [...new Set(user?.collectionArray)]?.filter(c => !c?.startsWith(FAV_COLLECTION_NAME))?.length
    : 0 //excude "fav", because we have "hearts" for favorites

  const AddIcon = (
    <IconWrapper
      enableCollections={enableCollections}
      count={currentAddedCollectionsCount}
      userindex={user._index}
    >
      <NewAddPersonToCollectionIcon userindex={user._index} isActiveIcon={false} />
    </IconWrapper>
  )

  return (
    <>
      {enableCollections ? (
        <div onClick={() => setIsAddToCollectionOpened(true)}>
          <WithTip
            tipText={labelStrings.addToCollections}
            userindex={user._index}
            position={isQuickPreview ? 'bottom center' : 'top center'}
            arrow={isQuickPreview ? false : true}
          >
            {AddIcon}
          </WithTip>
        </div>
      ) : (
        <WithUpgradeTip
          tipText={labelStrings.addUserToCollections}
          userindex={user._index}
          withSignup={!isUserAuthenticated}
          position={isQuickPreview ? 'bottom center' : 'top center'}
          arrow={isQuickPreview ? false : true}
        >
          {AddIcon}
        </WithUpgradeTip>
      )}
      {isAddToCollectionOpened && (
        <AddToCollectionModal
          profile={user}
          platform={user._index}
          collectionArray={collectionArray}
          collectionsList={user.collectionArray}
          closeModal={() => setIsAddToCollectionOpened(false)}
          openCreateCollectionModal={() => {
            setIsCreateCollectionOpened(true)
            setIsAddToCollectionOpened(false)
          }}
          withoutPortal
        />
      )}

      {isCreateCollectionOpened && (
        <EditCollectionModal
          isOpened={isCreateCollectionOpened}
          platform={user._index}
          isNewCollection
          closeModal={() => setIsCreateCollectionOpened(false)}
          withoutPortal
        />
      )}
    </>
  )
}

export default ProfileCollectionsTool
