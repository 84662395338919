import React, { useState } from 'react'
import { Stack, Typography, Button, Link, Divider, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextField, Tooltip } from '@mui/material'
import { fDate } from '../../../utils/formatTime'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useDispatch, useSelector } from 'react-redux'
import { onJoinTeam } from '../../../../redux/ducks/audienceReports'


export const Footer = props => {
  const { reports, onUserLogout, setIsRenderUnlockedReports } = props || {}
  const subscriptionsDate = reports && reports?.subscriptionStatus?.renewalDate

  const [isLinkClicked, setIsLinkClicked] = useState(false)
  const [openPopup, setOpenPopup] = useState(false)
  const [openJoinTeamModal, setOpenJoinTeamModal] = useState(false)
  const [joinTeamCode, setJoinTeamCode] = useState('')
  const dispatch = useDispatch()

  const handleJoinTeam = () => {
    // Perform the necessary action to join the team using the entered code
    // console.log('Joining team with code:', joinTeamCode);
    
    dispatch(onJoinTeam({ magicId: reports?.magicLinkId, joinCode: joinTeamCode }))
      .then(() => {        
        // Refresh the page after the request is done
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error joining team:', error);
        // Handle the error if needed
      });
    setOpenJoinTeamModal(false);
  };

  const handleSubscriptionClick = () => {
    if (!isLinkClicked) {
      setIsLinkClicked(true)
      window.open('https://infludata-audience.chargebeeportal.com', '_blank', 'noopener,noreferrer')
    }
  }
  const handleCopyCode = () => {
    navigator.clipboard.writeText(reports.magicLinkId?.replace('did:ethr:', ''))
    // Optionally, you can show a success message or toast notification here
  }

  return (
    <Stack spacing={1}>
      <Divider sx={{ width: '100%', height: '1px', borderColor: '#784E4E' }} />
      <Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              onClick={onUserLogout}
              disableFocusRipple
              sx={{
                color: '#784E4E',
                fontWeight: 600,
                '&:hover': { backgroundColor: 'transparent' },
              }}
              type="text"
            >
              Logout
            </Button>
            {reports?.hasChargebeeId ? (
              <Button
                disableFocusRipple
                href="javascript:void(0)"
                data-cb-type="portal"
                onClick={handleSubscriptionClick}
                sx={{
                  color: '#784E4E',
                  fontWeight: 600,
                  fontSize: '14px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                Manage Subscription
              </Button>
            ) : reports?.belongsToMagicId ? (
              <Tooltip title="Only your main account is able to edit your subscription">
                <span>
                  <Button
                    disabled
                    sx={{
                      color: 'rgba(0, 0, 0, 0.26)',
                      fontWeight: 600,
                      fontSize: '14px',
                      cursor: 'not-allowed',
                    }}
                  >
                    Manage Subscription
                  </Button>
                </span>
              </Tooltip>
            ) : ''}
            <Button
              sx={{
                color: '#784E4E',
                fontWeight: 600,
                fontSize: '14px',
                '&:hover': {
                  textDecoration: 'none',
                  color: '#784E4E',
                  backgroundColor: 'transparent',
                },
              }}
              onClick={() => setIsRenderUnlockedReports(true)}
            >
              Your Reports
            </Button>
            {reports?.hasChargebeeId && (
              <Button
                sx={{
                  color: '#784E4E',
                  fontWeight: 600,
                  fontSize: '14px',
                  '&:hover': {
                    textDecoration: 'none',
                    color: '#784E4E',
                    backgroundColor: 'transparent',
                  },
                }}
                onClick={() => setOpenPopup(true)}
              >
                Invite Team Members
              </Button>
            )}
          {!reports?.hasChargebeeId && !reports?.belongsToMagicId && (
            <Button
              sx={{
                color: '#784E4E',
                fontWeight: 600,
                fontSize: '14px',
                '&:hover': {
                  textDecoration: 'none',
                  color: '#784E4E',
                  backgroundColor: 'transparent',
                },
              }}
              onClick={() => setOpenJoinTeamModal(true)}
            >
              Join Team
            </Button>
          )}
            <Button
              sx={{
                color: '#784E4E',
                fontWeight: 600,
                fontSize: '14px',
                '&:hover': {
                  textDecoration: 'none',
                  color: '#784E4E',
                  backgroundColor: 'transparent',
                },
              }}
              component="a" // Use 'a' element for anchor behavior
              href="mailto:johannes@wecreate.media" // Set href to mailto link
              target="_blank" // Optionally open in a new tab
              rel="noopener noreferrer" // Security reasons when using target='_blank'
            >
              Contact Us
            </Button>
          </Stack>
          <Stack>
            <Typography
              sx={{ color: '#784E4E', fontWeight: 600, fontSize: '14px' }}
            >{`You have ${reports?.reportsLeft || 0} Reports left.`}</Typography>
            {subscriptionsDate && (
              <Typography
                sx={{ color: '#784E4E', fontWeight: 600, fontSize: '14px', lineHeight: 'initial' }}
              >{`Your Subscriptions renews/ends on ${
                subscriptionsDate ? fDate(subscriptionsDate) : ''
              }.`}</Typography>
            )}
          </Stack>
        </Stack>
        <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
          <DialogTitle align="center">Invite Team Members</DialogTitle>
          <DialogContent>
            <Typography align="center" sx={{ mb: 2 }}>
              Share your subscription and reports with your team, allowing everyone to access the same information without using additional tokens. Multiple accesses to the same reports will not incur extra charges, and any unlocked users in "Your Reports" will also be shared.
            </Typography>
            <Typography align="center" sx={{ mb: 2 }}>
              Here's how to get started:
            </Typography>
            <Typography align="center" sx={{ mb: 1 }}>
              1. Have your team member sign into influData Audiences.
            </Typography>
            <Typography align="center" sx={{ mb: 1 }}>
              2. Navigate to the footer menu and select "Join Team".
            </Typography>
            <Typography align="center" sx={{ mb: 2 }}>
              3. Enter the following personal code to join:
            </Typography>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} mt={1}>
              <Typography variant="h6" fontWeight="bold">
                {reports.magicLinkId?.replace('did:ethr:', '')}
              </Typography>
              <IconButton size="small" onClick={handleCopyCode}>
                <ContentCopyIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenPopup(false)}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openJoinTeamModal} onClose={() => setOpenJoinTeamModal(false)}>
          <DialogTitle align="center">Join Team</DialogTitle>
          <DialogContent>
            <Typography align="center" sx={{ mb: 2 }}>
              Enter the personal code provided by your team to join their subscription and access shared reports.
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              label="Personal Code"
              type="text"
              fullWidth
              value={joinTeamCode}
              onChange={(e) => setJoinTeamCode(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenJoinTeamModal(false)}>Cancel</Button>
            <Button onClick={() => handleJoinTeam()}>Join</Button>
          </DialogActions>
        </Dialog>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={6}
          sx={{ pt: 2 }}
        >
          <Link
            sx={{
              fontSize: '14px',
              '&:hover': {
                textDecoration: 'none',
                color: '#784E4E',
              },
            }}
            color="#784E4E"
            target="_blank"
            rel="noopener"
            underline="none"
            href="https://www.infludata.com/"
          >
            weCreate Data GmbH, {new Date().getFullYear()}
          </Link>
          <Link
            sx={{
              fontSize: '14px',
              '&:hover': {
                textDecoration: 'none',
                color: '#784E4E',
              },
            }}
            color="#784E4E"
            target="_blank"
            rel="noopener"
            underline="none"
            href="https://www.infludata.com/impressum"
          >
            Imprint
          </Link>
          <Link
            sx={{
              fontSize: '14px',
              '&:hover': {
                textDecoration: 'none',
                color: '#784E4E',
              },
            }}
            color="#784E4E"
            target="_blank"
            rel="noopener"
            underline="none"
            href="https://www.infludata.com/terms"
          >
            Terms & Conditions
          </Link>
          <Link
            sx={{
              fontSize: '14px',
              '&:hover': {
                textDecoration: 'none',
                color: '#784E4E',
              },
            }}
            color="#784E4E"
            target="_blank"
            rel="noopener"
            underline="none"
            href="https://www.infludata.com/privacy"
          >
            Privacy Policy
          </Link>
        </Stack>
      </Stack>
    </Stack>
  )
}
