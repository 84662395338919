import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import { withReduxActions } from '../../../hocs/withRedux'
import {
  createNewCollection,
  addSelectedProfileToCollection,
  removeSelectedProfileFromCollection,
  addElementToCollection,
  removeElementFromCollection,
  addElementToOpenedCollection,
  removeElementFromOpenedCollection,
} from '../../../redux/ducks/collectionsDucks'
import {
  deleteElementFromCampaign,
  addElementToCampaign,
  createCampaign,
} from '../../../redux/ducks/campaignsDucks'
import { limitAccess } from '../../../redux/ducks/appSettingsDucks'
import {
  manageProfileInCompareList,
  resetComparedProfiles,
} from '../../../redux/ducks/compareProfilesDucks'
import {
  userGrantSelector,
  canAddToCollectionDynamicSelector,
  noRerenderEqFn,
  isUserAuthenticatedSelector,
  isProfileAddedToCompareDynamicSelector,
  comparedProfilesPlatformSelector,
  comparedProfilesCountSelector,
  collectionsSelector,
} from '../../../redux/selectors'
import { FAV_COLLECTION_NAME } from '../../../constants/collections'

import ProfileToolsContainer from './ProfileToolsContainer'
import { event } from 'react-ga'

const ProfileToolsMediator = ({
  isSearchView,
  isCollectionView,
  isSelectedProfileView,
  user,
  createNewCollection,
  addSelectedProfileToCollection,
  removeSelectedProfileFromCollection,
  addElementToCollection,
  removeElementFromCollection,
  addElementToOpenedCollection,
  removeElementFromOpenedCollection,
  deleteElementFromCampaign,
  addElementToCampaign,
  createCampaign,
  limitAccess,
  stopEventBubbling,
  manageProfileInCompareList,
  resetComparedProfiles: resetComparedProfilesAll,
  onBlockUnblockUser,
  isBlackListCollection,
  setIsOpenPipelineModal,
  isExplore,
  isComparePage
}) => {
  const createCollectionsActions = () => {
    if (isSearchView)
      return {
        addElementToCollection: addElementToCollection,
        removeElementFromCollection: removeElementFromCollection,
        createCollection: createNewCollection,
      }
    if (isCollectionView)
      return {
        addElementToCollection: addElementToOpenedCollection,
        removeElementFromCollection: removeElementFromOpenedCollection,
        createCollection: createNewCollection,
      }
    if (isSelectedProfileView)
      return {
        addElementToCollection: addSelectedProfileToCollection,
        removeElementFromCollection: removeSelectedProfileFromCollection,
        createCollection: createNewCollection,
      }
  }

  const canAddToCollectionDynamic = useSelector(canAddToCollectionDynamicSelector, noRerenderEqFn)
  const checkIsProfileAddedToCompare = useSelector(isProfileAddedToCompareDynamicSelector)
  const currentComparedPlaform = useSelector(comparedProfilesPlatformSelector)
  const comparedProfilesCount = useSelector(comparedProfilesCountSelector)
  const userGrant = useSelector(userGrantSelector, shallowEqual)
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const collectionsList = useSelector(collectionsSelector)
  const findFavCollectionId = collectionsList?.filter(collection => collection.collectionId?.startsWith(FAV_COLLECTION_NAME))[0]?.collectionId
  const favCollectionId = findFavCollectionId?.length ? findFavCollectionId[0]?.collectionId : collectionsList[0]?.collectionId

  const canAddToFavs = canAddToCollectionDynamic({ collectionId: favCollectionId })

  const showCollectionsTool = !isCollectionView
  const showCampaignsTool = !isSearchView

  const resetComparedProfiles = (event) => {
    console.log(event)
    event.preventDefault()
    event.stopImmediatePropagation()
    event.stopPropagation()
    // resetComparedProfilesAll()
  }

  const profileToolsProps = {
    user,
    userGrant,
    canAddToFavs,
    showCollectionsTool,
    showCampaignsTool,
    isSelectedProfileView,
    isCollectionView,
    ...(isSelectedProfileView && { fullScreenModals: true }),
    ...createCollectionsActions(),
    deleteElementFromCampaign,
    addElementToCampaign,
    createCampaign,
    limitAccess,
    stopEventBubbling,
    isUserAuthenticated,
    checkIsProfileAddedToCompare,
    currentComparedPlaform,
    manageProfileInCompareList,
    resetComparedProfiles,
    comparedProfilesCount,
    onBlockUnblockUser,
    isBlackListCollection,
    setIsOpenPipelineModal,
    isExplore,
    isComparePage
  }

  return <ProfileToolsContainer {...profileToolsProps} />
}

const defaultFuncProp = () => {}

ProfileToolsMediator.propTypes = {
  isSearchView: PropTypes.bool,
  isCollectionView: PropTypes.bool,
  isSelectedProfileView: PropTypes.bool,
  user: PropTypes.object.isRequired,
  createNewCollection: PropTypes.func,
  addSelectedProfileToCollection: PropTypes.func,
  removeSelectedProfileFromCollection: PropTypes.func,
  addElementToCollection: PropTypes.func,
  removeElementFromCollection: PropTypes.func,
  addElementToOpenedCollection: PropTypes.func,
  removeElementFromOpenedCollection: PropTypes.func,
  deleteElementFromCampaign: PropTypes.func,
  addElementToCampaign: PropTypes.func,
  createCampaign: PropTypes.func,
  limitAccess: PropTypes.func,
  stopEventBubbling: PropTypes.func,
  manageProfileInCompareList: PropTypes.func,
  resetComparedProfiles: PropTypes.func,
  onBlockUnblockUser: PropTypes.func,
  isBlackListCollection: PropTypes.bool,
  setIsOpenPipelineModal: PropTypes.func,
  isExplore: PropTypes.bool,
  isComparePage: PropTypes.bool
}

export default withReduxActions({
  createNewCollection,
  addSelectedProfileToCollection,
  removeSelectedProfileFromCollection,
  addElementToCollection,
  removeElementFromCollection,
  addElementToOpenedCollection,
  removeElementFromOpenedCollection,
  deleteElementFromCampaign,
  addElementToCampaign,
  createCampaign,
  limitAccess,
  manageProfileInCompareList,
  resetComparedProfiles,
})(ProfileToolsMediator)
