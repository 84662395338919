import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { userCredsSelector } from './redux/selectors'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import SessionGuard from './components/SessionGuard';

import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { isUserLoggedInSelector } from './redux/selectors'

import WithGrantsContext from './hocs/WithGrantsContext'
import withTracker from './hocs/withTracker'
import WithToastError from './hocs/WithToastError'

import UpgradePage from './pages/UpgradePage'
import ListHostRoutingPage from './pages/ListHostRoutingPage'
import Page404 from './pages/Page404'

import HubSpotFormWidget from './components/HubSpotFormWidget'
import AudienceReportsContainer from './new-ui/pages/AudienceReports/AudienceReportsContainer'

const AppContainer = styled.div`
  width: 100%;
  min-width: 768px;
  position: relative;
  background-color: #FFF1F1;
`

const createListHostPageRoutesList = urlBase => [
  { path: `${urlBase}/search/:profileId?`, isExact: false },
  { path: `${urlBase}/collections/:collectionId?`, isExact: false },
  { path: `${urlBase}/campaigns/:campaignId?`, isExact: false },
  { path: `${urlBase}/profile/:profileId?`, isExact: false },
  { path: `${urlBase}/settings`, isExact: true },
  { path: `${urlBase}/password`, isExact: true },
  { path: `${urlBase}/signin`, isExact: true },
  { path: `${urlBase}/signup`, isExact: true },
  { path: `${urlBase}/signup-trial`, isExact: true },
  { path: `${urlBase}/collect-lead`, isExact: true },
  { path: `${urlBase}/compare-profiles`, isExact: true },
  { path: `${urlBase}/tools-for-brands`, isExact: true },
  { path: `${urlBase}/campaigns-trial`, isExact: true },
  { path: `${urlBase}/dashboardPreview`, isExact: false },
  { path: `${urlBase}/collections/your-overlaps/:overlapId?`, isExact: false },
  { path: `${urlBase}/changeemail`, isExact: true },
  { path: `${urlBase}/explore/:lookalikeId?`, isExact: true },
]

const createExtraPagesRoutesList = urlBase => [
  { path: `${urlBase}/upgrade`, render: () => <UpgradePage />, isExact: true },
  {
    path: `${urlBase}/audience-reports/:param?`,
    isExact: false,
    render: () => <AudienceReportsContainer />,
  },
]

const App = () => {
  const isUserLoggedIn = useSelector(isUserLoggedInSelector)
  const userCreds = useSelector(userCredsSelector)
  /*
  sessionGuardKey is used for remounting the SessionGuard component when it's needed => when login/logout,
  to get fresh grants data, since the SessionGuard doesn't do it automatically (it has only didMount effect in its code)
  The grants have refresh() method to update grants data, but it only refreshes the grants[] and props{}, but does not 
  refresh the username and id, so it doesn't fit the login/logout flow
   */
  const sessionGuardKey = isUserLoggedIn ? 'loggedIn' : 'notLoggedIn'

  const match = useRouteMatch()
  const urlBase = match.url.replace(/\/$/, '')
  const listHostPageRoutesList = createListHostPageRoutesList(urlBase)
  const extraPagesRoutesList = createExtraPagesRoutesList(urlBase)
  return (
    <AppContainer>
      <ReactTooltip delayShow={300} delayHide={0} />
      {isUserLoggedIn && userCreds && userCreds.username && <HubSpotFormWidget />}
      <Switch>
        <Route path={`${urlBase}/`}>
          <SessionGuard cookieName="whoami" loginPath="" ignorePaths={['']} key={sessionGuardKey}>
            <WithGrantsContext>
              <Switch>
                <Route exact path={`${urlBase}/`}>
                  <Redirect to={process.env.REACT_APP_PREFIX + 'search'} />
                </Route>

                {/* listHostPage routes */}
                {listHostPageRoutesList.map(routes => (
                  <Route key={routes?.path} path={routes?.path} exact={routes?.isExact}>
                    <ListHostRoutingPage baseUrl={urlBase} />
                  </Route>
                ))}
                {extraPagesRoutesList.map(route => (
                  <Route key={route.path} path={route.path} exact={route?.isExact}>
                    {route.render()}
                  </Route>
                ))}

                <Route path="*">
                  <Page404 />
                </Route>
              </Switch>
            </WithGrantsContext>
          </SessionGuard>
        </Route>
      </Switch>
    </AppContainer>
  )
}

export default withTracker(WithToastError(memo(App)))
