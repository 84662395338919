import { actionProvider } from './actionsProvider'
import { setError } from './errorDucks'

import {
  compareProfilesSelector,
  isProfileAddedToCompareDynamicSelector,
  comparedProfilesPlatformSelector,
  comparedProfilesCountSelector,
  comparedProfilesSortingSelector,
  comparedProfilesIdsSelector,
  comparedProfilesAnyLoadingSelector,
  userCredsSelector,
} from '../selectors'
import { ERROR_MSG } from '../../constants/errorMessages'
import { SOCIAL_PLATFORMS_NAMES } from '../../constants/appSettings'
import {
  COMPARED_PROFILE_DEFAULT_LOADING_OBJ,
  COMPARED_PROFILE_LOADED_OBJ_PART,
  COMPARED_PROFILES_LIMIT,
  MIN_USERS_FOR_RANKS,
  COMPARED_PROFILES_DEFAULT_RANKS,
} from '../../constants/comparedProfiles'
import {
  SORTING_DIRECTIONS,
  COMPARE_SORTING_TYPES,
  MIN_USERS_FOR_SORTING,
} from '../../constants/sortings'
import { sortComparedProfiles, notEmptyObject } from '../../utils'

import httpService from '../../services/httpService'
import sessionCacheService from '../../services/sessionCacheService'
import relativeRankingService from '../../services/RelativeRankingService'
import { changeSearchResultsAndProfilesUsed } from './userDucks'


const CHANGE_COMPARE_LIST = 'UPDATE_COMPARE_LIST'
const RESET_COMPARE = 'RESET_COMPARE'
const CHANGE_COMPARED_PROFILES_PLATFORM = 'CHANGE_COMPARED_PROFILES_PLATFORM'
const CHANGE_COMPARED_SORTING = 'CHANGE_COMPARED_SORTING'
const CHANGE_COMPARED_RANKS = 'CHANGE_COMPARED_RANKS'

const _createInitState = ({ isInit = false } = {}) => {
  let comparedProfiles = {}

  if (isInit) {
    comparedProfiles = sessionCacheService.getComparedProfiles() || {}
    if (comparedProfiles.profiles) {
      comparedProfiles.profiles = sortComparedProfiles({
        profiles: comparedProfiles.profiles,
        sortKey: comparedProfiles.sortKey || COMPARE_SORTING_TYPES.username.sortKey,
        sortDir: comparedProfiles.sortDir || SORTING_DIRECTIONS.ascend,
      })
    }
  }

  const ranks = notEmptyObject(comparedProfiles.ranks)
    ? comparedProfiles.ranks
    : COMPARED_PROFILES_DEFAULT_RANKS

  return {
    profiles: comparedProfiles.profiles || {},
    comparedProfilesPlatform: comparedProfiles.platform || '',
    sorting: {
      sortKey: comparedProfiles.sortKey || COMPARE_SORTING_TYPES.username.sortKey,
      sortDir: comparedProfiles.sortDir || SORTING_DIRECTIONS.ascend,
    },
    ranks,
  }
}

// reducer
export const compareProfilesReducer = (state = _createInitState({ isInit: true }), action = {}) => {
  switch (action.type) {
    case CHANGE_COMPARE_LIST:
      return { ...state, profiles: { ...action.payload } }
    case CHANGE_COMPARED_PROFILES_PLATFORM:
      return { ...state, comparedProfilesPlatform: action.payload }
    case CHANGE_COMPARED_SORTING:
      return { ...state, sorting: action.payload }
    case CHANGE_COMPARED_RANKS:
      return { ...state, ranks: action.payload }
    case RESET_COMPARE:
      return _createInitState()
    default:
      return state
  }
}

// actions
export const changeCompareList = payload => actionProvider(CHANGE_COMPARE_LIST, payload)
export const changeComparedProfilesPlatform = payload =>
  actionProvider(CHANGE_COMPARED_PROFILES_PLATFORM, payload)
export const resetCompare = () => actionProvider(RESET_COMPARE)
export const changeCompareSorting = payload => actionProvider(CHANGE_COMPARED_SORTING, payload)
export const changeComparedRanks = payload => actionProvider(CHANGE_COMPARED_RANKS, payload)

//
export const handleComparedProfilesPlatform = platform => (dispatch, getState) => {
  const comparedPlatform = comparedProfilesPlatformSelector(getState())
  if (!comparedPlatform) {
    dispatch(changeComparedProfilesPlatform(SOCIAL_PLATFORMS_NAMES[platform].index))
    return true
  } else if (comparedPlatform && comparedPlatform !== SOCIAL_PLATFORMS_NAMES[platform].index) {
    dispatch(setError(ERROR_MSG.forbiddenAddToComparedProfiles))
    return false
  }
  return true
}

export const handleComparedProfilesLimit = () => (dispatch, getState) => {
  const currentProfilesCount = comparedProfilesCountSelector(getState())
  if (currentProfilesCount >= COMPARED_PROFILES_LIMIT) {
    dispatch(setError(ERROR_MSG.comparedProfilesLimitReach(COMPARED_PROFILES_LIMIT)))
    return false
  }
  return true
}

export const handleAccessToAddComparedProfiles = (profileId, platform, processExistingProfile) => (
  dispatch,
  getState
) => {
  const isPlatformCheckPassed = dispatch(handleComparedProfilesPlatform(platform))
  if (!isPlatformCheckPassed) return false
  const isLimitNotReached = dispatch(handleComparedProfilesLimit())
  if (!isLimitNotReached) return false
  if (processExistingProfile) return true
  const checkIsProfileInList = isProfileAddedToCompareDynamicSelector(getState())
  if (checkIsProfileInList(profileId)) {
    dispatch(setError(ERROR_MSG.comparedProfileAlreadyInList))
    return false
  }
  return true
}

// async actions
export const addProfileToCompareList = ({ profileId, platform }) => async (dispatch, getState) => {
  const isAddAllowed = dispatch(handleAccessToAddComparedProfiles(profileId, platform))
  const { searchResultsAndProfilesUsed } = userCredsSelector(getState())
  const { profileAnalyticsRemaining } = searchResultsAndProfilesUsed || {}
  if (!isAddAllowed) return
  try {
    const comparedProfiles = compareProfilesSelector(getState())
    dispatch(
      changeCompareList({
        ...comparedProfiles,
        [profileId]: COMPARED_PROFILE_DEFAULT_LOADING_OBJ,
      })
    )
    const comparedUser = await httpService.fetchComparedUserById(profileId)
    const comparedUserArray = comparedUser.resultsArray
    const deductRequests = comparedUser.deductRequests

    if(deductRequests) {
      dispatch(
        changeSearchResultsAndProfilesUsed({
          profileAnalyticsRemaining: profileAnalyticsRemaining - deductRequests
        })
      )
    }

    if (comparedUserArray && !!comparedUserArray?.length && comparedUserArray[0]?._id) {
      dispatch(
        processAddToStoreComparedProfileWithSorting({
          comparedUser: comparedUserArray[0],
          platform,
        })
      )
    } else {
      dispatch(removeProfileFromCompareList({ profileId, platform }))
    }
    return true
  } catch (err) {
    dispatch([
      removeProfileFromCompareList({ profileId }),
      setError(ERROR_MSG.failToAddProfileToCompare),
    ])
    return false
  }
}

export const processAddToStoreComparedProfileWithSorting = ({ comparedUser, platform }) => (
  dispatch,
  getState
) => {
  const { _id: profileId } = comparedUser
  const latestComparedProfiles = {
    ...compareProfilesSelector(getState()),
    [profileId]: {
      ...comparedUser,
      ...COMPARED_PROFILE_LOADED_OBJ_PART,
    },
  }
  const { sortKey, sortDir } = comparedProfilesSortingSelector(getState())
  const sortedProfiles = sortComparedProfiles({
    profiles: latestComparedProfiles,
    sortKey,
    sortDir,
  })

  dispatch([changeCompareList(sortedProfiles)])
  const isAnyProfileLoading = comparedProfilesAnyLoadingSelector(getState())
  if (!isAnyProfileLoading) {
    // rank and save to session storage only when all profiles already loaded
    const ranksToCache = dispatch(rankComparedProfiles())
    sessionCacheService.setComparedProfiles({
      profiles: sortedProfiles,
      platform,
      sortKey,
      sortDir,
      ranks: ranksToCache,
    })
  }
}

export const removeProfileFromCompareList = ({ profileId, platform }) => (dispatch, getState) => {
  try {
    const currentCompareList = compareProfilesSelector(getState())
    if (!Object.keys(currentCompareList).length || !profileId) return
    const { [profileId]: itemToRemove, ...restOfList } = currentCompareList

    if (!Object.keys(restOfList).length) {
      dispatch(resetComparedProfiles())
      sessionCacheService.removeComparedProfiles()
    } else {
      const { sortKey, sortDir } = comparedProfilesSortingSelector(getState())
      const sortedProfiles = sortComparedProfiles({
        profiles: restOfList,
        sortKey,
        sortDir,
      })
      dispatch(changeCompareList(sortedProfiles))
      const ranksToCache = dispatch(rankComparedProfiles()) // re-rank when any profile is removed
      sessionCacheService.setComparedProfiles({
        profiles: sortedProfiles,
        platform: SOCIAL_PLATFORMS_NAMES[platform].index,
        ranks: ranksToCache,
      })
    }
    return true
  } catch (err) {
    dispatch(setError(ERROR_MSG.failToRemoveProfileFromCompare))
    return false
  }
}

export const resetComparedProfiles = () => dispatch => {
  dispatch(resetCompare())
  sessionCacheService.removeComparedProfiles()
}

export const changeComparedProfilesSorting = ({ sortKey, sortDir }) => async (
  dispatch,
  getState
) => {
  const currentSorting = comparedProfilesSortingSelector(getState())
  const comparedProfilesIds = comparedProfilesIdsSelector(getState())
  // avoid sorting for less than 2 or for same key and dir
  if (
    (sortKey === currentSorting.sortKey && sortDir === currentSorting.sortDir) ||
    comparedProfilesIds.length < MIN_USERS_FOR_SORTING
  ) {
    return
  }

  const currentComparedProfiles = compareProfilesSelector(getState())
  try {
    dispatch(changeCompareSorting({ sortKey, sortDir }))

    const sortedProfiles = sortComparedProfiles({
      profiles: currentComparedProfiles,
      sortKey,
      sortDir,
      onlyLoaded: false,
    })

    dispatch(changeCompareList(sortedProfiles))
    sessionCacheService.setComparedProfiles({
      profiles: sortedProfiles,
      sortKey,
      sortDir,
    })
  } catch (err) {
    dispatch([
      setError(ERROR_MSG.failSortComparedProfiles),
      // revert changes if failed
      changeCompareSorting(currentSorting),
      changeCompareList(currentComparedProfiles),
    ])
  }
}

export const rankComparedProfiles = () => (dispatch, getState) => {
  const profiles = compareProfilesSelector(getState())
  if (Object.keys(profiles)?.length < MIN_USERS_FOR_RANKS) {
    dispatch(changeComparedRanks(COMPARED_PROFILES_DEFAULT_RANKS))
    return {}
  }
  const ranks = relativeRankingService.calcComparedProfilesRankings({
    profiles,
  })
  dispatch(changeComparedRanks(ranks))
  return ranks
}

export const manageProfileInCompareList = ({
  profileId,
  toAdd = true,
  platform = '',
}) => async dispatch => {
  if (!profileId) return
  const action = toAdd ? addProfileToCompareList : removeProfileFromCompareList
  return await dispatch(action({ profileId, platform }))
}

export const processAddToStoreComparedProfilesWithSorting = ({ comparedUsers, platform }) => (
  dispatch,
  getState
) => {
  const { sortKey, sortDir } = comparedProfilesSortingSelector(getState())
  const sortedProfiles = sortComparedProfiles({
    profiles: comparedUsers,
    sortKey,
    sortDir,
  })

  dispatch([changeCompareList(sortedProfiles)])
  const isAnyProfileLoading = comparedProfilesAnyLoadingSelector(getState())
  if (!isAnyProfileLoading) {
    // rank and save to session storage only when all profiles already loaded
    const ranksToCache = dispatch(rankComparedProfiles())
    sessionCacheService.setComparedProfiles({
      profiles: sortedProfiles,
      platform,
      sortKey,
      sortDir,
      ranks: ranksToCache,
    })
  }
}

export const AddAllCollectionsToComparison = ({
  collectionArr,
  comparedProfilesPlatform,
  toComparePage,
  setIsLoading,
}) => async (dispatch, getState) => {
  const collectionIds = Object.keys(collectionArr)?.join()
  const { searchResultsAndProfilesUsed } = userCredsSelector(getState())
  const { profileAnalyticsRemaining } = searchResultsAndProfilesUsed || {}


  dispatch([resetComparedProfiles(), setIsLoading(true)])

  try {
    const comparedCollectionReturn = await httpService.fetchComparedUserById(collectionIds)
    const comparedCollectionUsers = comparedCollectionReturn.resultsArray
    const deductRequests = comparedCollectionReturn.deductRequests

    if(deductRequests) {
      dispatch(
        changeSearchResultsAndProfilesUsed({
          profileAnalyticsRemaining: profileAnalyticsRemaining - deductRequests
        })
      )
    }
    if (comparedCollectionUsers && !!Object.keys(comparedCollectionUsers)?.length) {
      const comparedUsers = comparedCollectionUsers?.reduce((acc, item) => {
        acc[item._id] = item
        return acc
      }, {})

      dispatch([
        processAddToStoreComparedProfilesWithSorting({
          comparedUsers,
          platform: comparedProfilesPlatform,
        }),
        changeComparedProfilesPlatform(comparedProfilesPlatform),
        rankComparedProfiles(),
        setIsLoading(false),
      ])
      toComparePage()
    }
  } catch (e) {}
}
