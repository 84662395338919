import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { getNavbarHeight } from '../../../../utils'
import CollectionsBarHeader from './CollectionsBarHeader'
import CreateCollectionItem from './CreateCollectionItem'
import CollectionsBarItemsList from './CollectionsBarItemsList'
import { Container, PopupedContainer, CollectionBarContent } from './collectionsSidebarStyles'

const CollectionsBarRenderer = ({
  collectionsList = [],
  disabled = true,
  createCollection,
  canCreateCollection,
  useAsPopupWithDragDrop,
  isDragCaptured,
  registerDropableHandlers,
  activeForDropCollectionId = null,
  canDragItemToCollection,
  onDropLeaveCollectionList,
  dropOperations,
  isNavType = 'default',
  onHandleOpenViewType
}) => {
  const history = useHistory()
  const RenderContainer = useAsPopupWithDragDrop ? PopupedContainer : Container

  const renderContainerProps = useAsPopupWithDragDrop
    ? {
        isDragCaptured: isDragCaptured || dropOperations.inProgress,
        topPosition: getNavbarHeight() - 12,
        isDrag: true,
      }
    : {
        disabled,
        ...(disabled && { onClick: () => history.push('/login') }),
      }

  // const showCreateCollection = useAsPopupWithDragDrop
  //   ? false
  //   : !isDragCaptured && !dropOperations.inProgress

  const goToYourOverlaps = () => {
    history.push(`/collections/your-overlaps`)
  }

  return (
    <RenderContainer {...renderContainerProps} isNavType={isNavType}>
      <CollectionsBarHeader
        showHeaderForDrag={useAsPopupWithDragDrop || isDragCaptured || dropOperations.inProgress}
        isNavType={isNavType}
      />
      <CollectionBarContent>
        <CreateCollectionItem
          createCollectionHandler={createCollection}
          canCreateCollection={canCreateCollection}
          goToYourOverlaps={goToYourOverlaps}
          isNavType={isNavType}
        />

        <CollectionsBarItemsList
          collectionsList={collectionsList}
          registerDropableHandlers={registerDropableHandlers}
          activeForDropCollectionId={activeForDropCollectionId}
          canDragItemToCollection={canDragItemToCollection}
          onDropLeaveCollectionList={onDropLeaveCollectionList}
          isDragCaptured={isDragCaptured}
          dropOperations={dropOperations}
          isNavType={isNavType}
          onHandleOpenViewType={onHandleOpenViewType}
        />
      </CollectionBarContent>
    </RenderContainer>
  )
}

CollectionsBarRenderer.propTypes = {
  collectionsList: PropTypes.array,
  disabled: PropTypes.bool,
  createCollection: PropTypes.func,
  canCreateCollection: PropTypes.bool,
  useAsPopupWithDragDrop: PropTypes.bool,
  isDragCaptured: PropTypes.bool,
  registerDropableHandlers: PropTypes.func,
  activeForDropCollectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  canDragItemToCollection: PropTypes.object,
  onDropLeaveCollectionList: PropTypes.func,
  dropOperations: PropTypes.object,
  isNavType: PropTypes.string,
  onHandleOpenViewType: PropTypes.func
}

export default memo(CollectionsBarRenderer)
