import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useGoogleLogin } from '@react-oauth/google'
import { Alert, Button, Divider, Link, Stack, TextField, Typography } from '@mui/material'
import useTranslation from '../../../localization/useTranslation'
import { constructLinkToLandingPage, validateEmail } from '../../../utils'
import { Form } from '../../common/styledWrappers/signupFormsStyles/signupStepOneFormStyles'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import { EMAIL_VALID_REGEX } from '../../../constants/login'
import Iconify from '../../../new-ui/components/iconify'

const StyledLoginBtn = {
  fontFamily: `Lato, sans-serif`,
  fontWeight: 'bold',
  fontSize: '17px',
  lineHeight: '16px',
  color: '#fff',
  width: '100%',
  height: '48px',
  textTransform: 'uppercase',
  border: 'none',
  background:
    'transparent linear-gradient(89deg, #FF726F 0%, #FFCA55 120%) 0% 0% no-repeat padding-box',
}

const SignupStepOneForm = props => {
  const { goToStepTwo, goToStepTwoGoogle, goToStepAzure, emailTakenStatus, isSevenDaysTrial } =
    props || {}
  const { labelStrings, currentLang } = useTranslation()

  const [formState, changeFormState] = useState({
    email: '',
    isValidEmail: true,
    isGoogleAuthError: false,
    errorText: '',
  })

  const isEmailInvalid = !formState.isValidEmail
  const hasErrorText =
    !!formState.errorText?.length &&
    formState.errorText !== labelStrings.signupLocalization.workEmailAddress

  const changeEmail = ({ target }) => changeFormState({ ...formState, email: target.value })
  const onFocusInput = () =>
    !formState.isValidEmail && changeFormState({ ...formState, isValidEmail: true })

  const submitForm = async e => {
    e.preventDefault()
    const isValidEmail = !!formState.email && validateEmail(formState.email.trim())
    if (isValidEmail) {
      const { email } = formState
      return await goToStepTwo({ email: email.toLowerCase().trim() })
    }
    changeFormState({ ...formState, isValidEmail: false })
  }

  const onErrorGoogleAuth = err => {
    if (err) {
      changeFormState({
        ...formState,
        isGoogleAuthError: true,
      })
    }
    console.log(err)
  }

  const onBlurEmailInput = () => {
    if (formState.email.trim() === '') {
      changeFormState({
        ...formState,
        errorText: labelStrings.signupLocalization.workEmailAddress,
      })
    } else if (!formState.email.match(EMAIL_VALID_REGEX)) {
      changeFormState({
        ...formState,
        errorText: labelStrings.signupLocalization.emailError,
      })
    } else {
      changeFormState({
        ...formState,
        errorText: '',
      })
    }
  }

  const isHasAndValidEmail = formState?.isValidEmail && formState.email?.length

  useEffect(() => {
    const { is_valid, is_free, isRequested } = emailTakenStatus || {}
    const isEmailInvalid =
      !is_valid && isRequested && isHasAndValidEmail && isSevenDaysTrial
    const isFree = is_free && isRequested && isHasAndValidEmail && isSevenDaysTrial
    let errorText = ''

    if (isEmailInvalid) {
      errorText = labelStrings.yourEmailIsNotValid
    } else if (isFree) {
      errorText = labelStrings.signupIsNotAllowed
    }

    if (errorText && isSevenDaysTrial) {
      changeFormState({
        ...formState,
        errorText,
      })
    }
  }, [emailTakenStatus, isHasAndValidEmail, isSevenDaysTrial])

  const googleLogin = useGoogleLogin({
    onSuccess: goToStepTwoGoogle,
    onError: onErrorGoogleAuth,
  })

  return (
    <Form onSubmit={submitForm}>
      <Stack sx={{ width: '100%', px: 2 }} justifyContent="center" alignItems="center">
        <Stack sx={{ width: '100%' }} spacing={5}>
          <Stack
            direction="row"
            alignItems={isEmailInvalid || hasErrorText ? 'center' : 'end'}
            sx={{ width: '100%' }}
          >
            <MailOutlinedIcon
              sx={{
                color: isEmailInvalid || hasErrorText ? '#FF5630' : 'action.active',
                mr: 1,
                my: 0.5,
              }}
            />
            <TextField
              variant="standard"
              sx={{
                width: '100%',
              }}
              label={labelStrings.email}
              id="signup-email"
              type="text"
              onChange={changeEmail}
              onFocus={onFocusInput}
              onBlur={onBlurEmailInput}
              value={formState.email}
              error={isEmailInvalid || hasErrorText}
              helperText={
                isEmailInvalid ? labelStrings.reenterNotValidEmail : formState.errorText || null
              }
              autoFocus
            />
          </Stack>

          <Button sx={StyledLoginBtn} variant="contained" type="submit">
            {labelStrings.signupLocalization.signupFree}
          </Button>
        </Stack>

        <Divider sx={{ fontFamily: `Lato, sans-serif`, width: '100%', py: 4 }}>
          {labelStrings.or}
        </Divider>

        <Stack>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => googleLogin()}
            startIcon={<Iconify width={22} icon={'flat-color-icons:google'} />}
            sx={{
              minWidth: '221px',
              height: '46px',
              fontSize: '15px',
              fontFamily: 'Inter',
              borderRadius: '60px',
              border: 'unset',
              color: '#1F1F1F',
              backgroundColor: '#F2F2F2',
              fontWeight: 600,
              '&:hover': {
                border: 'unset',
                opacity: 0.7,
              },
            }}
          >
            {labelStrings.continueWithGoogle}
          </Button>

          {formState.isGoogleAuthError && (
            <Alert
              variant="outlined"
              severity="error"
              sx={{
                mt: 1,
                fontFamily: `Lato, sans-serif`,
                fontSize: '12px',
                padding: '2px 6px',
                '& .MuiAlert-message': { padding: '2px 0', display: 'flex', alignItems: 'center' },
                '& .MuiAlert-icon': { display: 'flex', alignItems: 'center', marginRight: '8px' },
              }}
            >
              {labelStrings.googleAuthErrorText}
            </Alert>
          )}
        </Stack>

        <Stack sx={{ mt: 0.75 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={goToStepAzure}
            startIcon={<Iconify width={16} icon={'devicon:azure'} />}
            sx={{
              minWidth: '221px',
              height: '46px',
              fontSize: '15px',
              fontFamily: 'Inter',
              borderRadius: '60px',
              border: 'unset',
              color: '#1F1F1F',
              backgroundColor: '#F2F2F2',
              fontWeight: 600,
              '&:hover': {
                border: 'unset',
                opacity: 0.7,
              },
            }}
          >
            {labelStrings.signInAzureAdLabel}
          </Button>
        </Stack>

        <Typography
          textAlign="center"
          variant="body-2"
          sx={{ mt: 5, maxWidth: currentLang === 'de' ? '100%' : '60%' }}
        >
          <Typography
            variant="body-2"
            textAlign="center"
            sx={{ fontFamily: `Lato, sans-serif`, fontSize: '13px', color: '#6B7280' }}
          >
            {labelStrings.bySigningUpIAgreeTo} influData{' '}
          </Typography>
          <Link
            underline="none"
            target="_blank"
            rel="noopener"
            href={constructLinkToLandingPage('privacy', currentLang)}
            sx={{
              fontFamily: `Lato, sans-serif`,
              fontSize: '13px',
              fontWeight: 600,
              '&:hover': {
                color: '#6B7280',
                textDecoration: 'none',
                opacity: 0.8,
              },
            }}
          >
            {labelStrings.privacyPolicy}
          </Link>
          <Typography
            variant="body-2"
            sx={{ fontFamily: `Lato, sans-serif`, fontSize: '13px', color: '#6B7280', px: 0.5 }}
          >
            {labelStrings.and}
          </Typography>
          <Link
            underline="none"
            target="_blank"
            rel="noopener"
            href={constructLinkToLandingPage('terms', currentLang)}
            sx={{
              fontFamily: `Lato, sans-serif`,
              fontSize: '13px',
              fontWeight: 600,
              '&:hover': {
                color: '#6B7280',
                textDecoration: 'none',
                opacity: 0.8,
              },
            }}
          >
            {labelStrings.termsOfService}.
          </Link>
        </Typography>
      </Stack>
    </Form>
  )
}

SignupStepOneForm.propTypes = {
  goToStepTwo: PropTypes.func,
  goToStepTwoGoogle: PropTypes.func,
  stepTwoVerifiedSignupToken: PropTypes.func,
}

export default SignupStepOneForm
