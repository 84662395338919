import React from 'react'
import { ModalComponent } from '../modal/ModalComponent'
import {
  Box,
  Stack,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import useTranslation from '../../../localization/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import { userCredsSelector } from '../../../redux/selectors'
import {
  changeSelectedOrganization,
  switchOrganizationalAccount,
} from '../../../redux/ducks/userDucks'

export const ModalSwitchOrganization = props => {
  const { isOpenModal, onCloseModal } = props || {}
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()

  const { organizationName, organizations } = useSelector(userCredsSelector) || {}
  const { dataForProfileSelect, selectedOrganization } = organizations || {}

  const onChangeOrganization = ({ target: { value } }) => {
    const { orgId } = value || {}
    // @ts-ignore
    dispatch([
      changeSelectedOrganization(value),
      switchOrganizationalAccount({
        orgId,
      }),
    ])
    onClose()
  }

  const onClose = async () => {
    onCloseModal()
    localStorage.setItem('hiddenMultipleOrganizations', 'true')
  }

  return (
    <ModalComponent
      isOpenModal={isOpenModal}
      closeModal={onClose}
      title={labelStrings.yourAccountIsAssociatedWithMultipleOrganization}
      contentWidth={'720px'}
      contentHeight="auto"
      isCenterTitle
      withoutScrollWrapper
      isGradientTitle
      isGradientBg
    >
      <Box sx={{ pb: 3 }}>
        <Stack spacing={3} alignItems="center">
          <Stack alignItems="center">
            <Typography textAlign="center" sx={{ fontFamily: 'Inter', fontWeight: 400 }}>
              {labelStrings.youAreCurrentlyLogged}:
            </Typography>
            <Typography
              textAlign="center"
              sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '18px' }}
            >
              {organizationName}
            </Typography>
          </Stack>

          <Stack alignItems="center" spacing={0.5}>
            <Typography textAlign="center" sx={{ fontFamily: 'Inter', fontWeight: 400 }}>
              {labelStrings.youCanSwitchYourAccount}:
            </Typography>
            <Box
              sx={{
                px: 1,
                minHeight: '56px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <FormControl size="small" sx={{ textAlign: 'center' }}>
                <InputLabel
                  focused={false}
                  sx={{
                    fontWeight: 'bold',
                    color: '#442424',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                  size="small"
                >
                  {labelStrings.currentOrganization}
                </InputLabel>
                <Select
                  size="small"
                  value={selectedOrganization}
                  label={labelStrings.currentOrganization}
                  onChange={onChangeOrganization}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        fontFamily: 'Inter',
                        maxHeight: '318px',
                        color: '#442424',
                        fontSize: '14px',
                      },
                    },
                  }}
                  sx={{
                    width: '220px',
                    color: '#442424',
                    fontWeight: 'bold',
                    background: 'white',
                    '& .MuiSelect-icon': {
                      color: '#442424',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#E3C5C5',
                    },
                    '& .Mui-selected': {
                      backgroundColor: '#FFF1F1',
                      color: '#442424',
                    },
                  }}
                >
                  {dataForProfileSelect?.map(el => (
                    <MenuItem
                      sx={{
                        backgroundColor: el.isActive ? '#FFF1F1' : 'inherit',
                        cursor: el.isActive ? 'not-allowed' : 'pointer',
                        userSelect: el.isActive ? 'none' : 'auto',
                        '&:hover': {
                          backgroundColor: el.isActive ? '#FFF1F1' : 'inherit',
                        },
                        maxWidth: '220px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      key={el.orgId}
                      value={el}
                    >
                      {el.orgName}
                    </MenuItem>
                  ))}
                  <Typography
                    textAlign="center"
                    sx={{
                      fontFamily: 'Inter',
                      maxWidth: '220px',
                      fontSize: '12px',
                      color: '#442424',
                      px: 1,
                      pt: 1,
                    }}
                  >
                    {labelStrings.youCanAddNewOrganizations}
                  </Typography>
                </Select>
              </FormControl>
            </Box>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ width: '100%', px: 5 }}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              color="primary"
              sx={{
                color: '#FF5C00',
                width: 'fit-content',
                minWidth: '100px',
              }}
            >
              {labelStrings.close}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </ModalComponent>
  )
}
