import React, { useState } from "react";
import { ModalComponent } from "../../../../new-ui/components/modal/ModalComponent";
import useTranslation from "../../../../localization/useTranslation";
import {
  Stack,
  Typography,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

export const ChangeTeamMemberRole = (props) => {
  const { isOpenModal, closeModal, onConfirmChangeRoleMember, changeRoleData, isOwner } = props || {};

  const { labelStrings } = useTranslation();

  const initialData = changeRoleData?.roles[0] || '';
  const [roleValue, setRoleValue] = useState(initialData);

  const onCloseModal = () => {
    closeModal();
    setRoleValue("");
  };

  const onConfirm = (e) => {
    e.preventDefault();
    if (!!roleValue?.length) {
      onConfirmChangeRoleMember(roleValue);
    }
    onCloseModal();
  };

  const onChangeRole = ({ target }) => {
    setRoleValue(target?.value);
  };

  return (
    <ModalComponent
      isOpenModal={isOpenModal}
      closeModal={onCloseModal}
      title={labelStrings.changeTeamMemberRole}
      contentWidth={"520px"}
      contentHeight='auto'
      isCenterTitle
      withoutScrollWrapper
      isGradientTitle
      isGradientBg
    >
      <Box>
        <Stack spacing={2}>
          <Stack alignItems='center'>
            <Typography  sx={{ fontFamily: "Inter", fontWeight: 500 }}>
                {labelStrings.selectRoleMember}
              </Typography>
            <FormControl fullWidth sx={{ maxWidth: "80%", pt: 0.5 }}>
              <Select size='small' value={roleValue} onChange={onChangeRole}>
                <MenuItem value={"member"}>{labelStrings.member}</MenuItem>
                <MenuItem value={"admin"}>{labelStrings.admin}</MenuItem>
                { isOwner ? (
                  <MenuItem value={"owner"}>{labelStrings.owner}</MenuItem>
                  ) : ''
                }
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{ pb: 3, pt: 2 }}
          >
            <Button
              onClick={closeModal}
              variant='outlined'
              color='primary'
              sx={{ borderColor: "#FF5C00", color: "#FF5C00" }}
            >
              {labelStrings.cancel}
            </Button>
            <Button
              disabled={!roleValue?.length}
              onClick={onConfirm}
              variant='contained'
              color='primary'
              sx={{
                backgroundColor: "#FF5C00",
              }}
            >
              {labelStrings.confirm}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </ModalComponent>
  );
};
