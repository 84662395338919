import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import { SectionsDivider } from '../../common/styledWrappers'

import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Alert,
  Stack,
  Tooltip,
  InputAdornment,
  MenuItem
} from '@mui/material'
import { Edit as EditIcon, Save as SaveIcon, Cancel as CancelIcon } from '@mui/icons-material'

const BillingDetails = ({
  updateBillingDetails,
  openContactUs,
  isUserDetailsPresent = false,
  currentBillingDetails = {},
  isUpdateLoading = false,
  isUserDetailsError = false,
  isBillingDetailsPresent = false,
  isOpenEditDetails = false,
  isEditMode,
  setIsEditMode,
  errorMessage, // new prop for displaying errors returned from request
  setErrorMessage // new prop to reset or set error messages
}) => {
  const { labelStrings, countryOptions } = useTranslation()
  const [formData, setFormData] = useState(currentBillingDetails)

  React.useEffect(() => {
    // Only reset formData if we exit edit mode or if currentBillingDetails changes (i.e. after a successful save)
    // If we remain in edit mode and there's an error, we do NOT reset the formData.
    if (!isEditMode) {
      setFormData(currentBillingDetails)
    }
  }, [currentBillingDetails, isEditMode])

  const handleInputChange = (e) => {
    setErrorMessage('') // clear any previous error messages when the user changes something
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setErrorMessage('') // clear previous error
    updateBillingDetails(formData)
  }

  const DetailItem = ({ label, prevalue, value, children }) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="caption" color="text.secondary" sx={{ fontFamily: 'Inter' }}>
        {label}
      </Typography>
      {children || (
        <Typography variant="body1" sx={{ fontFamily: 'Inter' }}>
          {prevalue || ''}{value || '-'}
        </Typography>
      )}
    </Box>
  )

  const CountryField = () => {
    // If country is already set, show disabled field with tooltip.
    if (currentBillingDetails.country) {
      return (
        <Tooltip title={labelStrings.contactToChangeCountry} arrow placement="top">
          <Box>
            <TextField
              fullWidth
              label={labelStrings.country}
              name="country"
              value={currentBillingDetails?.country}
              disabled
              sx={{
                '.MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
                },
                cursor: 'not-allowed',
              }}
            />
          </Box>
        </Tooltip>
      )
    }

    // If no country is selected yet, provide a dropdown to select one.
    return (
      <TextField
        select
        fullWidth
        label={labelStrings.country}
        name="country"
        value={formData.country || ''}
        onChange={handleInputChange}
        required
      >
        {countryOptions.map((c) => (
          <MenuItem key={c.isoCode.toUpperCase()} value={c.isoCode.toUpperCase()}>
            {c.label}
          </MenuItem>
        ))}
      </TextField>
    )
  }

  const renderViewMode = () => (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <DetailItem label={labelStrings.firstName} value={currentBillingDetails.firstName} />
          <DetailItem label={labelStrings.lastName} value={currentBillingDetails.lastName} />
          <DetailItem label={labelStrings.company} value={currentBillingDetails.company} />
          <DetailItem label={labelStrings.billingEmail} value={currentBillingDetails.billingEmail} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DetailItem label={labelStrings.addressLine1} value={currentBillingDetails.addressLine1} />
          {currentBillingDetails.addressLine2 && (
            <DetailItem label="" value={currentBillingDetails.addressLine2} />
          )}
          <DetailItem
            label={`${labelStrings.city}, ${labelStrings.zip}`}
            value={`${currentBillingDetails.city}, ${currentBillingDetails.zip}`}
          />
          <DetailItem label={labelStrings.country} value={currentBillingDetails.country  || ''} />
          <DetailItem label={labelStrings.vatId} prevalue={currentBillingDetails.country} value={currentBillingDetails.vatId} />
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => setIsEditMode(true)}
          sx={{
            fontFamily: 'Inter',
            textTransform: 'none',
          }}
        >
          {labelStrings.edit}
        </Button>
      </Box>
    </Box>
  )

  const renderEditMode = () => (
    <form onSubmit={handleSubmit}>
      {errorMessage && (
        <Box sx={{ mb: 2 }}>
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label={labelStrings.firstName}
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
              inputProps={{
                maxLength: 40
              }}
            />
            <TextField
              fullWidth
              label={labelStrings.lastName}
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
              inputProps={{
                maxLength: 40
              }}
            />
            <TextField
              fullWidth
              label={labelStrings.company}
              name="company"
              value={formData.company}
              onChange={handleInputChange}
              required
              inputProps={{
                maxLength: 40
              }}
            />
            <TextField
              fullWidth
              label={labelStrings.billingEmail}
              name="billingEmail"
              type="email"
              value={formData.billingEmail}
              onChange={handleInputChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label={labelStrings.addressLine1}
              name="addressLine1"
              value={formData.addressLine1}
              onChange={handleInputChange}
              required
              inputProps={{
                maxLength: 100
              }}
            />
            <TextField
              fullWidth
              label={labelStrings.addressLine2}
              name="addressLine2"
              value={formData.addressLine2}
              onChange={handleInputChange}
              inputProps={{
                maxLength: 100
              }}
            />
            <TextField
              fullWidth
              label={labelStrings.city}
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              required
              inputProps={{
                maxLength: 40
              }}
            />
            <TextField
              fullWidth
              label={labelStrings.zip}
              name="zip"
              value={formData.zip}
              onChange={handleInputChange}
              required
              inputProps={{
                maxLength: 15
              }}
            />
            <CountryField />
            <TextField
              fullWidth
              label={labelStrings.vatId}
              name="vatId"
              value={formData.vatId}
              onChange={handleInputChange}
              inputProps={{
                maxLength: 40
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {formData.country || ''}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
        <Button
          type="submit"
          variant="contained"
          disabled={isUpdateLoading}
          startIcon={isUpdateLoading ? <CircularProgress size={20} /> : <SaveIcon />}
          sx={{
            fontFamily: 'Inter',
            textTransform: 'none',
          }}
        >
          {labelStrings.save}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setIsEditMode(false)
            setFormData(currentBillingDetails)
            setErrorMessage('')
          }}
          startIcon={<CancelIcon />}
          sx={{
            fontFamily: 'Inter',
            textTransform: 'none',
          }}
        >
          {labelStrings.cancel}
        </Button>
      </Box>
    </form>
  )

  if (!isUserDetailsPresent || (isUpdateLoading && !isEditMode)) {
    // Show loading if user details are not present or if an update is ongoing but not in edit mode
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    )
  }

  if (isUserDetailsError) {
    return <Alert severity="error">{labelStrings.notAvailableBillingDetails}</Alert>
  }

  return (
    <Grid container spacing={2} alignItems="top" marginTop='30px' marginBottom='30px'>
      {/* Label column */}
      <Grid item xs={3}>
        <Typography 
          component="h2" 
          sx={{ fontSize: '30px', fontWeight: 600, fontFamily: 'Inter', alignContent: 'top' }}
        >
          {labelStrings.billingDetails}
        </Typography>
      </Grid>
  
      {/* Card column */}
      <Grid item xs={9}>
        <Card sx={{ mb: 4 }}>
          <CardContent>
            {isEditMode ? renderEditMode() : renderViewMode()}
          </CardContent>
        </Card>
      </Grid>
      <SectionsDivider width="100%" />
    </Grid>
  )
}

BillingDetails.propTypes = {
  updateBillingDetails: PropTypes.func,
  openContactUs: PropTypes.func,
  isUserDetailsPresent: PropTypes.bool,
  currentBillingDetails: PropTypes.object,
  isUpdateLoading: PropTypes.bool,
  isUserDetailsError: PropTypes.bool,
  isBillingDetailsPresent: PropTypes.bool,
  isOpenEditDetails: PropTypes.bool,
  isEditMode: PropTypes.bool,
  setIsEditMode: PropTypes.func,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func,
}

export default BillingDetails
