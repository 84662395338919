import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { USER_INDEXES } from '../../../constants/appSettings'
import useTranslation from '../../../localization/useTranslation'
import { constructLinkToWECFileServer } from '../../../utils'
import LatestPostsGalleryItem from './LatestPostsGalleryItem'
import {
  SectionTitleBorder,
  YTButton,
  IgTtButton,
  SectionTitleName,
} from '../commonProfilePageStyles'
import { Galery } from './styles'
import LatestPostsYtGalleryItem from './LatestPostYtGalleryItem'
import {
  LATEST_SHORTS,
  LATEST_POSTS,
  MOST_VIEWED_POSTS,
  MOST_VIEWED_REELS,
  LATEST_REELS,
  LATEST_STORIES,
} from '../../../constants/profile'
import { Stack } from '@mui/material'
import { StoriesModal } from './StoriesModal'

const LatestPostsGallery = ({ profile }) => {
  const {
    postArray,
    postArrayViews,
    shortsArray,
    reelArray,
    reelArrayViews,
    storiesArray,
    _index: platform,
    username,
  } = profile || {}
  const { labelStrings, currentLang } = useTranslation()

  const initialTabValueForYt = postArray?.length ? LATEST_POSTS : LATEST_SHORTS
  const initialTabValueForIgTikTok = postArrayViews?.length ? MOST_VIEWED_POSTS : LATEST_POSTS
  const initialTabValue =
    platform === USER_INDEXES.youtube ? initialTabValueForYt : initialTabValueForIgTikTok

  const [currentTab, setCurrentTab] = useState(initialTabValue)

  const [currStoryData, setCurrentStoryData] = useState(null)

  const handleOpenStoriesModal = storyData => {
    setCurrentStoryData(storyData)
  }

  const handleCloseStoriesModal = () => {
    setCurrentStoryData(null)
  }

  const renderYtButton = (label, arrayLength, tabValue) => {
    return (
      <YTButton
        disabled={!arrayLength}
        isActive={currentTab === tabValue}
        onClick={() => setCurrentTab(tabValue)}
      >
        {label}
      </YTButton>
    )
  }

  const renderIgTtButton = (label, arrayLength, tabValue) => {
    return (
      <IgTtButton
        disabled={!arrayLength}
        platform={platform}
        isActive={currentTab === tabValue}
        onClick={() => setCurrentTab(tabValue)}
      >
        {label}
      </IgTtButton>
    )
  }

  const galleryData = {
    [LATEST_SHORTS]: shortsArray,
    [MOST_VIEWED_POSTS]: postArrayViews,
    [MOST_VIEWED_REELS]: reelArrayViews,
    [LATEST_POSTS]: postArray,
    [LATEST_REELS]: reelArray,
    [LATEST_STORIES]: storiesArray,
  }

  const ytGallery = useMemo(() => {
    return (
      <>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          {renderYtButton(labelStrings.latestYoutuBeVideos, postArray?.length, LATEST_POSTS)}
          {!!shortsArray?.length &&
            renderYtButton(labelStrings.latestYouTubeShorts, shortsArray?.length, LATEST_SHORTS)}
          <SectionTitleBorder />
        </Stack>

        <Galery platform={platform}>
          {galleryData[currentTab]?.map(post => {
            if (platform !== USER_INDEXES.youtube) return
            const imgSrc = post?.imageUrl
            return (
              <div key={post.contentId}>
                <LatestPostsYtGalleryItem
                  imgSrc={imgSrc}
                  postLink={post.contentId}
                  title={post.title}
                  likeCount={post.likes}
                  commentCount={post.comments}
                  viewCount={post.reach}
                  duration={post.duration}
                  engagement={post.reach ? ((post.likes + post.comments) / post.reach) : 0}
                  positiveReactions={post.commentPositivitiyRate}
                />
              </div>
            )
          })}
        </Galery>
      </>
    )
  }, [platform, currentLang, currentTab, galleryData])

  const renderGallery = useMemo(() => {
    return (
      <>
        {platform === USER_INDEXES.instagram ? (
          <Stack direction="row" alignItems="center" spacing={5}>
            <Stack spacing={1}>
              <SectionTitleName platform={platform}>{labelStrings.mostViewed}</SectionTitleName>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                {renderIgTtButton(labelStrings.posts, postArrayViews?.length, MOST_VIEWED_POSTS)}
                {renderIgTtButton(labelStrings.reels, reelArrayViews?.length, MOST_VIEWED_REELS)}
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <SectionTitleName platform={platform}>{labelStrings.latest}</SectionTitleName>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                {renderIgTtButton(labelStrings.posts, postArray?.length, LATEST_POSTS)}
                {renderIgTtButton(labelStrings.reels, reelArray?.length, LATEST_REELS)}
                {renderIgTtButton(labelStrings.stories, storiesArray?.length, LATEST_STORIES)}
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack alignItems="center" direction="row" spacing={1}>
            {renderIgTtButton(
              labelStrings.mostViewedPosts,
              postArrayViews?.length,
              MOST_VIEWED_POSTS
            )}
            {renderIgTtButton(labelStrings.latestPosts, postArray?.length, LATEST_POSTS)}
            <SectionTitleBorder />
          </Stack>
        )}

        <Galery>
          {galleryData[currentTab]?.map((post, index) => {
            let galeryItemProps
            switch (platform) {
              case USER_INDEXES.instagram:
                galeryItemProps = {
                  imgSrc: constructLinkToWECFileServer(post.url || post.imageUrl),
                  platformPostCode: post.code || post.contentId,
                  likeCount: post.likes,
                  commentCount: post.comments,
                  handleOpenStoriesModal: () => handleOpenStoriesModal(post),
                  tabType: currentTab,
                  reelsViews: post.reach,
                }
                break
              case USER_INDEXES.tiktok:
                galeryItemProps = {
                  imgSrc: constructLinkToWECFileServer(post.imageUrl),
                  platformPostCode: post.id || post.contentId,
                  likeCount: post.likeCount || post.likes,
                  commentCount: post.commentCount || post.comments,
                  playCount: post.playCount || post.reach,
                  shareCount: post.shareCount || post.shares,
                }
                break
              default:
                galeryItemProps = {}
                break
            }

            return (
              <LatestPostsGalleryItem
                key={index}
                platform={platform}
                username={username}
                {...galeryItemProps}
              />
            )
          })}
        </Galery>
      </>
    )
  }, [platform, currentLang, currentTab, galleryData])

  return (
    <>
      {platform === USER_INDEXES.youtube ? ytGallery : renderGallery}
      <StoriesModal
        imageSrc={currStoryData?.imageUrl}
        videoUrl={currStoryData?.videoUrl}
        handleCloseStoriesModal={handleCloseStoriesModal}
      />
    </>
  )
}

LatestPostsGallery.propTypes = {
  profile: PropTypes.object.isRequired,
}

export default LatestPostsGallery
