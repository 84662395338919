import React from 'react'
import PropTypes from 'prop-types'
import StarRating from '../../StarRating'
import useTranslation from '../../../localization/useTranslation'
import {
  ProfileRow,
  RowCell,
  Username,
  DisplayName,
  SmallText,
  BoldText,
  PayAttentionText,
  CheckboxWrapper,
  RowWrapper,
  RowLink,
} from '../profileListTable/tableStyles'
import RowCellContent from '../profileListTable/profileListUserRow/RowCellContent'
import ProfileUnlockedStatusContent from '../profileListTable/ProfileUnlockedStatusContent'
import { formatDateToLocale, formatFollowers, pretifyBigNum } from '../../../utils'
import Checkbox from '../../common/checkboxes/Checkbox'
import { SOCIAL_PLATFORMS_NAMES } from '../../../constants/appSettings'
import AdditionalFields from '../../influencerProfile/notesAndAdditionalInfo/additionalFields/AdditionalFields'

const ProfileListViewUserRowDetails = ({
  user,
  selectSingle,
  isCollectionView = false,
  isUnlockedCollectionOpened = false,
  applyMocked = false,
  isFullTable,
  privateRating = '',
  userlanguage = '',
  showUserGrowth,
  onStarClick,
  onRowClick,
  stopEventBubbling,
  link = '#',
  isBlackListCollection = false,
  currentFieldsState = {},
  additionalFields = [],
}) => {
  const { flags, currentLang } = useTranslation()

  const hasCheckbox = isCollectionView && !isUnlockedCollectionOpened
  const hasStarRating = isCollectionView && !isUnlockedCollectionOpened
  const hasUnlockedStatus = isCollectionView && isUnlockedCollectionOpened

  return (
    <>
      <ProfileRow userindex={user._index} onClick={onRowClick} id={user._id} isList>
        {hasCheckbox && !isBlackListCollection && (
          <RowCell
            onClick={stopEventBubbling}
            onMouseDown={stopEventBubbling}
            width={30}
            isListView
          >
            <RowCellContent link={link} />
            <CheckboxWrapper>
              <Checkbox isChecked={user.isSelected || false} onChange={selectSingle} />
            </CheckboxWrapper>
          </RowCell>
        )}

        {currentFieldsState[`profileScore`]?.show && (
          <RowCell width={200} isListView>
            <RowCellContent link={link} />
            <RowWrapper>
              <BoldText applyMocked={applyMocked}>{user?.profileScore?.overall}</BoldText>
            </RowWrapper>
          </RowCell>
        )}

        <RowCell width={200} isListView>
          <RowCellContent link={link} />
          <RowLink to={`/profile/${user._id}`} rel="noopener noreferrer">
            <Username userindex={user?._index} applymocked={applyMocked ? 'true' : undefined}>
              {user?.username}
            </Username>
          </RowLink>
        </RowCell>

        {currentFieldsState[`country`]?.show && (
          <RowCell width={170} isListView>
            <RowCellContent link={link} />
            <RowWrapper>
              <SmallText applyMocked={applyMocked}>
                {flags[user?.country] ? flags[user?.country] : user?.country} {user?.country || '-'}
              </SmallText>
            </RowWrapper>
          </RowCell>
        )}

        {currentFieldsState[`city`]?.show && (
          <RowCell width={170} isListView>
            <RowCellContent link={link} />
            <RowWrapper>
              <SmallText applyMocked={applyMocked}>{user?.city || '-'}</SmallText>
            </RowWrapper>
          </RowCell>
        )}

        {currentFieldsState[`displayName`]?.show && (
          <RowCell width={170} isListView>
            <RowCellContent link={link} />
            <RowWrapper>
              <RowLink to={`/profile/${user._id}`} rel="noopener noreferrer">
                <DisplayName
                  userindex={user?._index}
                  applymocked={applyMocked ? 'true' : undefined}
                >
                  {user?.displayName || '-'}
                </DisplayName>
              </RowLink>
            </RowWrapper>
          </RowCell>
        )}

        {currentFieldsState[`followers`]?.show && (
          <RowCell width={125} isListView>
            <RowCellContent link={link} />
            <RowWrapper>
              <BoldText applyMocked={applyMocked} asBlock>
                {formatFollowers(user?.followers)}
              </BoldText>
            </RowWrapper>
          </RowCell>
        )}

        {currentFieldsState[`language`]?.show && (
          <RowCell width={125} isListView>
            <RowCellContent link={link} />
            <RowWrapper>
              <SmallText applyMocked={applyMocked}>{userlanguage?.label || ''}</SmallText>
            </RowWrapper>
          </RowCell>
        )}

        {currentFieldsState[`engagementMean`]?.show && (
          <RowCell width={125} isListView>
            <RowCellContent link={link} />
            <RowWrapper>
              <SmallText applyMocked={applyMocked}>{user?.engagementMean}</SmallText>
            </RowWrapper>
          </RowCell>
        )}

        {currentFieldsState[`monthlyGrowthFollowers`]?.show && (
          <RowCell width={125} isListView>
            <RowCellContent link={link} />
            <RowWrapper>
              <BoldText applyMocked={applyMocked} asBlock>
                {user?.monthlyGrowthFollowers?.length && user?.monthlyGrowthFollowers[0]
                  ? pretifyBigNum(user?.monthlyGrowthFollowers[0])
                  : 0}
              </BoldText>
            </RowWrapper>
          </RowCell>
        )}

        {currentFieldsState[`monthlyGrowthPercentage`]?.show && (
          <RowCell width={125} isListView>
            <RowCellContent link={link} />
            <RowWrapper>
              <BoldText applyMocked={applyMocked} asBlock>
                {user?.monthlyGrowthFollowers?.length ? `${user?.monthlyGrowthFollowers[1]}%` : 0}
              </BoldText>
            </RowWrapper>
          </RowCell>
        )}

        {currentFieldsState[`impressions`]?.show && (
          <RowCell width={125} isListView>
            <RowCellContent link={link} />
            <RowWrapper>
              <PayAttentionText userindex={user?._index} applyMocked={applyMocked}>
                {user?.impressions ? pretifyBigNum(user?.impressions) : 0}
              </PayAttentionText>
            </RowWrapper>
          </RowCell>
        )}

        {currentFieldsState[`dateAdded`]?.show && (
          <RowCell width={125} isListView>
            <RowCellContent link={link} />
            <RowWrapper>
              <SmallText applyMocked={applyMocked}>
                {formatDateToLocale({ dateStr: user?.unlockedOn, currentLang }) || '-'}
              </SmallText>
            </RowWrapper>
          </RowCell>
        )}

        {hasStarRating && !isBlackListCollection && currentFieldsState[`stars`]?.show && (
          <RowCell width={325} isListView>
            <RowCellContent link={link} />
            <RowWrapper>
              <StarRating
                nrOfStars={user?.stars}
                onStarClick={onStarClick}
                userindex={user?._index}
                allowRating={privateRating}
                stopEventBubbling={stopEventBubbling}
              />
            </RowWrapper>
          </RowCell>
        )}

        {currentFieldsState[`_index`]?.show && (
          <RowCell width={125} isListView>
            <RowCellContent link={link} />
            <RowWrapper>
              <SmallText applyMocked={applyMocked}>
                {SOCIAL_PLATFORMS_NAMES[user?._index]?.prettyName}
              </SmallText>
            </RowWrapper>
          </RowCell>
        )}

        {currentFieldsState[`comment`]?.show && (
          <RowCell width={125} isListView>
            <RowCellContent link={link} />
            <RowWrapper lastEl>
              <SmallText applyMocked={applyMocked}>{user?.comment || '-'}</SmallText>
            </RowWrapper>
          </RowCell>
        )}

        {additionalFields?.map((field, idx) => {
          if (field.isHidden || !currentFieldsState[field.code]?.show) {
            return
          }

          return (
            <RowCell width={240} key={field.code} isListView>
              <RowWrapper
                onClick={e => e.stopPropagation()}
                customMaxWidth
                customWidth={field.type === 'multiselect'}
              >
                <AdditionalFields
                  profileId={user._id}
                  platform={user._index}
                  additionalFields={[additionalFields[idx]]}
                  userAdditionalFields={user.additionalFields}
                  isCollection
                />
              </RowWrapper>
            </RowCell>
          )
        })}

        {hasUnlockedStatus && (
          <RowCell width={125}>
            <ProfileUnlockedStatusContent
              unlockedOn={user?.unlockedOn}
              isAudienceAvailable={user?.audienceAvailable}
            />
          </RowCell>
        )}
      </ProfileRow>
    </>
  )
}

ProfileListViewUserRowDetails.propsTypes = {
  user: PropTypes.object.isRequired,
  isCollectionView: PropTypes.bool,
  isUnlockedCollectionOpened: PropTypes.bool,
  applyMocked: PropTypes.bool,
  privateNotes: PropTypes.string,
  privateRating: PropTypes.string,
  showUserGrowth: PropTypes.bool,
  selectSingle: PropTypes.func,
  isFullTable: PropTypes.bool,
  userlanguage: PropTypes.string,
  onStarClick: PropTypes.func,
  onCommentAdd: PropTypes.func,
  onRowClick: PropTypes.func,
  stopEventBubbling: PropTypes.func,
  link: PropTypes.string,
  isBlackListCollection: PropTypes.bool,
  currentFieldsState: PropTypes.object,
  additionalFields: PropTypes.array,
}

export default ProfileListViewUserRowDetails
