import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  isUserDetailsPresentSelector,
  userDetailsSelector,
  isUserDetailsErrorSelector,
} from '../../../redux/selectors';
import { withReduxActions } from '../../../hocs/withRedux';
import { toggleContactUs } from '../../../redux/ducks/appSettingsDucks';
import { updateUserBillingDetails } from '../../../redux/ducks/userDucks';
import BillingDetails from './BillingDetails';
import { Box } from '@mui/material';


const BillingDetailsContainer = ({ toggleContactUs, updateUserBillingDetails, isOpenEditDetails }) => {
  const isUserDetailsPresent = useSelector(isUserDetailsPresentSelector);
  const userDetails = useSelector(userDetailsSelector);
  const isUserDetailsError = useSelector(isUserDetailsErrorSelector);

  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { billing_address = {} } = userDetails;

  const isBillingDetailsPresent = !!Object.keys(billing_address).length;

  const prepareUpdatedBillingDetails = formUpdateDetails => ({
    vat_number: formUpdateDetails.vatId,
    billing_address: {
      first_name: formUpdateDetails.firstName,
      last_name: formUpdateDetails.lastName,
      company: formUpdateDetails.company,
      line1: formUpdateDetails.addressLine1,
      line2: formUpdateDetails.addressLine2,
      city: formUpdateDetails.city,
      zip: formUpdateDetails.zip,
      state: formUpdateDetails.state,
      email: formUpdateDetails.billingEmail,
      country: formUpdateDetails.country,
    },
  });

  const currentBillingDetails = {
    firstName: billing_address.first_name || '',
    lastName: billing_address.last_name || '',
    company: billing_address.company || '',
    addressLine1: billing_address.line1 || '',
    addressLine2: billing_address.line2 || '',
    zip: billing_address.zip || '',
    city: billing_address.city || '',
    country: billing_address.country || '',
    vatId: billing_address.vat_number || '',
    billingEmail: billing_address.email || '',
  };

  // In BillingDetailsContainer
  const updateBillingDetailsHandler = async updatedDetails => {
    setIsUpdateLoading(true);
    setErrorMessage('');
    try {
      await updateUserBillingDetails(prepareUpdatedBillingDetails(updatedDetails));
      // Success: close edit mode
      setIsEditMode(false);
    } catch (error) {
      // On error: remain in edit mode and display error
      setErrorMessage(error.message || 'Error updating billing details. Please try again.');
      setIsEditMode(true);
    } finally {
      setIsUpdateLoading(false);
    }
  };

  return (
    <Box sx={{ marginLeft: '80px', marginRight: '50px' }}>
      <BillingDetails
        updateBillingDetails={updateBillingDetailsHandler}
        openContactUs={toggleContactUs}
        isUserDetailsPresent={isUserDetailsPresent}
        currentBillingDetails={currentBillingDetails}
        isUpdateLoading={isUpdateLoading}
        isUserDetailsError={isUserDetailsError}
        isBillingDetailsPresent={isBillingDetailsPresent}
        isOpenEditDetails={isOpenEditDetails}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </Box>
  );
};

BillingDetailsContainer.propTypes = {
  toggleContactUs: PropTypes.func,
  updateUserBillingDetails: PropTypes.func,
  isOpenEditDetails: PropTypes.bool
};

export default withReduxActions({ toggleContactUs, updateUserBillingDetails })(
  BillingDetailsContainer
);
