import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userCredsSelector } from '../../redux/selectors'
import GradientTransparentButton from '../common/buttons/GradientTransparentButton'
import useTranslation from '../../localization/useTranslation'
import { COLLECTIONS_ACCESS_TYPES_NEW } from '../../constants/collections'
import { ModalComponent } from '../../new-ui/components/modal/ModalComponent'
import { Box, Button, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { changeCollectionAccess } from '../../redux/ducks/collectionsDucks'
import WithTip from '../common/popups/WithTip'

const CollectionAccessSwitcher = ({
  access = '',
  collectionId,
  collectionName,
  isOwnerCollection,
}) => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()

  const { organizationName } = useSelector(userCredsSelector)

  const [isModalOpened, setModalOpened] = useState(false)

  const [collectionAccess, setCollectionAccess] = useState('')

  const onCloseModal = () => {
    setModalOpened(false)
    setCollectionAccess('')
  }

  const onOpenModal = () => {
    setCollectionAccess('')
    setModalOpened(true)
  }

  const onChangeCollectionAccess = ({ target }) => {
    const newAccess = target.value
    setCollectionAccess(newAccess)
  }

  const onConfirm = () => {
    if (!collectionId) return

    dispatch(
      // @ts-ignore
      changeCollectionAccess({
        collectionId,
        access: collectionAccess,
      })
    )
    onCloseModal()
  }

  const accessValuesArr = [
    {
      value: COLLECTIONS_ACCESS_TYPES_NEW.organization,
      label: organizationName || COLLECTIONS_ACCESS_TYPES_NEW.organization,
    },
    {
      value: COLLECTIONS_ACCESS_TYPES_NEW.restricted,
      label: COLLECTIONS_ACCESS_TYPES_NEW.restricted,
    },
    {
      value: COLLECTIONS_ACCESS_TYPES_NEW.anyone,
      label: COLLECTIONS_ACCESS_TYPES_NEW.anyone,
    },
  ]

  const accessDescription = {
    [COLLECTIONS_ACCESS_TYPES_NEW.organization]:
      labelStrings.collectionsAccessDescriptions.organization,
    [COLLECTIONS_ACCESS_TYPES_NEW.restricted]:
      labelStrings.collectionsAccessDescriptions.restricted,
    [COLLECTIONS_ACCESS_TYPES_NEW.anyone]: labelStrings.collectionsAccessDescriptions.anyone,
  }

  useEffect(() => {
    if (!collectionAccess?.length && access) {
      setCollectionAccess(access)
    }
  }, [access, collectionAccess])

  return (
    <Stack sx={{ mr: '10px' }}>
      {!isOwnerCollection ? (
        <WithTip tipText={labelStrings.disabledChangeAccess} position="bottom center">
          <div>
            <GradientTransparentButton onClick={onOpenModal} isDisabled={true}>
              {labelStrings.changeAccess}
            </GradientTransparentButton>
          </div>
        </WithTip>
      ) : (
        <GradientTransparentButton onClick={onOpenModal}>
          {labelStrings.changeAccess}
        </GradientTransparentButton>
      )}

      {isModalOpened && (
        <ModalComponent
          title={labelStrings.changeAccessOf(collectionName)}
          closeModal={onCloseModal}
          isOpenModal={isModalOpened}
          withoutScrollWrapper
          isCenterTitle
          contentWidth={`400px`}
          contentHeight={'auto'}
          smallPadding
          smallPaddingContent
          isGradientBg
          isFilledTitle
        >
          <Stack alignItems="center" sx={{ py: 2 }}>
            <Stack sx={{ minWidth: '300px' }} spacing={0.5}>
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  color: '#784E4E',
                  fontSize: '13px',
                  fontWeight: 600,
                }}
              >
                {labelStrings.generalAccess}
              </Typography>

              <Stack direction="row" alignItems="center" spacing={1}>
                <Box
                  sx={{
                    backgroundColor: '#FFF1F1',
                    color: '#442424',
                    borderRadius: '50%',
                    height: '34px',
                    width: '34px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <LockOutlinedIcon sx={{ fontSize: '18px' }} />
                </Box>
                <Stack>
                  <FormControl variant="standard" sx={{ width: 'fit-content' }}>
                    <Select
                      size="small"
                      value={collectionAccess || COLLECTIONS_ACCESS_TYPES_NEW.organization}
                      onChange={onChangeCollectionAccess}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            fontFamily: 'Inter',
                            color: '#442424',
                            '& .MuiMenuItem-root': {
                              fontSize: '13px',
                              textTransform: 'capitalize',
                            },
                          },
                        },
                      }}
                      sx={{
                        color: '#442424',
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '12px',
                        '& .MuiInput-input:focus': {
                          backgroundColor: 'transparent !important',
                        },
                        '& svg': {
                          color: '#442424',
                        },
                        '&:hover': {
                          '&:before': {
                            borderBottom: '1px solid #442424 !important',
                          },
                        },
                        '&:before': {
                          borderBottom: 'unset',
                        },
                        '&:after': {
                          borderBottom: 'unset',
                        },
                      }}
                    >
                      {accessValuesArr.map(el => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      color: '#784E4E',
                      fontSize: '11px',
                    }}
                  >
                    {accessDescription[collectionAccess]}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ pb: 2, pt: 1 }}
          >
            <Button
              onClick={onCloseModal}
              variant="outlined"
              color="primary"
              sx={{ borderColor: '#FF5C00', color: '#FF5C00' }}
            >
              {labelStrings.cancel}
            </Button>
            <Button
              onClick={onConfirm}
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: '#FF5C00',
              }}
            >
              {labelStrings.confirm}
            </Button>
          </Stack>
        </ModalComponent>
      )}
    </Stack>
  )
}

export default CollectionAccessSwitcher
