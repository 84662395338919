import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { validateEmail } from '../../../utils'
import useTranslation from '../../../localization/useTranslation'
import { Alert, Box, Button, Stack, TextField, Typography } from '@mui/material'

const EmailToGetLinkForm = ({ sendLinkToEmail, noUserFound = false, resetNoUserFound }) => {
  const { labelStrings } = useTranslation()

  const [formDataObj, setFormDataObj] = useState({
    email: '',
    isValidEmail: true,
  })

  const changeFormDataObj = newValueObj => setFormDataObj({ ...formDataObj, ...newValueObj })

  const onEmailChange = ({ target }) => {
    changeFormDataObj({ email: target.value })
  }

  const resetValidation = () => {
    if (noUserFound) resetNoUserFound()
    changeFormDataObj({ isValidEmail: true })
  }

  const onSubmit = async e => {
    e.preventDefault()
    if (noUserFound) return
    const isValidEmail = !!formDataObj.email && validateEmail(formDataObj.email)
    if (!isValidEmail) return changeFormDataObj({ isValidEmail })
    const { email } = formDataObj
    await sendLinkToEmail(email.toLowerCase())
  }

  return (
    <>
      {noUserFound && (
        <Alert
          sx={{
            mt: 2,
            mb: 1,
            fontSize: '12px',
            padding: '2px 6px',
            fontFamily: 'Inter',
            '& .MuiAlert-message': {
              padding: '2px 0',
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'pre-line',
            },
            '& .MuiAlert-icon': {
              display: 'flex',
              alignItems: 'center',
              marginRight: '8px',
            },
          }}
          variant="outlined"
          severity="error"
        >
          {labelStrings.noUserWithSuchEmail}
        </Alert>
      )}

      <Typography
        textAlign="center"
        sx={{
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '18.8px',
          textAlign: 'center',
          fontFamily: 'Inter',
          background: `linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)`,
          backgroundClip: `text`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          py: 0.5,
          mt: 2,
        }}
      >
        {labelStrings.restorePassword}
      </Typography>

      <form
        onSubmit={onSubmit}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: `column`,
          justifyContent: `space-around`,
          marginTop: `28px`,
          width: `100%`,
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '16px',
            color: '#442424',
            px: 1,
          }}
        >
          {labelStrings.weWillSendPassRecoveryLink}:
        </Typography>

        <Box sx={{ width: '100%', my: 1 }}>
          <TextField
            size="small"
            sx={{
              width: '100%',
              borderRadius: '8px',
              borderColor: '#A06E6E',
              backgroundColor: '#FDFCFF',
              '& .Mui-focused.MuiAutocomplete-input': {
                backgroundColor: '#FDFCFF',
                mt: '0px !important',
              },
              '& .MuiInputLabel-root': {
                fontFamily: 'Inter',
                color: '#A77D7D !important',
                mt: '3px',
                '&:focused, &:active': {
                  color: '#A77D7D',
                },
              },
              '& .Mui-focused': {
                mt: '0px !important',
              },
              '& .MuiOutlinedInput-root': {
                height: '46px',
                '& fieldset': {
                  borderColor: '#A06E6E',
                },
                '&:hover fieldset': {
                  borderColor: '#A06E6E',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#A06E6E',
                },
              },
            }}
            label={labelStrings.email}
            id="email_input"
            type="text"
            onChange={onEmailChange}
            onFocus={resetValidation}
            value={formDataObj.email}
            error={!formDataObj.isValidEmail}
            helperText={!formDataObj.isValidEmail ? labelStrings.notValidEmail : null}
          />
        </Box>
        <Stack sx={{ width: '100%', mt: 1 }} direction="row" alignItems="end" justifyContent="end">
          <Button
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '16px',
              color: '#FFF5F5',
              fontFamily: 'Inter',
              minWidth: '100px',
              width: 'fit-content',
              height: '46px',
              textTransform: 'uppercase',
              border: 'none',
              background:
                'transparent linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)',
            }}
            variant="contained"
            type="submit"
          >
            {labelStrings.send}
          </Button>
        </Stack>
      </form>
    </>
  )
}

EmailToGetLinkForm.propTypes = {
  sendLinkToEmail: PropTypes.func,
  noUserFound: PropTypes.bool,
  resetNoUserFound: PropTypes.func,
}

export default EmailToGetLinkForm
