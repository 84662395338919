import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Stack, TextField, Typography } from '@mui/material'
import useTranslation from '../../../localization/useTranslation'
import { Form } from '../../common/styledWrappers/signupFormsStyles/signupStepOneFormStyles'
import httpService from '../../../services/httpService'
import { toast } from 'react-toastify'

const StyledLoginBtn = {
  fontFamily: `Lato, sans-serif`,
  fontWeight: 'bold',
  fontSize: '17px',
  lineHeight: '16px',
  color: '#fff',
  width: '100%',
  height: '48px',
  textTransform: 'uppercase',
  border: 'none',
  background:
    'transparent linear-gradient(89deg, #FF726F 0%, #FFCA55 120%) 0% 0% no-repeat padding-box',
}

const SignupStepOneVerifyEmail = props => {
  const { stepTwoVerifiedSignupToken, email, isVerifiedSignupToken } = props || {}
  const { labelStrings } = useTranslation()

  const [formState, changeFormState] = useState({
    verifiedCode: '',
    isValidCode: false,
    isFinallyValidCode: false,
    errorText: '',
  })

  const [isFiredConfirm, setIsFiredConfirm] = useState(false)

  const hasErrorText = !!formState.errorText?.length

  const changeVerifiedCode = ({ target }) => {
    const value = target.value
    const numericValue = value.replace(/\D/g, '')
    const truncatedValue = numericValue?.slice(0, 6)
    changeFormState({
      ...formState,
      verifiedCode: truncatedValue,
      isFinallyValidCode: truncatedValue?.length === 6 ? true : false,
      errorText: truncatedValue?.length === 6 ? '' : formState.errorText,
    })
    isFiredConfirm && setIsFiredConfirm(false)
  }

  const onFocusInput = () => {
    changeFormState({ ...formState, errorText: '' })
    !formState.isValidCode && changeFormState({ ...formState, isValidCode: true })
  }

  const submitForm = e => {
    e.preventDefault()

    if (formState.isValidCode && formState.isFinallyValidCode) {
      const { verifiedCode } = formState
      stepTwoVerifiedSignupToken(verifiedCode.trim())
    }

    changeFormState({ ...formState, isValidCode: false })
    setIsFiredConfirm(true)
  }

  const onBlurVerifiedCode = () => {
    if (formState.verifiedCode.length !== 6) {
      changeFormState({
        ...formState,
        errorText: labelStrings.signupLocalization.enterYourDigitCode,
      })
    } else {
      changeFormState({
        ...formState,
        errorText: '',
        isFinallyValidCode: true,
      })
    }
  }

  const onHandleResetVerifiedCode = async () => {
    await httpService.requestSignupToken({ email })
    toast.success(
      <Stack>
        <Typography sx={{ fontFamily: `Lato, sans-serif`, color: '#1F2937', fontWeight: 'bold' }}>
          {labelStrings.signupLocalization.emailSent}
        </Typography>
        <Typography sx={{ fontFamily: `Lato, sans-serif`, fontSize: '15px', color: '#6B7280' }}>
          {labelStrings.signupLocalization.TheEmailIsOnItsWay}
        </Typography>
      </Stack>,
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    )
  }

  return (
    <Form onSubmit={submitForm}>
      <Stack sx={{ width: '100%' }} justifyContent="center" alignItems="center">
        <Stack sx={{ width: '100%' }} spacing={5}>
          <Stack direction="row" alignItems="end" sx={{ width: '100%' }}>
            <TextField 
              variant="standard"
              sx={{
                width: '100%',
              }}
              label={labelStrings.signupLocalization.enterYourDigitCode}
              id="verified-signup-token"
              type="number"
              onChange={changeVerifiedCode}
              onFocus={onFocusInput}
              onBlur={onBlurVerifiedCode}
              value={formState.verifiedCode}
              error={hasErrorText || (!isVerifiedSignupToken && isFiredConfirm)}
              helperText={
                !isVerifiedSignupToken && isFiredConfirm
                  ? labelStrings.signupLocalization.expiredCode
                  : formState.errorText
              }
              
              inputProps={{
                maxLength: 6,
                inputMode: 'numeric',
                pattern: '[0-9]*'
              }}
              onWheel={e => e?.target?.blur()}
              autoFocus
            />
          </Stack>

          <Button
            disabled={!formState.isFinallyValidCode || (!isVerifiedSignupToken && isFiredConfirm)}
            sx={
              !formState.isFinallyValidCode || (!isVerifiedSignupToken && isFiredConfirm)
                ? {
                    fontFamily: `Lato, sans-serif`,
                    fontSize: StyledLoginBtn.fontSize,
                    fontWeight: StyledLoginBtn.fontWeight,
                    height: StyledLoginBtn.height,
                  }
                : StyledLoginBtn
            }
            variant="contained"
            type="submit"
          >
            {labelStrings.confirm}
          </Button>
        </Stack>

        <Typography textAlign="center" variant="body-2" sx={{ mt: 5 }}>
          <Typography
            variant="body-2"
            textAlign="center"
            sx={{ fontFamily: `Lato, sans-serif`, fontSize: '13px', color: '#6B7280' }}
          >
            {labelStrings.signupLocalization.makeSureToKeepThisWindowOpen}{' '}
          </Typography>
          <Typography
            sx={{
              fontFamily: `Lato, sans-serif`,
              fontSize: '13px',
              fontWeight: 600,
              cursor: 'pointer',
              color: '#FF5D5A',
              '&:hover': {
                opacity: 0.8,
              },
            }}
            onClick={onHandleResetVerifiedCode}
          >
            {labelStrings.signupLocalization.resendNow}
          </Typography>
        </Typography>
      </Stack>
    </Form>
  )
}

SignupStepOneVerifyEmail.propTypes = {
  goToStepTwo: PropTypes.func,
  goToStepTwoGoogle: PropTypes.func,
  stepTwoVerifiedSignupToken: PropTypes.func,
}

export default SignupStepOneVerifyEmail
