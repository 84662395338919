import React from 'react'
import useTranslation from '../../../../localization/useTranslation'
import { ModalComponent } from '../../../../new-ui/components/modal/ModalComponent'
import { Box, Button, Stack, Typography } from '@mui/material'

const RemoveItemsFromCollectionDialog = ({ itemsCount, close, removeItemsHandler, isOpen }) => {
  const { labelStrings } = useTranslation()

  const closeHandler = () => close()
  return (
    <ModalComponent
      isOpenModal={isOpen}
      closeModal={closeHandler}
      title={labelStrings.warning}
      contentWidth={'489px'}
      contentHeight="auto"
      isCenterTitle
      withoutScrollWrapper
      isFilledTitle
      isGradientBg
      smallPadding
    >
      <Box sx={{ py: 3 }}>
        <Stack spacing={1} alignItems="center" sx={{ mb: 1 }}>
          <Typography textAlign="center" sx={{ fontFamily: 'Inter' }}>
            {labelStrings.removeNItemsWarning(itemsCount)}
          </Typography>
          <Typography textAlign="center" sx={{ fontFamily: 'Inter', fontWeight: 700 }}>
            {labelStrings.areYouSureToProceed}
          </Typography>
        </Stack>

        <Stack
          sx={{ width: '100%', mt: 3}}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            onClick={closeHandler}
            variant="outlined"
            color="primary"
            sx={{ borderColor: '#FF5C00', color: '#FF5C00' }}
          >
            {labelStrings.cancel}
          </Button>
          <Button
            disabled={false}
            onClick={removeItemsHandler}
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: '#FF5C00',
            }}
          >
            {labelStrings.remove}
          </Button>
        </Stack>
      </Box>
    </ModalComponent>
  )
}

export default RemoveItemsFromCollectionDialog
