import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../localization/useTranslation'
import LanguageSwitcher from './languageSwitcher'
import {
  FooterElement,
  LinkToLanding,
  Text,
  RowFlex,
  LangWrapper,
} from './common/styledWrappers/footerStyles'
import { constructLinkToLandingPage, getPngCountryFlags } from '../utils'
import { Stack } from '@mui/material'

const Footer = ({ isFixedFooter = false }) => {
  const { labelStrings, countryOptions, currentLang } = useTranslation()

  const onlinePunksCountry = countryOptions.find(el => el.value === 'Austria')
  const currentYear = new Date().getFullYear()

  const privacyLinks = [
    {
      label: labelStrings.termsAndConditions,
      href: constructLinkToLandingPage('terms', currentLang),
    },
    {
      label: labelStrings.impressum,
      href: constructLinkToLandingPage('impressum', currentLang),
    },
    {
      label: labelStrings.privacy,
      href: constructLinkToLandingPage('privacy', currentLang),
    },
  ]

  return (
    <FooterElement isFixed={isFixedFooter}>
      <div>
        <Text left>
          {currentYear} &copy; weCreate Data GmbH · Vienna,{' '}
          {onlinePunksCountry ? (
            <Stack direction="row" alignItems="center" sx={{ width: 'fit-content', ml: 0.5 }}>
              {getPngCountryFlags('AT')}
              {onlinePunksCountry.value}
            </Stack>
          ) : (
            'Austria'
          )}
        </Text>
      </div>

      <RowFlex>
        <LangWrapper>
          <LanguageSwitcher />
        </LangWrapper>
        <Stack direction='row' alignItems='center'>
          {privacyLinks.map((link, index) => (
            <LinkToLanding href={link.href} target="_blank" key={index}>
              <Text right>{link.label}</Text>
            </LinkToLanding>
          ))}
        </Stack>
      </RowFlex>
    </FooterElement>
  )
}

Footer.propTypes = {
  isFixedFooter: PropTypes.bool,
}

export default Footer
