import httpService from '../../services/httpService'
import { searchResultsSelector } from '../selectors'
import { actionProvider } from './actionsProvider'
import { changeSuggestionsLoading, setSearchResults } from './searchDucks'

// action types
const SET_SUGGESTIONS_CREATORS_ARRAY = 'SET_SUGGESTIONS_CREATORS_ARRAY'
const SET_RECENT_SEARCH_ARRAY = 'SET_RECENT_SEARCH_ARRAY'
const SET_EXPLORE_QUERY = 'SET_EXPLORE_QUERY'
const SET_SUGGESTION_SELECTED_DATA = 'SET_SUGGESTION_SELECTED_DATA'
const SET_ERROR = 'SET_ERROR'
const SET_LOADING = 'SET_LOADING'
const SET_CURRENT_EXPLORE_LIST_DATA = 'SET_CURRENT_EXPLORE_LIST_DATA'
const SET_ADDITIONAL_DATA = 'SET_ADDITIONAL_DATA'

const initState = {
  suggestedCreatorsArray: [],
  recentSearchArray: [],
  recentSearchQuery: {},
  suggestionSelectedData: {
    isLoading: false,
    userdata: [],
  },
  isLoading: false,
  error: null,
  currentExploreListData: {},
  additionalData: {}
}

// reducer
export const exploreReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case SET_SUGGESTIONS_CREATORS_ARRAY:
      return { ...state, suggestedCreatorsArray: action.payload }
    case SET_RECENT_SEARCH_ARRAY:
      return { ...state, recentSearchArray: action.payload }
    case SET_EXPLORE_QUERY:
      return {
        ...state,
        recentSearchQuery: action.payload,
      }
    case SET_SUGGESTION_SELECTED_DATA:
      return {
        ...state,
        suggestionSelectedData: action.payload,
      }
    case SET_CURRENT_EXPLORE_LIST_DATA:
      return {
        ...state,
        currentExploreListData: action.payload,
      }
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case SET_ADDITIONAL_DATA: 
      return {
        ...state,
        additionalData: action.payload
      }
    default:
      return state
  }
}

// actions
export const setSuggestionsArray = payload =>
  actionProvider(SET_SUGGESTIONS_CREATORS_ARRAY, payload)
export const setRecentSearchArray = payload => actionProvider(SET_RECENT_SEARCH_ARRAY, payload)
export const changeExploreQuery = payload => actionProvider(SET_EXPLORE_QUERY, payload)
export const changeSuggestionSelectedData = payload =>
  actionProvider(SET_SUGGESTION_SELECTED_DATA, payload)
export const changeCurrentExploreListData = payload =>
  actionProvider(SET_CURRENT_EXPLORE_LIST_DATA, payload)
export const changeLoading = payload => actionProvider(SET_LOADING, payload)
export const setError = payload => actionProvider(SET_ERROR, payload)
export const changeAdditionalData = payload => actionProvider(SET_ADDITIONAL_DATA, payload)

// async actions
export const getLookalikeLists = () => async dispatch => {
  try {
    const lookaLikeListData = await httpService.getLookalikeLists()
    dispatch(setSuggestionsArray(lookaLikeListData))
  } catch (err) {
    console.error(err)
  }
}

export const getSuggestedUsersById = ({ queryId }) => async (dispatch, getState) => {
  const searchResults = searchResultsSelector(getState())
  const { dataSuccess } = searchResults || {}

  dispatch([
    changeSuggestionsLoading(true),
    changeSuggestionSelectedData({
      isLoading: true,
    }),
  ])

  try {
    const suggestedUsersByIdData = await httpService.getSuggestedUsersById({ queryId })
    if (suggestedUsersByIdData?.userdata?.length) {
      dispatch(
        setSearchResults({
          results: {
            users: suggestedUsersByIdData?.userdata,
            count: suggestedUsersByIdData?.userdata?.length,
            content: [],
            dataSuccess,
          },
        })
      )

      dispatch([
        changeSuggestionSelectedData({
          ...suggestedUsersByIdData,
          isLoading: false,
        }),
        changeSuggestionsLoading(false),
      ])
    }
  } catch (err) {
    console.error(err)
    dispatch([
      changeSuggestionSelectedData({
        isLoading: false,
      }),
      changeSuggestionsLoading(false),
    ])
  }
}

export const buildLookalike = ({ platform, userList, label }) => async dispatch => {
  dispatch(
    changeSuggestionSelectedData({
      isLoading: true,
    })
  )
  try {
    const sendBuildLookalike = await httpService.buildLookalike({ platform, userList, label })

    if (sendBuildLookalike === 'Created') {
      dispatch(
        changeSuggestionSelectedData({
          isLoading: false,
        })
      )
      dispatch(getLookalikeLists())
    }
  } catch (err) {
    console.error(err)
    dispatch(
      changeSuggestionSelectedData({
        isLoading: false,
      })
    )
  } finally {
    dispatch(
      changeSuggestionSelectedData({
        isLoading: false,
      })
    )
  }
}

export const deleteLookalikeList = ({ id }) => async dispatch => {
  dispatch(
    changeSuggestionSelectedData({
      isLoading: true,
    })
  )
  try {
    const deleteLookalike = await httpService.deleteLookalikeList({ id })

    if (deleteLookalike === 'Created') {
      dispatch([
        changeSuggestionSelectedData({
          isLoading: false,
        }),
        dispatch(getLookalikeLists())
      ])
    }
  } catch (err) {
    console.error(err)
    dispatch(
      changeSuggestionSelectedData({
        isLoading: false,
      })
    )
  } finally {
    dispatch(
      changeSuggestionSelectedData({
        isLoading: false,
      })
    )
  }
}