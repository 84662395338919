// @ts-nocheck
import React, { useState } from 'react'
import { Alert, Box, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { currentCampaignAirTableDataSelector } from '../../../redux/selectors'
import useTranslation from '../../../localization/useTranslation'
import LoadingSpinner from '../../../components/common/spinners/LoadingSpinner'

export const AirtableView = props => {
  const { campaign_content_limit_days = 1000 } = props || {}
  const { labelStrings } = useTranslation()
  const { baseId, shareId } = useSelector(currentCampaignAirTableDataSelector) || {}

  const [iframeLoading, setIframeLoading] = useState(true)

  const airTableLink = baseId && shareId ? `https://airtable.com/embed/${baseId}/${shareId}?backgroundColor=yellowDusty&embedded=true` : ''

  const CONTENT_LIMIT_STRING = labelStrings.airTableLimitStringMoreThousand
    /*campaign_content_limit_days < 1000
      ? labelStrings.airTableLimitStringLessThousand(campaign_content_limit_days)
      : labelStrings.airTableLimitStringMoreThousand*/

  return (
    <Stack sx={{ p: 3 }}>
      {iframeLoading && <LoadingSpinner showOnFullPage height={'100%'} width={'100%'} />}
      <Stack>
        <Typography sx={{ fontWeight: 700, fontSize: '18px', lineHeight: '28px' }}>
          {labelStrings.airTableCustomFields}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '22px',
            color: '#637381',
            whiteSpace: 'pre-line',
          }}
        >
          {`${labelStrings.airTableCustomFieldPageTitle} \n ${CONTENT_LIMIT_STRING} \n ${labelStrings.airTableUseAllFeatures} \n `}
          <a 
            href={`https://airtable.com/${baseId}`} 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ color: '#637381' }}
          >
            {labelStrings.airTableAccessLink}
          </a>
        </Typography>

      </Stack>
      <Box sx={{ width: '100%', height: '100%', overflow: 'hidden', py: 2 }}>
        {(!baseId || !shareId) && (
          <Alert sx={{ my: 1 }} severity="warning">
            {labelStrings.airTableIsNotCreated}
          </Alert>
        )}
        { airTableLink && (
          <iframe
            className="airtable-embed"
            src={airTableLink}
            frameBorder="0"
            // onmouseWheel=""
            onLoad={() => setIframeLoading(false)}
            width="100%"
            height="100%"
            style={{
              background: 'transparent',
              border: '1px solid #ccc',
              borderRadius: '8px',
              height: '75vh',
            }}
          />
        )}
      </Box>
    </Stack>
  )
}
