// @ts-nocheck
import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TopControls } from '../../sections/keywords-mentions-manager/TopControls'
import { MentionsKeywordsTable } from '../../sections/keywords-mentions-manager/MentionsKeywordsTable'
import { useDispatch, useSelector } from 'react-redux'
import { editTrackedMetrics, getTrackedMetrics } from '../../../redux/ducks/campaignsDucks'
import { currentOpenedCampaignSelector, trackedMetricsSelector } from '../../../redux/selectors'
import { EditItem } from '../../sections/keywords-mentions-manager/EditItem'

export const KeywordsMentionsManager = () => {
  const dispatch = useDispatch()

  const { campaignId } = useSelector(currentOpenedCampaignSelector)
  const { isLoading, data } = useSelector(trackedMetricsSelector)

  const [openEditModal, setOpenEditModal] = useState(false)
  const [editItem, setEditItem] = useState({})
  const [currPage, setCurrPage] = useState(0)


  useEffect(() => {
    if (campaignId && !data?.length) {
      dispatch(getTrackedMetrics({ campaignId }))
    }
  }, [campaignId, data?.length])

  const onCreate = ({ value, type }) => {
    const payload = {
      method: 'add',
      value,
      type,
      campaignId,
      startingDate: new Date(),
    }
    dispatch(editTrackedMetrics(payload))
  }

  const onRemove = ({ metricId }) => {
    const payload = {
      method: 'remove',
      campaignId,
      metricId,
    }
    dispatch(editTrackedMetrics(payload))
  }

  const onEdit = ({ value, type, metricId }) => {
    const payload = {
      method: 'change',
      isExact: editItem.isExact,
      startingDate: editItem.startingDate,
      value,
      type,
      metricId,
      campaignId,
    }
    dispatch(editTrackedMetrics(payload))
  }

  return (
    <>
      <Box  sx={{ p: 3 }}>
        <TopControls onCreate={setOpenEditModal} disabled={isLoading} />
        <MentionsKeywordsTable
          data={data}
          openModal={setOpenEditModal}
          setEditItem={setEditItem}
          isLoading={isLoading}
          onRemove={onRemove}
          onEdit={onEdit}
          setCurrPage={setCurrPage}
        />
      </Box>
      <EditItem
        openEditFieldModal={openEditModal}
        setOpenEditFieldModal={setOpenEditModal}
        editField={editItem}
        setEditField={setEditItem}
        onEdit={onEdit}
        onCreate={onCreate}
      />
    </>
  )
}
