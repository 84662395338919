import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../localization/useTranslation'
import CollectionOptionsContainer from '../collectionsOptions/CollectionOptionsContainer'
import LinkToFaqQuestion from '../faqs/LinkToFaqQuestion'
import { SOCIAL_PLATFORMS_NAMES } from '../../constants/appSettings'
import { FAV_COLLECTION_NAME, UNLOCKED_COLLECTION_NAME } from '../../constants/collections'
import CollectionUsersSortingTool from '../sortingTools/CollectionUsersSortingTool'
import CollectionAccessStatus from '../collectionsAccess/CollectionAccessStatus'
import CollectionAccessSwitcher from '../collectionsAccess/CollectionAccessSwitcher'
import { CollectionsCampaign } from '../collectionsCampaign/CollectionsCampaign'

const MainWrapper = styled.div`
  margin-top: 15px;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 15px;
`

const FlexRow = styled.div`
  ${props => props.theme.flex.centerAll};
`

const TitleWrapper = styled.div`
  ${props => props.theme.flex.centerAll};
  position: relative;
  width: fit-content;
`

const Title = styled.div`
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  color: ${props => props.theme.colors.brown};
`

const CollectionItemsCount = styled.div`
  font-size: 10px;
  line-height: 12px;
  padding: 3px 8px;
  background: ${props => props.theme.colors.mauve};
  color: ${props => props.theme.colors.white};
  border-radius: 9px;
  margin-left: 20px;
`

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
`

const DescWrapper = styled.div`
  width: 60%;
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.brown};
`

const DescTitle = styled.span`
  font-weight: bold;
`

const TipWrapper = styled.div`
  & * {
    font-weight: 400;
  }
`

const DescAndSortWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const SortToolWrapper = styled.div`
  display: flex;
  width: 40%;
  justify-content: flex-end;
`

const CollectionProfileListHeader = ({
  collection,
  openedCollection,
  enableExports,
  showCollectionAccess,
  showSortingTools,
  isOwnerCollection
}) => {
  const { labelStrings } = useTranslation()

  const collectionsExists = !!Object.keys(collection)?.length
  const isBlackListCollection = collectionsExists && collection?.collectionId === 'blacklist'
  const { name, count, description, collectionId, access } = useMemo(() => collection, [collection])

  const isFavCollection = collectionId?.startsWith(FAV_COLLECTION_NAME)
  const isUnlockedCollection = collectionId === UNLOCKED_COLLECTION_NAME

  const hasCollectionAccessSwitcher = !isUnlockedCollection && !isBlackListCollection

  let renderDescription = description
  let renderName = name

  if (isFavCollection) {
    renderDescription = labelStrings.favsDescription
  } else if (isUnlockedCollection) {
    renderDescription = labelStrings.unlockedDescription
    renderName = labelStrings.unlockedReports
  }

  return (
    collectionsExists && (
      <MainWrapper>
        <NavWrapper>
          <FlexRow>
            <TitleWrapper>
              <Title>{renderName}</Title>
              {!isUnlockedCollection && (
                <>
                  <CollectionItemsCount>{count}</CollectionItemsCount>
                  <TipWrapper>
                    <LinkToFaqQuestion
                      tipPosition="bottom center"
                      faqQuestionId="" //TODO: FAQLINK collections page header near bubble count
                      userindex={SOCIAL_PLATFORMS_NAMES.instagram.index}
                    />
                  </TipWrapper>
                </>
              )}
            </TitleWrapper>
            {!isUnlockedCollection && !isBlackListCollection && (
              <CollectionAccessStatus access={collection.access} showInCollection />
            )}
          </FlexRow>
          <FlexRow>
            {hasCollectionAccessSwitcher && (
              // @ts-ignore
              <CollectionAccessSwitcher
                access={access}
                collectionId={collectionId}
                collectionName={name}
                isOwnerCollection={isOwnerCollection}
              />
            )}
            {!isBlackListCollection && (
              // @ts-ignore
              <FlexRow>
                <CollectionsCampaign openedCollection={openedCollection} />
                <CollectionOptionsContainer collection={collection} enableExports={enableExports} />
              </FlexRow>
            )}
          </FlexRow>
        </NavWrapper>
        <DescAndSortWrapper>
          {renderDescription && (
            <DescWrapper>
              <DescTitle>{labelStrings.description}: </DescTitle>
              {renderDescription}
            </DescWrapper>
          )}
          <SortToolWrapper>{showSortingTools && <CollectionUsersSortingTool />}</SortToolWrapper>
        </DescAndSortWrapper>
      </MainWrapper>
    )
  )
}

CollectionProfileListHeader.propTypes = {
  collection: PropTypes.object.isRequired,
  openedCollection: PropTypes.object.isRequired,
  enableExports: PropTypes.bool,
  showCollectionAccess: PropTypes.bool,
  showSortingTools: PropTypes.bool,
  isOwnerCollection: PropTypes.bool
}

export default CollectionProfileListHeader
