import React from 'react'
import { TableRow, TableCell, Button } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'
import { fDate } from '../../../../new-ui/utils/formatTime'

export const OrganizationsTableRow = ({ row, onSwitchToOrganizations }) => {
  const { labelStrings } = useTranslation()

  const { orgName, created, membersCount, viewerCanSwitch } = row || {}

  return (
    <>
      <TableRow hover>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {orgName}
        </TableCell>

        <TableCell align="left">{membersCount}</TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {created ? fDate(created, 'yyyy-MM-dd') : '-'}
        </TableCell>

        <TableCell align="right">
          <Button
            disabled={!viewerCanSwitch}
            size="small"
            sx={{ FontFamily: 'Inter', color: '#FF5C00', height: '26px', lineHeight: 'initial' }}
            onClick={onSwitchToOrganizations}
            variant="outlined"
          >
            {labelStrings?.switchToOrganizations}
          </Button>
        </TableCell>
      </TableRow>
    </>
  )
}
