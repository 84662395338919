// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import queryString from 'query-string'
import { Box } from '@mui/material'
import { TopControls } from '../../sections/content-overview/TopControls'
import { CarouselContent } from '../../sections/content-overview/CarouselContent'
import {
  campaignDashboard,
  contentListSelector,
  currentOpenedCampaignSelector,
} from '../../../redux/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { EditContent } from '../../sections/content-overview/EditContent'
import {
  addContentToRecognizedContent,
  changeContentByBitlyLink,
  editCampaignContent,
  getCampaignContentList,
  getNonRecognizedContent,
  removeContentFromTracking,
} from '../../../redux/ducks/campaignsDucks'
import { pretifyBigNum } from '../../../utils'
import { isValidDate } from '../../utils/formatTime'
import { DownloadContentModal } from '../../sections/content-overview/DownloadContentModal'
import { ContentOverviewSkeletonLoader } from '../../sections/content-overview/SkeletonLoader'
import { BitlyLinkModal } from '../../sections/content-overview/BitlyLinkModal'

const FILTERED_BY_CONTENT_COUNT_OPTIONS = 6

export const ContentOverview = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { search: contentSearchQuery } = useLocation()
  const queryParams = queryString.parse(contentSearchQuery)

  const params = new URLSearchParams(contentSearchQuery)
  const selectedCreatorsFromQuery = params.get('selectedCreators')
  const startingDateFromQuery = params.get('startingDate')
  const endingDateFromQuery = params.get('endingDate')
  const openUnmatchedContent = params.get('openUnmatchedContent') === 'true' ? true : false
  const selectedFilteredByContentFromQuery = params.get('filterContentTypes')

  const { isRunningFetch: isRunningFetchDashboard } = useSelector(campaignDashboard)
  const currentCampaign = useSelector(currentOpenedCampaignSelector)
  const { campaignId, settings, creators } = currentCampaign
  const {
    data: { contentArray, totalContentPieces, hasMoreItems },
    isLoading,
    isShowMoreLoading,
    fromBitlyLink
  } = useSelector(contentListSelector) || {}
  const isBitlyLinkContent = fromBitlyLink && fromBitlyLink.link

  const [page, setPage] = useState(1)
  const [openEditContentModal, setOpenEditContentModal] = useState(false)
  const [editItem, setEditItem] = useState({})

  const [isOpenBitlyModal, setIsOpenBitlyModal] = useState(false)

  const [selectedCreators, setSelectedCreators] = useState([''])
  const [dateRange, setDateRange] = useState([null, null])
  const [selectedFilteredByContent, setSelectedFilteredByContent] = useState([''])

  const [currentCreatorsData, setCurrentCreatorsData] = useState({})

  const hasValidDateRange = isValidDate(dateRange[0]) || isValidDate(dateRange[1])
  const startingDate = isValidDate(dateRange[0]) ? String(format(dateRange[0], 'yyyy-MM-dd')) : null
  const endingDate = isValidDate(dateRange[1]) ? String(format(dateRange[1], 'yyyy-MM-dd')) : null

  const updateURLParams = newParams => {
    const mergedParams = {
      ...queryParams,
      ...newParams,
    }

    const newQueryString = queryString.stringify(mergedParams)
    history.push(`?${newQueryString}`)
  }

  const platforms = new Set(creators?.map(el => el.platform))

  const creatorsOptionsData = creators
    ?.filter(x => !x.isArchived && !x.isDeleted)
    .map(el => {
      let platformAbbr = ''
      if (platforms.size > 1) {
        platformAbbr = el.platform.includes('insta')
          ? ' (IG)'
          : el.platform.includes('tiktok')
          ? ' (TT)'
          : ' (YT)'
      }
      return {
        label: `${el.username}${platformAbbr}`,
        value: el.creatorId,
      }
    })

  useEffect(() => {
    const startingDate = new Date(startingDateFromQuery)
    const endingDate = new Date(endingDateFromQuery)
    const splittedSelectedCreators = selectedCreatorsFromQuery?.split(',')
    const splittedSelectedFilteredByContent = selectedFilteredByContentFromQuery?.split(',')

    if (selectedCreatorsFromQuery) {
      setSelectedCreators(splittedSelectedCreators)
    }

    if (selectedFilteredByContentFromQuery) {
      setSelectedFilteredByContent(splittedSelectedFilteredByContent)
    }

    setDateRange([
      startingDateFromQuery ? startingDate : null,
      endingDateFromQuery ? endingDate : null,
    ])
  }, [
    selectedCreatorsFromQuery?.length,
    startingDateFromQuery?.length,
    endingDateFromQuery,
    selectedFilteredByContentFromQuery?.length,
  ])
  
  useEffect(() => {
    if (!campaignId) {
      return
    }

    const contentListParams = {
      campaignId,
      page,
    }

    if (selectedCreatorsFromQuery && !startingDateFromQuery && !endingDateFromQuery) {
      contentListParams.selectedCreators =
        selectedCreators?.length === creatorsOptionsData?.length ? '' : selectedCreatorsFromQuery
      contentListParams.filterContentTypes =
        selectedFilteredByContent?.length === FILTERED_BY_CONTENT_COUNT_OPTIONS
          ? ''
          : selectedFilteredByContentFromQuery
    } else if (hasValidDateRange || (startingDateFromQuery && endingDateFromQuery)) {
      contentListParams.startingDate = startingDate
      contentListParams.endingDate = endingDate
      contentListParams.selectedCreators =
        selectedCreators?.length === creatorsOptionsData?.length ? '' : selectedCreatorsFromQuery
      contentListParams.filterContentTypes =
        selectedFilteredByContent?.length === FILTERED_BY_CONTENT_COUNT_OPTIONS
          ? ''
          : selectedFilteredByContentFromQuery
    }

    if (!isRunningFetchDashboard) {
      openUnmatchedContent
        ? dispatch(
            getNonRecognizedContent({
              campaignId,
              creatorId: selectedCreatorsFromQuery,
              page,
              filterContentTypes:
                selectedFilteredByContent?.length === FILTERED_BY_CONTENT_COUNT_OPTIONS
                  ? ''
                  : selectedFilteredByContentFromQuery,
            })
          )
        : dispatch(getCampaignContentList(contentListParams))
    }
  }, [
    campaignId,
    page,
    selectedCreatorsFromQuery,
    startingDateFromQuery,
    endingDateFromQuery,
    openUnmatchedContent,
    isRunningFetchDashboard,
    selectedFilteredByContentFromQuery,
  ])

  const contentData = fromBitlyLink.status === 'ok' ? fromBitlyLink?.contentArray : contentArray

  const mappedListData = contentData?.map(item => {
    return {
      id: item?.contentId ? item?.contentId : item?.contentIds[0], // React needs an id
      ids: item?.contentIds || null,
      name: item?.username,
      avatar: item?.profilePicUrl,
      dateTimeAt: item?.uploaded,
      likes: item?.likes,
      comments: item?.comments,
      commentSummary: item?.commentSummary,
      estReach: item?.reach,
      value: `${pretifyBigNum(item?.valueFrom)} - ${pretifyBigNum(
        item?.valueTo
      )} ${settings?.campaignCurrency || 'EUR'}`,
      reachedTargetGroup: '-',
      found: item?.found,
      contentImg: item?.imageUrl,
      platform: item?.platform,
      contentVideo: item?.videoUrl,
      contentVideoPreview: item?.imageUrl,
      contentType: item?.contentType,
      commentPositivityRate: item?.commentPositivityRate,
      controversialCommentRate: item?.controversialCommentRate,
      videoUrls: item?.videoUrls,
      imageUrls: item?.imageUrls,
      engagementRate: item?.engagementRate,
      platformLink: item?.platformLink,
      reach: item?.reach,
      shares: item?.shares,
      saves: item?.saves,
      customFields: item?.customFields,
      label: item?.label,
      ad_media: item?.ad_media,
      errorBubbles: item?.errorBubbles
    }
  })

  const onHandlePlay = () => {
    const queryParams = {}

    if (selectedCreators?.length) {
      queryParams.selectedCreators = selectedCreators?.join(',')
    }

    if (selectedFilteredByContent?.length) {
      queryParams.filterContentTypes = selectedFilteredByContent?.join(',')
    }

    if (startingDate || endingDate) {
      queryParams.startingDate = startingDate
      queryParams.endingDate = endingDate
    }

    setPage(1)
    updateURLParams(queryParams)
  }

  const onChangeDates = ({ startingDateFromParams, endingDateFromParams }) => {
    const queryParams = {}

    if (selectedCreators?.length) {
      queryParams.selectedCreators = selectedCreators?.join(',')
    }

    if (selectedFilteredByContent?.length) {
      queryParams.filterContentTypes = selectedFilteredByContent?.join(',')
    }

    queryParams.startingDate = startingDateFromParams === null ? '' : String(startingDateFromParams)
    queryParams.endingDate = endingDateFromParams === null ? '' : String(endingDateFromParams)

    setPage(1)
    updateURLParams(queryParams)
  }

  const onRemoveContentFromTracking = () => {
    dispatch(
      removeContentFromTracking({
        contentId: editItem.id,
        campaignId,
        page,
        startingDate,
        endingDate,
        selectedCreators,
      })
    )
    setOpenEditContentModal(false)
  }

  const onAddToCampaign = ({ contentIds }) => {
    dispatch(
      addContentToRecognizedContent({
        campaignId,
        contentIds,
      })
    )
  }

  const onEditContent = payload => {
    dispatch(
      editCampaignContent({
        ...payload,
        startingDate,
        endingDate,
      })
    )
  }

  const handleOpenBitlyModal = () => {
    setIsOpenBitlyModal(true)
  }

  const handleCloseBitlyModal = () => {
    setIsOpenBitlyModal(false)
  }

  const onShowAll = () => {
    dispatch([
      changeContentByBitlyLink({
        contentArray: [],
        link: "",
        status: "",
        totalContentPieces: 0
      }),
      getCampaignContentList({
        campaignId,
        page: 1
      })
    ])
  }  

  return (
    <Box>
      {isLoading && page === 1 ? (
        <ContentOverviewSkeletonLoader />
      ) : (
        <Box sx={{ p: 3 }}>
          <TopControls
            selectedCreators={selectedCreators}
            setSelectedCreators={setSelectedCreators}
            dateRange={dateRange}
            setDateRange={setDateRange}
            onHandlePlay={onHandlePlay}
            creatorsOptions={creatorsOptionsData}
            selectedCreatorsFromQuery={selectedCreatorsFromQuery}
            openUnmatchedContent={openUnmatchedContent}
            selectedFilteredByContent={selectedFilteredByContent}
            setSelectedFilteredByContent={setSelectedFilteredByContent}
            selectedFilteredByContentFromQuery={selectedFilteredByContentFromQuery}
            onChangeDates={onChangeDates}
            handleOpenBitlyModal={handleOpenBitlyModal}
            isBitlyLinkContent={isBitlyLinkContent}
          />
          <CarouselContent
            listData={mappedListData}
            openModal={setOpenEditContentModal}
            setEditItem={setEditItem}
            setPage={setPage}
            isShowMoreLoading={isShowMoreLoading}
            totalContentPieces={totalContentPieces}
            openUnmatchedContent={openUnmatchedContent}
            onAddToCampaign={onAddToCampaign}
            setCurrentCreatorsData={setCurrentCreatorsData}
            hasMoreItems={hasMoreItems}
            isBitlyLinkContent={isBitlyLinkContent}
            fromBitlyLinkData={fromBitlyLink}
            onShowAll={onShowAll}
          />
        </Box>
      )}
      <EditContent
        openEditContentModal={openEditContentModal}
        setOpenEditContentModal={setOpenEditContentModal}
        editItem={editItem}
        setEditItem={setEditItem}
        onRemoveContentFromTracking={onRemoveContentFromTracking}
        onEditContent={onEditContent}
      />
      <DownloadContentModal
        isOpened={!!currentCreatorsData?.id}
        setOpenedDownloadModal={setCurrentCreatorsData}
        currentCreatorsData={currentCreatorsData}
      />
      <BitlyLinkModal 
        isOpened={isOpenBitlyModal}
        handleClose={handleCloseBitlyModal}
        campaignId={campaignId}
      />
    </Box>
  )
}
