import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { userGrantSelector } from '../../redux/selectors'
import { GradientTransparentLoaderButton } from '../common/buttons/GradientTransparentLoaderButton'
import {
  COMPARED_PROFILES_LIMIT,
  NAME_ROUTE_COMPARE_PROFILES,
} from '../../constants/comparedProfiles'
import { AddAllCollectionsToComparison } from '../../redux/ducks/compareProfilesDucks'
import useTranslation from '../../localization/useTranslation'
import WithTip from '../common/popups/WithTip'

import { StyledButtonContainer, TipContainer, UpgradeLinkWrapper } from './styles.js'

export const CollectionsCampaign = props => {
  const { openedCollection } = props
  const {
    users: { userdata },
  } = openedCollection

  const dispatch = useDispatch()
  const history = useHistory()
  const { labelStrings } = useTranslation()

  const { package_free: isFreeUser } = useSelector(userGrantSelector)

  const [isLoading, setIsLoading] = useState(false)

  const comparedProfilesPlatform = userdata[0]?._index

  const isOnePlatformCollections = useMemo(
    () => userdata?.every(item => item?._index === comparedProfilesPlatform),
    [userdata]
  )

  const isValidCollectionLength =
    userdata?.length <= COMPARED_PROFILES_LIMIT && userdata?.length >= 2

  const isDisabled = !isValidCollectionLength || !isOnePlatformCollections || isFreeUser

  const toComparePage = () => history.push(NAME_ROUTE_COMPARE_PROFILES)

  const onAddToComparison = () => {
    const collectionArr = userdata?.reduce((acc, item) => {
      acc[item._id] = item
      return acc
    }, {})

    dispatch(
      AddAllCollectionsToComparison({
        collectionArr,
        comparedProfilesPlatform,
        toComparePage,
        setIsLoading,
      })
    )
  }

  const TipContent = (
    <TipContainer>
      {isFreeUser ? (
        <>
          <UpgradeLinkWrapper to="/settings">{labelStrings.contactSales}</UpgradeLinkWrapper>{' '}
          {labelStrings.to} {labelStrings.useComapreProfiles}
        </>
      ) : !isOnePlatformCollections ? (
        labelStrings.cantAddManyPlatformsToCompare
      ) : !isValidCollectionLength ? (
        labelStrings.youCanOnlyAppUp10Profiles
      ) : (
        labelStrings.comparedProfilesLimitReach
      )}
    </TipContainer>
  )

  return (
    <StyledButtonContainer>
      {isDisabled ? (
        <WithTip tipText={TipContent} position="bottom center">
          <div>
            <GradientTransparentLoaderButton isDisabled={isDisabled} onClick={onAddToComparison}>
              {labelStrings.addAllToComparison}
            </GradientTransparentLoaderButton>
          </div>
        </WithTip>
      ) : (
        <GradientTransparentLoaderButton onClick={onAddToComparison} isLoading={isLoading}>
          {labelStrings.addAllToComparison}
        </GradientTransparentLoaderButton>
      )}
    </StyledButtonContainer>
  )
}
