import React from 'react'
import useTranslation from '../../../../localization/useTranslation'
import { ModalComponent } from '../../../../new-ui/components/modal/ModalComponent'
import { Box, Button, Stack, Typography, Chip } from '@mui/material'
import { useSelector } from 'react-redux'
import { canAddToCollectionDynamicSelector, noRerenderEqFn } from '../../../../redux/selectors'
import { UNLOCKED_COLLECTION_NAME } from '../../../../constants/collections'
import WithUpgradeTip from '../../popups/WithUpgradeTip'
import Scrollbar from '../../../../new-ui/components/scrollbar/Scrollbar'

const ManageCollectionsItemsDialog = ({ type, close, collectionsList, manageHandler, isOpen }) => {
  const { labelStrings } = useTranslation()

  const canManageItems = useSelector(canAddToCollectionDynamicSelector, noRerenderEqFn)

  const TITLES_MAP = {
    move: labelStrings.moveToCollection,
    copy: labelStrings.copyToCollection,
  }

  const canMoveItemsToCollection = collectionId => canManageItems({ collectionId })
  const itemClickHandler = collectionId => async () => manageHandler(type, collectionId)
  const closeHandler = () => close()

  return (
    <ModalComponent
      isOpenModal={isOpen}
      closeModal={closeHandler}
      title={TITLES_MAP[type]}
      contentWidth={'400px'}
      contentHeight="auto"
      isCenterTitle
      withoutScrollWrapper
      isFilledTitle
      isGradientBg
      smallPadding
      smallPaddingContent
    >
      <Box sx={{ py: 3 }}>
        <Scrollbar sx={{ maxHeight: '400px', px: 1.5 }}>
          <Stack spacing={1}>
            {collectionsList?.map(col => {
              if (col.collectionId === UNLOCKED_COLLECTION_NAME) return null
              const canManage = canMoveItemsToCollection(col.collectionId)
              return canManage ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  key={col.collectionId}
                  sx={{
                    borderRadius: '9px',
                    p: '10px 15px',
                    w: '100%',
                    cursor: 'pointer',
                    background: '#EED9D9',
                    '&:hover': {
                      filter: `brightness(90%)`,
                    },
                  }}
                  onClick={itemClickHandler(col.collectionId)}
                >
                  <Typography sx={{ fontFamily: 'Inter', color: '#442424', fontSize: '15px' }}>
                    {col.name}
                  </Typography>
                  <Chip
                    size="small"
                    sx={{
                      fontFamily: 'Inter',
                      color: '#fff',
                      backgroundColor: '#784E4E',
                      fontSize: '10px',
                    }}
                    label={col.count}
                  />
                </Stack>
              ) : (
                <WithUpgradeTip key={col.collectionId} tipText="add more users to collection">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      borderRadius: '9px',
                      p: '10px 15px',
                      w: '100%',
                      cursor: 'not-allowed',
                      background: 'rgba(0, 0, 0, 0.38)',
                      '&:hover': {
                        filter: `brightness(90%)`,
                      },
                    }}
                  >
                    <Typography sx={{ fontFamily: 'Inter', color: '#fff', fontSize: '15px' }}>
                      {col.name}
                    </Typography>
                    <Chip
                      size="small"
                      sx={{
                        fontFamily: 'Inter',
                        color: '#fff',
                        backgroundColor: '#784E4E',
                        fontSize: '10px',
                      }}
                      label={col.count}
                    />
                  </Stack>
                </WithUpgradeTip>
              )
            })}
          </Stack>
        </Scrollbar>

        <Stack
          sx={{ width: '100%', mt: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            onClick={closeHandler}
            variant="outlined"
            color="primary"
            sx={{ borderColor: '#FF5C00', color: '#FF5C00', textTransform: 'uppercase' }}
          >
            {labelStrings.confirm}
          </Button>
        </Stack>
      </Box>
    </ModalComponent>
  )
}

export default ManageCollectionsItemsDialog
