import React, { useState } from "react"
import { Button } from "@mui/material"
import { NavbarFAQIcon } from "./navbarIcons"
import useTranslation from "../../../localization/useTranslation"
import {
  LoginContainerWrapper,
  LoginItemsDivider,
} from "../../../components/common/styledWrappers/navbarStyles"
import { INFLUDATA_HOSTS } from "../../../constants/appSettings"
import ProfileNavContainer from "./profile/ProfileNavContainer"

const NavBarUserRelated = ({ onLoginClick, isUserAuthorized }) => {
  const { labelStrings } = useTranslation()

  const [isHovered, setIsHovered] = useState(false)

  return (
    <LoginContainerWrapper>
      <Button
        href={INFLUDATA_HOSTS.faq}
        target='__blank'
        sx={{ minWidth: "40px", '&:hover': { backgroundColor: 'transparent' } }}
        disableRipple
        onMouseEnter={() => setIsHovered(true)} 
        onMouseLeave={() => setIsHovered(false)}
      >
        <NavbarFAQIcon is_active={`${isHovered}`} />
      </Button>
      {!isUserAuthorized && (
        <>
          <Button
            variant='text'
            sx={{ color: "#6B4545" }}
            onClick={onLoginClick}
          >
            {labelStrings.login}
          </Button>
          <LoginItemsDivider />
        </>
      )}
      <ProfileNavContainer isUserAuthorized={isUserAuthorized} />
    </LoginContainerWrapper>
  )
}

export default NavBarUserRelated
