import React, { useState } from 'react'
import { ModalComponent } from '../../../../new-ui/components/modal/ModalComponent'
import useTranslation from '../../../../localization/useTranslation'
import { Stack, Typography, Box, Button, TextField } from '@mui/material'

export const CreateNewOrganizationsAccountModal = props => {
  const { isOpenModal, closeModal, onSubmit } = props || {}

  const { labelStrings } = useTranslation()

  const [organizationsName, setOrganizationsName] = useState('')

  const onChange = ({ target }) => {
    setOrganizationsName(target.value)
  }

  const onCloseModal = () => {
    closeModal()
    setOrganizationsName('')
  }

  const onConfirm = e => {
    e.preventDefault()
    if (!!organizationsName?.trim()?.length) {
      const payload = {
        companyName: organizationsName?.trim(),
      }
      onSubmit(payload)
    }
    onCloseModal()
  }

  return (
    <ModalComponent
      isOpenModal={isOpenModal}
      closeModal={onCloseModal}
      title={labelStrings.createNewOrganizationsAccount}
      contentWidth={'520px'}
      contentHeight="auto"
      isCenterTitle
      withoutScrollWrapper
      isGradientTitle
      isGradientBg
    >
      <Box>
        <Stack spacing={2}>
          <Stack spacing={0.5} alignItems="center">
            <Typography sx={{ fontFamily: 'Inter' }}>
              {labelStrings.typeNameOrganizationalAccount}:
            </Typography>
            <TextField
              sx={{ width: '80%' }}
              size="small"
              autoComplete="off"
              value={organizationsName}
              onChange={onChange}
              focused
              autoFocus
            />
          </Stack>

          <Stack alignItems="center">
            <Typography textAlign="center" sx={{ fontFamily: 'Inter', fontWeight: 500, mb: 0.5 }}>
              {labelStrings.keepInMind}
            </Typography>
            <Stack>
              <Typography sx={{ fontFamily: 'Inter', fontSize: '14px' }}>
                1. {labelStrings.yourTeamMemberSeats}
              </Typography>

              <Typography sx={{ fontFamily: 'Inter', fontSize: '14px' }}>
                2. {labelStrings.youWillBeOnlyOwner}
              </Typography>
              <Typography sx={{ fontFamily: 'Inter', fontSize: '14px' }}>
                3. {labelStrings.onlyOtherOwners}
              </Typography>
              <Typography sx={{ fontFamily: 'Inter', fontSize: '14px' }}>
                4. {labelStrings.youNeedToReach}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ pb: 3, pt: 2 }}
          >
            <Button
              onClick={closeModal}
              variant="outlined"
              color="primary"
              sx={{ borderColor: '#FF5C00', color: '#FF5C00' }}
            >
              {labelStrings.cancel}
            </Button>
            <Button
              disabled={!organizationsName?.trim()?.length}
              onClick={onConfirm}
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: '#FF5C00',
              }}
            >
              {labelStrings.confirm}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </ModalComponent>
  )
}
