import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import useTranslation from '../../../localization/useTranslation'
import { useSelector } from 'react-redux'
import { userGrantSelector, areAvailableAudienceTokensSelector } from '../../../redux/selectors'

import { withReduxActions } from '../../../hocs/withRedux'
import { unlockComparedProfileAudienceToken } from '../../../redux/ducks/audienceTokensActions'
import { limitTrailAudienceTokensAccess } from '../../../redux/ducks/appSettingsDucks'

import { AUDIENCE_AVAILABLE_STATUS } from '../../../constants/audienceAnalysis'
import { UnlockedIcon } from '../../common/icons'
import {
  OuterAudienceLockedWrapper,
  AudienceLockedWrapper,
  IconWrapper,
  LabelWrapper,
  AvailableReport,
} from '../../common/styledWrappers/comparedProfilesStyles'
// import { Upgrade } from '../../common/styledWrappers'
import { USER_INDEXES } from '../../../constants/appSettings'

const ComparedAudienceLocked = ({ profile, unlockComparedProfileAudienceToken }) => {
  const { labelStrings } = useTranslation()
  const history = useHistory()
  const areAvailableTokens = useSelector(areAvailableAudienceTokensSelector)
  const { in_trial: isTrial, audience_tokens_monthly  } = useSelector(userGrantSelector)
  const { audienceAvailable } = profile || {}

  const lockTipsMap = {
    [AUDIENCE_AVAILABLE_STATUS.lockedDelayed]: labelStrings.available15m,
    [AUDIENCE_AVAILABLE_STATUS.lockedInstant]: labelStrings.avaliableInstantly,
    [AUDIENCE_AVAILABLE_STATUS.unlockedRequested]: labelStrings.avaliableWithin15m,
  }

  const onUnlockClick = () => {
    const platform = profile._index

    if (platform === USER_INDEXES.tiktok || areAvailableTokens) {
      unlockComparedProfileAudienceToken({ profileId: profile._id, platform})
    }
  }

  const onUpgradeTokens = () => {
    if (isTrial) {
      return limitTrailAudienceTokensAccess()
    }
    history.push('/settings')
  }

  const isUnlockedRequested = audienceAvailable === AUDIENCE_AVAILABLE_STATUS.unlockedRequested
  return (
    <OuterAudienceLockedWrapper>
      {!isUnlockedRequested && areAvailableTokens && (
        <AudienceLockedWrapper
          index={profile._index}
          {...(!isUnlockedRequested && {
            onClick: onUnlockClick,
          })}
        >
          <IconWrapper index={profile._index}>
            <UnlockedIcon />
          </IconWrapper>

          <LabelWrapper index={profile._index}>{labelStrings.unlockAnalysis}</LabelWrapper>
        </AudienceLockedWrapper>
      )}
      <AvailableReport>
        {areAvailableTokens || audience_tokens_monthly === -1 ? (
          lockTipsMap[audienceAvailable]
        ) : (
          <>
            {isTrial
              ? labelStrings.audienceTokensTrialLimit
              : `${labelStrings.noAudienceTokensLeft}.`}
            <br />
            {/*{labelStrings.please}{' '}
            <Upgrade onClick={onUpgradeTokens} userindex={profile._index} noLowerCase>
              {isTrial ? labelStrings.clickHere : labelStrings.changeAudienceTokens}
            </Upgrade>
            {isTrial && <>&nbsp;{labelStrings.toActivateTrialForAudienceTokens}</>}*/}
            {labelStrings.pleaseContactSupport}
          </>
        )}
      </AvailableReport>
    </OuterAudienceLockedWrapper>
  )
}

ComparedAudienceLocked.propTypes = {
  profile: PropTypes.object,
  unlockComparedProfileAudienceToken: PropTypes.func,
}

export default withReduxActions({ unlockComparedProfileAudienceToken })(ComparedAudienceLocked)
