import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import useTranslation from '../../../localization/useTranslation'

import CommonSettingSection from '../CommonSettingSection'
import { DownloadIcon } from '../../common/icons'

import { formatDateToLocale, convertInvoiceAmountFromCents } from '../../../utils'
import {
  Wrapper,
  InvoiceLink,
  InvoicesSection,
  NextBillingSection,
  Title,
  SubTitle,
  SubTitlesRow,
  WidthWrap,
  TextWrapper,
  DownloadIconWrapper,
  ShowMoreBtn,
  InvoicesDynamicHeightSection,
} from './paymentsStyles'
import { Button } from '@mui/material'

import { userGrantSelector } from '../../../redux/selectors'

const MORE_INVOICES_COUNT = 4

const Payments = ({
  isUserDetailsPresent = false,
  invoices = [],
  changePaymentMethod,
  profileData = {},
  isUserDetailsError = false,
}) => {
  const { labelStrings, currentLang } = useTranslation()
  const { no_payment_method } = useSelector(userGrantSelector)

  const [hasInvoicesPagination, setHasInvoicesPagination] = useState(false)
  const [isAllInvoicesDisplayed, setIsAllInvoicesDisplayed] = useState(false)
  const [invoicesToRender, setInvoicesToRender] = useState([])

  const hasInvoices = !!invoicesToRender.length

  useEffect(() => {
    const hasPagination = invoices.length > MORE_INVOICES_COUNT
    setHasInvoicesPagination(hasPagination)
    setIsAllInvoicesDisplayed(!hasPagination)
    setInvoicesToRender(hasPagination ? invoices.slice(0, MORE_INVOICES_COUNT) : invoices)
  }, [invoices])

  const ButtonForRender = (
    <Button
      // size='small'
      variant='outlined'
      sx={{
        fontFamily: "Inter",
        fontSize: "13px",
        borderRadius: "10px",
        textTransform: "uppercase",
        width: "fit-content",
        color: "#FF5C00",
        borderColor: "#FF5C00",
        backgroundColor: "#fff",
        "&:hover": {
          color: "white",
          backgroundColor: "#FF5500",
        },
        maxWidth: '200px',
        minHeight: '50px'
      }}
      onClick={changePaymentMethod}
      disabled={!profileData.hostedPage}
    >
      {labelStrings.editPaymentMethod}
    </Button>
  )

  const nextBillingDate =
    profileData.next_billing_at && new Date(profileData.next_billing_at * 1000).toString() // from unix to ms

  const getInvoiceDate = unixDate =>
    new Date(unixDate * 1000).toLocaleDateString(currentLang, {
      year: 'numeric',
      month: 'long',
    })

  const onPaginateInvoices = () => {
    if (isAllInvoicesDisplayed) {
      setInvoicesToRender(invoices.slice(0, MORE_INVOICES_COUNT))
      setIsAllInvoicesDisplayed(false)
    } else {
      setInvoicesToRender(invoices)
      setIsAllInvoicesDisplayed(true)
    }
  }

  const Content = (
    <Wrapper>
      {hasInvoices && (
        <InvoicesSection>
          <Title>{labelStrings.invoices}</Title>
          <SubTitlesRow>
            <WidthWrap width={'320px'}>
              <SubTitle>{labelStrings.name}</SubTitle>
            </WidthWrap>
            <WidthWrap width={'150px'}>
              <SubTitle>{labelStrings.date}</SubTitle>
            </WidthWrap>

            <WidthWrap width={'100px'}>
              <SubTitle>{labelStrings.amount}</SubTitle>
            </WidthWrap>

            <WidthWrap width={'100px'}>
              <SubTitle>{labelStrings.download}</SubTitle>
            </WidthWrap>
          </SubTitlesRow>
          <InvoicesDynamicHeightSection>
            {invoicesToRender.map(item => (
              <SubTitlesRow key={item.id}>
                <WidthWrap width={'320px'}>
                  <InvoiceLink href={item.link} target="_blank">
                    {labelStrings.invoice}&nbsp;
                    {item.id}
                  </InvoiceLink>
                </WidthWrap>
                <WidthWrap width={'150px'}>
                  <TextWrapper>{getInvoiceDate(item.date)}</TextWrapper>
                </WidthWrap>
                <WidthWrap width={'100px'}>
                  <TextWrapper>{convertInvoiceAmountFromCents(item.amount)}&euro;</TextWrapper>
                </WidthWrap>
                <WidthWrap width={currentLang === 'de' ? '110px' : '90px'} center>
                  <DownloadIconWrapper href={item.link} download>
                    <DownloadIcon />
                  </DownloadIconWrapper>
                </WidthWrap>
              </SubTitlesRow>
            ))}
          </InvoicesDynamicHeightSection>
          {hasInvoicesPagination && (
            <ShowMoreBtn onClick={onPaginateInvoices}>
              {isAllInvoicesDisplayed ? labelStrings.showLess : labelStrings.showMore}
            </ShowMoreBtn>
          )}
        </InvoicesSection>
      )}
      {nextBillingDate && (
        <NextBillingSection>
          <Title>{labelStrings.nextBillingDate}</Title>
          <TextWrapper>
            {no_payment_method ? '-' : formatDateToLocale({ dateStr: nextBillingDate, currentLang })}
          </TextWrapper>
        </NextBillingSection>
      )}
    </Wrapper>
  )

  return (
    <CommonSettingSection
      title={labelStrings.payments}
      withLoading
      isLoading={!isUserDetailsPresent}
      Content={Content}
      Button={ButtonForRender}
      isError={isUserDetailsError}
      errorLabel={labelStrings.notAvailablePayments}
    />
  )
}

Payments.propTypes = {
  isUserDetailsPresent: PropTypes.bool,
  profileData: PropTypes.object,
  invoices: PropTypes.arrayOf(PropTypes.object),
  changePaymentMethod: PropTypes.func,
  isUserDetailsError: PropTypes.bool,
}

export default Payments
