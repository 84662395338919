import React, { useEffect, useLayoutEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { changeDashboardId, getAllNewCampaigns } from '../../redux/ducks/campaignsDucks'
import DashboardLayout from '../layouts/DashboardLayout'
import {
  campaignDashboard,
  isSignUpOpenedSelector,
  isUserAuthenticatedSelector,
  userCredsSelector,
  userGrantSelector,
} from '../../redux/selectors'
import { toggleSignUp } from '../../redux/ducks/appSettingsDucks'
import { Box } from '@mui/material'
import { NAVBAR_FOOTER_SUM_HEIGHT } from '../../constants/appSettings'
import { PlanInactiveModal } from '../components/no-plan-modal/PlanInactiveModal'
import { NoPlanModal } from '../components/no-plan-modal/NoPlanModal'

const CampaignsPage = () => {
  const { campaignId } = useParams()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const isSignupOpened = useSelector(isSignUpOpenedSelector)
  const { currentDashboardId: dashboardIdFromState } = useSelector(campaignDashboard)
  const { no_plan, is_self_service } = useSelector(userGrantSelector, shallowEqual)
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const { showNoPlanModal } = useSelector(userCredsSelector) || {}

  const params = new URLSearchParams(search)
  const dashboardIdFromQueryURL = params.get('dashboardId')
  const campaignIdFromQueryURL = params.get('campaignId')

  useEffect(() => {
    dispatch(changeDashboardId(dashboardIdFromQueryURL))
  }, [])

  useEffect(() => {
    if (dashboardIdFromState === dashboardIdFromQueryURL || !dashboardIdFromState) {
      dispatch(changeDashboardId(dashboardIdFromQueryURL))
    }

    dispatch(
      // @ts-ignore
      getAllNewCampaigns({
        campaignId: campaignIdFromQueryURL || campaignId,
      })
    )
  }, [campaignId, dashboardIdFromQueryURL, campaignIdFromQueryURL])

  useLayoutEffect(() => {
    if (isSignupOpened) {
      dispatch(toggleSignUp())
    }
  }, [isSignupOpened])

  if (no_plan && isUserAuthenticated) {
    return showNoPlanModal ? (
      <Box sx={{ height: `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)` }} />
    ) : (
      <Box sx={{ height: `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)` }}>
        {is_self_service ? <NoPlanModal /> : <PlanInactiveModal />}
      </Box>
    )
  }

  return <DashboardLayout />
}

export default CampaignsPage
