import React from 'react'
import PropTypes from 'prop-types'
import RemoveItemsFromCollectionDialog from '../../common/dialogs/newDesign/RemoveItemFromCollections'
import ManageCollectionsItemsDialog from '../../common/dialogs/newDesign/ManageCollectionsItemsDialog'

const CollectionBulkModalsMediator = ({
  openDialogType = '',
  modalsTypes = {},
  closeModalHandler,
  removeItemsHandler,
  manageColelctionsItemsList = [],
  manageCommonHandler,
  removeItemsCount = 0,
}) => {
  const showRemoveDialog = openDialogType === modalsTypes.remove
  const showManageDialog =
    openDialogType === modalsTypes.copy || openDialogType === modalsTypes.move

  return (
    <>
      {showRemoveDialog && (
        <RemoveItemsFromCollectionDialog
          itemsCount={removeItemsCount}
          close={closeModalHandler}
          isOpen={showRemoveDialog}
          removeItemsHandler={removeItemsHandler}
        />
      )}
      {showManageDialog && (
        <ManageCollectionsItemsDialog
          type={openDialogType}
          close={closeModalHandler}
          isOpen={showManageDialog}
          collectionsList={manageColelctionsItemsList}
          manageHandler={manageCommonHandler}
        />
      )}
    </>
  )
}

CollectionBulkModalsMediator.propTypes = {
  openDialogType: PropTypes.string,
  modalsTypes: PropTypes.object,
  closeModalHandler: PropTypes.func,
  removeItemsHandler: PropTypes.func,
  manageColelctionsItemsList: PropTypes.array,
  manageCommonHandler: PropTypes.func,
  removeItemsCount: PropTypes.number,
}

export default CollectionBulkModalsMediator
