import React, {  useState } from "react";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Stack, Box, IconButton, Popover, Badge } from "@mui/material";
import useTranslation from "../../../localization/useTranslation";
import { userCredsSelector, userGrantSelector } from "../../../redux/selectors";
import WithTip from "../../../components/common/popups/WithTip";
import AnalyzeInlinedNavbarContainer from "../../../components/analyzeBar/AnalyzeInlinedNavbarContainer";
import { NavbarLinkItem } from "./NavbarLinkItem";
import {
  NavbarCampaignsIcon,
  NavbarCollectionsIcon,
  NavbarInfludataIcon,
  NavbarMessageIcon,
  NavbarSearchIcon,
} from "./navbarIcons";
import NavBarUserRelated from "./NavbarUserRelated";
import ReactivationPlanContainer from "../../../components/appUserSettings/pricingPlans/ReactivationPlanContainer";
import CompareProfilesDynamicNavItem from "./CompareProfilesDynamicNavItem";
import { markNotificationsAsSeen } from "../../../redux/ducks/startupActions";
import { Notifications } from "./Notifications";


const notificationsLabel = (count) => {
  if (count === 0) {
    return "no notifications";
  }
  if (count > 99) {
    return "more than 99 notifications";
  }
  return `${count} notifications`;
};

export const NavbarLinks = ({
  isUserAuthorized,
  allowCollections,
  allowTracking,
  onLoginClick,
  currentPagePath,
}) => {
  const { labelStrings } = useTranslation();
  const dispatch = useDispatch();

  const {
    enable_collections,
    enable_ig_search,
    enable_tt_search,
    enable_yt_search,
    build_lookalikes,
    no_plan,
    // build_lookalikes,
    ...otherGrants
  } = useSelector(userGrantSelector, shallowEqual);
  const { notifications } = useSelector(userCredsSelector);
  const { notificationArray } = notifications || {};
  const BADGE_VALUE = notificationArray?.length ? (notificationArray.filter(x => !x.seenInApp)).length : 0

  const disabledSearch =
    !enable_ig_search && !enable_tt_search && !enable_yt_search;
  const disabledCollection = !enable_collections;

  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const onOpenNotifications = (event) => {
    dispatch(markNotificationsAsSeen({ location: 'notifications_tab' }))
    setNotificationsAnchorEl(event.currentTarget);
  };

  const onCloseNotifications = () => {
    setNotificationsAnchorEl(null);
  };



  return (
    <Box id='navbar' sx={{ width: "100%" }}>
      <ReactivationPlanContainer />
      <Stack direction='row' alignItems='center' justifyContent='center'>
        <Box sx={{ width: "100%", ml: "58px" }}>
          <Link to='/'>
            <NavbarInfludataIcon />
          </Link>
        </Box>

        <Stack
          direction='row'
          alignItems='center'
          spacing={3}
          sx={{
            pointerEvents: !isUserAuthorized || no_plan ? "none" : "auto",
            opacity: !isUserAuthorized || no_plan ? 0.4 : 1,
          }}
        >
          <NavbarLinkItem
            linkPath={"search"}
            Icon={NavbarSearchIcon}
            is_active={currentPagePath?.startsWith("/search")}
            is_disabled={disabledSearch || no_plan}
            hoverText={"Search"}
            disableRipple
          />

          {!enable_collections && isUserAuthorized ? (
            <WithTip
              tipText={labelStrings.collectionDisabledTip}
              position='bottom center'
            >
              <div>
                <NavbarLinkItem
                  Icon={NavbarCollectionsIcon}
                  linkPath={"collections/"}
                  withSignup={!allowCollections && !isUserAuthorized}
                  signupTipText={labelStrings.useCollections}
                  is_active={currentPagePath?.startsWith("/collections")}
                  is_disabled={
                    !isUserAuthorized || !enable_collections || no_plan
                  }
                  hoverText={"Collections"}
                  disableRipple
                />
              </div>
            </WithTip>
          ) : (
            <NavbarLinkItem
              Icon={NavbarCollectionsIcon}
              linkPath={"collections/"}
              withSignup={!allowCollections && !isUserAuthorized}
              signupTipText={labelStrings.useCollections}
              is_active={currentPagePath?.startsWith("/collections")}
              is_disabled={!isUserAuthorized || disabledCollection || no_plan}
              hoverText={"Collections"}
              disableRipple
            />
          )}

          <NavbarLinkItem
            Icon={NavbarCampaignsIcon}
            linkPath={"campaigns"}
            withSignup={!allowTracking && !isUserAuthorized}
            signupTipText={labelStrings.useTracking}
            is_active={currentPagePath?.startsWith("/campaigns")}
            is_disabled={!isUserAuthorized || no_plan}
            hoverText={"Campaigns"}
            disableRipple
          />
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          sx={{ width: "100%" }}
          justifyContent='end'
        >
          {isUserAuthorized && (
            <Box sx={{ mr: 2 }}>
              <IconButton
                size='small'
                disableRipple
                aria-label={notificationsLabel(notificationArray?.length)}
                onClick={onOpenNotifications}
                sx={{
                  color: "#A06E6E",
                  "&:hover": {
                    color: "#FF5500",
                  },
                  "& .MuiBadge-badge": {
                    backgroundColor: "#ff2a00",
                    color: "#fff",
                    fontSize: "14px",
                    height: "16px",
                    minWidth: "16px",
                    paddingTop: "1px !important",
                    padding: "0 6px",
                    transform: "translate(3px, -3px)", // Moves left by 4px and down by 4px
                  },
                }}
              >
                {BADGE_VALUE > 0 ? ( // Conditionally render the Badge only if BADGE_VALUE > 0
                  <Badge badgeContent={BADGE_VALUE}>
                    <NavbarMessageIcon />
                  </Badge>
                ) : (
                  <NavbarMessageIcon /> // Render only the icon if no badge is needed
                )}
              </IconButton>
              <Popover
                open={!!notificationsAnchorEl}
                anchorEl={notificationsAnchorEl}
                onClose={onCloseNotifications}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  ml: "36px",
                }}
              >
                <Notifications onCloseNotifications={onCloseNotifications} />
              </Popover>
            </Box>
          )}
          <Box
            sx={{
              mr: 1,
              pointerEvents: !isUserAuthorized || no_plan ? "none" : "auto",
              opacity: !isUserAuthorized || no_plan ? 0.4 : 1,
            }}
          >
            <AnalyzeInlinedNavbarContainer />
          </Box>

          {/* <ToolsForBrandsWrapper>
                      <NavbarLinkItem
                        Icon={BuildIcon}
                        linkPath={'tools-for-brands'}
                        withSignup={!isUserAuthorized}
                        signupTipText={labelStrings.useToolsForBrands}
                        isActive={currentPagePath.includes('tools-for-brands')}
                      />
                    </ToolsForBrandsWrapper> */}

          <NavBarUserRelated
            onLoginClick={onLoginClick}
            isUserAuthorized={isUserAuthorized}
          />

          <CompareProfilesDynamicNavItem
            isUserAuthorized={isUserAuthorized}
            currentPagePath={currentPagePath}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
