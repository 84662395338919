import React from 'react'
import { ModalComponent } from '../modal/ModalComponent'
import { Box, Stack, Typography, Button } from '@mui/material'
import useTranslation from '../../../localization/useTranslation'
import { useSelector } from 'react-redux'
import httpService from '../../../services/httpService'
import { API_STATUS_CODES } from '../../../constants/appSettings'
import { toast } from 'react-toastify'
import { refreshGrantSelector } from '../../../redux/selectors'

export const ModalScheduledCancellation = props => {
  const { isOpenModal, onCloseModal } = props || {}
  const { labelStrings } = useTranslation()
  const refreshGrant = useSelector(refreshGrantSelector)

  const onContinue = async () => {
    onCloseModal()
    try {
      const { status } = (await httpService.removeScheduledCancellation()) || {}

      if (status === API_STATUS_CODES.created) {
        await refreshGrant()
        toast.success(labelStrings.removeCancellationSuccessful, { theme: 'colored' })
      }
    } catch (err) {
      const errorText = err.response.data

      if (err.status === API_STATUS_CODES.badRequest) {
        toast.error(labelStrings.removeCancellationError, { theme: 'colored' })
      }
    }
  }
  const onClose = async () => {
    onCloseModal()
  }

  return (
    <ModalComponent
      isOpenModal={isOpenModal}
      closeModal={onCloseModal}
      title={labelStrings.attention}
      contentWidth={'530px'}
      contentHeight="auto"
      isCenterTitle
      withoutScrollWrapper
      isGradientTitle
      isGradientBg
    >
      <Box sx={{ pb: 3 }}>
        <Stack spacing={2} alignItems="center">
          <Typography textAlign="center" sx={{ fontFamily: 'Inter', fontWeight: 600 }}>
            {labelStrings.removeCancellationScheduled}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: '100%', px: 5 }}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              color="primary"
              sx={{
                color: '#FF5C00',
                width: 'fit-content',
                minWidth: '100px',
              }}
            >
              {labelStrings.cancel}
            </Button>
            <Button
              onClick={onContinue}
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: '#FF5C00',
                width: 'fit-content',
                minWidth: '100px',
              }}
            >
              {labelStrings.confirm}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </ModalComponent>
  )
}
