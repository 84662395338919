import React from 'react'
import { Box, Card, Table, TableBody, TableContainer } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  useTable,
} from '../../../../new-ui/components/table'
import { TableRowsLoader } from '../../../../new-ui/components/table/TableRowSkeleton'
import { OrganizationsTableRow } from './OrganizationsTableRow'

export const OrganizationsTableContainer = props => {
  const { labelStrings } = useTranslation()

  const { tableData, isLoading, onSwitchToOrganizations } = props || {}

  const { page, order, orderBy, rowsPerPage } = useTable()

  const denseHeight = 52
  const isNotFound = false
  const data = !tableData?.length

  const TABLE_HEAD = [
    {
      id: 'displayName',
      label: labelStrings?.campaignCreatorOverview?.tableHead?.name,
      align: 'left',
    },
    { id: 'members', label: labelStrings.members, align: 'left' },
    { id: 'role', label: labelStrings.created, align: 'left' },
    { id: '' },
  ]

  return (
    <Box sx={{ py: 2 }}>
      <Card sx={{ width: '100%' }}>
        <TableContainer sx={{ width: '100%', overflow: 'unset' }}>
          <Table size="small" sx={{ minWidth: 800 }}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length}
            />

            <TableBody sx={{ width: '100%' }}>
              {isLoading ? (
                <TableRowsLoader rowsNum={4} thNum={3} />
              ) : (
                !isLoading &&
                tableData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map(row => (
                    <OrganizationsTableRow
                      key={row.orgName}
                      row={row}
                      onSwitchToOrganizations={() => onSwitchToOrganizations(row)}
                    />
                  ))
              )}
              <TableNoData isNotFound={isNotFound} />
            </TableBody>

            <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, data)} />
          </Table>
        </TableContainer>
      </Card>
    </Box>
  )
}
