import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import { USER_INDEXES } from '../../../constants/appSettings'
import InfoTip from '../../common/tips/InfoTip'
import InfludataRating from '../../qualityScores/InfludataRating'
import {
  RatingWithDescriptionWrapper,
  DescriptionWrapper,
  OverallScoreTitle,
  OverallScoreText,
} from './styles'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../commonProfilePageStyles'
import { Stack, Box } from '@mui/material'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { pretifyBigNum } from '../../../utils'
import EmvChart from '../../qualityScores/EmvChart'

export const defaultEmvChartData = [
  {
    p: 0,
    value: 0,
    score: 1,
    height: 0.1,
    minWidth: '12px',
    isActive: false,
  },
  {
    p: 20,
    value: 5000,
    score: 2,
    height: 0.25,
    minWidth: '12px',
    isActive: false,
  },
  {
    p: 30,
    value: 10000,
    score: 3,
    height: 0.4,
    minWidth: '12px',
    isActive: false,
  },
  {
    p: 40,
    value: 50000,
    score: 4,
    height: 0.55,
    minWidth: '12px',
    isActive: false,
  },
  {
    p: 50,
    value: 100000,
    score: 6,
    height: 0.7,
    minWidth: '12px',
    isActive: false,
  },
  {
    p: 60,
    value: 500000,
    score: 9,
    height: 0.85,
    minWidth: '12px',
    isActive: false,
  },
  {
    p: 100,
    value: 1000000,
    score: 10,
    height: 1,
    minWidth: '12px',
    isActive: false,
  },
]

const InfludataQualityRating = ({ overallScore = 0, platform = '', isForPdf = false, creatorCollaborations = null }) => {
  const { labelStrings, scoreStrings, profileSectionDescs } = useTranslation()

  const { metadata } = creatorCollaborations || {}
  const isBrandsProfile = creatorCollaborations && Object.keys(creatorCollaborations)?.length
  const emvValue = metadata?.totalMediaValue

  const [currencyFromStorage] = useLocalStorage('currency')
  const currencySymbol = currencyFromStorage === "usd" ? "$" : currencyFromStorage === "eur" ? "€" : '£'

  const igScoreStrings = scoreStrings[USER_INDEXES.instagram]

  const emvChartData = defaultEmvChartData.map(item => ({
    ...item,
    isActive: emvValue >= item.value,
  }))

  return (
    <>
      {!isForPdf && (
        <SectionTitleWrapper>
          <SectionTitleName platform={platform}>
            {labelStrings.infludataQualityRating}
          </SectionTitleName>
          <InfoTip>
            <div
              dangerouslySetInnerHTML={{ __html: profileSectionDescs.qualityRating[platform] }}
            />
          </InfoTip>
          <SectionTitleBorder isForPdf={isForPdf} />
        </SectionTitleWrapper>
      )}

      <RatingWithDescriptionWrapper isForPdf={isForPdf}>
        <DescriptionWrapper>
          <OverallScoreTitle>{igScoreStrings.overallScore.title}</OverallScoreTitle>
          <OverallScoreText>{igScoreStrings.overallScore.description}</OverallScoreText>
        </DescriptionWrapper>

        <InfludataRating
          scoreValue={overallScore}
          platform={platform}
          isOverallScore
          isTitleHidden
          isForPdf={isForPdf}
        />
      </RatingWithDescriptionWrapper>

      {isBrandsProfile && (
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <DescriptionWrapper>
              <OverallScoreTitle>{igScoreStrings.brandPerformanceOverall.title}</OverallScoreTitle>
              <OverallScoreText>
                {igScoreStrings.brandPerformanceOverall.description}
              </OverallScoreText>
            </DescriptionWrapper>
          </Stack>

          <Box>
            <Stack alignItems="center" sx={{ pb: 2 }}>
              <OverallScoreTitle>
                {igScoreStrings.brandPerformanceOverall.titleChart}
              </OverallScoreTitle>
              <OverallScoreTitle>
                {`${currencySymbol} ${pretifyBigNum(emvValue)}`}
              </OverallScoreTitle>
            </Stack>

            <EmvChart benchmarkArray={emvChartData} />
          </Box>
        </Stack>
      )}
    </>
  )
}

InfludataQualityRating.propTypes = {
  overallScore: PropTypes.number,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  isForPdf: PropTypes.bool,
  creatorCollaborations: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
}

export default InfludataQualityRating
