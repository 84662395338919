const assert = require('assert')

const collabApi = '/api'
assert(collabApi)

const routes = {
  sign: {
    in: '/api/profile/authenticate?onSuccess=/',
    out: '/api/profile/logout',
    takenEmail: '/api/profile/emailtaken',
    subscribe: '/api/profile/cb/subscribe',
    plans: '/api/profile/cb/plans',
    subscriptions: '/api/profile/cb/subscriptions',
    upgrade: '/api/profile/cb/upgrade_subscription',
    setPassword: '/api/profile/setpassword',
    signupAndStartTrialCampaign: '/api/profile/cb/signup_start_trial_campaign',
    deleteAccount: '/api/profile/delete_account',
    managePaymentSources: '/api/profile/cb/manage_payment_sources',
    getProfileDetails: '/api/profile/cb/get_profile_details',
    updateBillingInfo: '/api/profile/cb/update_billing_info',
    reactivateActiveSubscription: '/api/profile/cb/reactivate_active_subscription',
    reactivateInactiveSubscription: '/api/profile/cb/reactivate_inactive_subscription',
    forceImmediateActivation: '/api/profile/cb/force_immediate_activation',
    changeSeatsOfTracking: '/api/profile/cb/change_seats_of_tracking',
    purchaseOneTimeItems: '/api/profile/cb/purchaseOneTimeItems',
    switchOrganizationalAccount: '/api/profile/switch_organizational_account',
  },

  audiences: {
    login: '/api/audiences/login',
    getReport: '/api/audiences/getReport',
    joinTeam: '/api/audiences/joinTeam',
    renewSubscriptionNow: '/api/audiences/renewSubscriptionNow',
    getUserSuggestionByLetters: '/api/audiences/getUserSuggestionByLetters',
  },

  chargebee: {
    forceImmediateActivation: `/api/chargebee/force_immediate_activation/`,
    cancelSubscription: `/api/chargebee/cancel_subscription/`,
    removeScheduledCancellation: `/api/chargebee/remove_scheduled_cancellation/`,
    reactivateCancelledSubscription: `/api/chargebee/reactivate_cancelled_subscription/`
  },

  collab: {
    api: {
      getDataIG: `${collabApi}/getDataIG`,
      getDataTT: `${collabApi}/getDataTT`,
      getDataYT: `${collabApi}/getDataYT`,
      getDataContent: `${collabApi}/getDataContent`,
      getTimelineForContent: `${collabApi}/getTimelineForContent`,
      putData: `${collabApi}/putData`,
      updateData: `${collabApi}/updateData`,
      deleteData: `${collabApi}/deleteData`,
      login: `${collabApi}/login`,
      updateSavedUsers: `${collabApi}/updateSavedUsers`,
      getSaved: `${collabApi}/getSaved`,
      getCollection: `${collabApi}/getCollection`,
      changeElement: `${collabApi}/changeElement`,
      getStartupData: `${collabApi}/getStartupData`,
      removeElementFromCollection: `${collabApi}/removeElementFromCollection`,
      renameCollection: `${collabApi}/renameCollection`,
      deleteCollection: `${collabApi}/deleteCollection`,
      changeOrderCollection: `${collabApi}/changeCollectionOrder`,
      changeSortCollectionView: `${collabApi}/changeCollectionView`,
      emptyCollection: `${collabApi}/emptyCollection`,
      renameCampaign: `${collabApi}/renameCampaign`,
      emptyCampaign: `${collabApi}/emptyCampaign`,
      addElementToCollection: `${collabApi}/addElementToCollection`,
      getAllCollections: `${collabApi}/getAllCollections`,
      createCollection: `${collabApi}/createCollection`,
      getCitiesForCountry: `${collabApi}/getCitiesForCountry`,
      getCountriesCities: `${collabApi}/getCountriesCities`,
      getUserById: `${collabApi}/getUserById`,
      getCSVData: `${collabApi}/getCSVData`,
      getMailAddress: `${collabApi}/getMailAddress`,
      getAllVideos: `${collabApi}/getAllVideos`,
      getUserByUsername: `${collabApi}/getUserByUsername`,
      getCampaign: `${collabApi}/getCampaign`,
      getAllCampaigns: `${collabApi}/getAllCampaigns`,
      addElementToCampaign: `${collabApi}/addElementToCampaign`,
      removeElementFromCampaign: `${collabApi}/removeElementFromCampaign`,
      deleteCampaign: `${collabApi}/deleteCampaign`,
      createCampaign: `${collabApi}/createCampaign`,
      getSuggestedUsers: `${collabApi}/getUserSuggestionByLetters`,
      getSimilarUsers: `${collabApi}/getSimilarUsers`,
      contactUs: `${collabApi}/contactUs`,
      sendFeedback: `${collabApi}/giveFeedback`,
      createNewInfludataCustomer: `${collabApi}/createCustomer`,
      forgetPassword: `${collabApi}/forgetPassword`, 
      addProfileToInfludata: `${collabApi}/addUserToDatabase`,
      unlockAudienceAnalysisReport: `${collabApi}/unlockReport`,
      getComparedUserById: `${collabApi}/getComparedUserById`,
      getComparedUserByIds: `${collabApi}/getComparedUserByIds`,
      removeTeamMember: `${collabApi}/removeTeamMember`,
      changeCollectionAccess: `${collabApi}/changeCollectionAccess`,
      changeCollectionOrder: `${collabApi}/changeCollectionOrder`,
      addNotification: `${collabApi}/addNotification`,
      addAdditionalField: `${collabApi}/addAdditionalField`,
      editAdditionalFields: `${collabApi}/editAdditionalFields`,
      proxy: `${collabApi}/proxy-cors`,
      getGoogleLocationByLetters: `${collabApi}/getGoogleLocationByLetters`,
      getCampaignDashboard: `${collabApi}/campaigns/getDashboard`,
      getPreviewCampaignDashboard: '/api/campaigns/dashboardPreview',
      getAllNewCampaigns: `${collabApi}/campaigns/getAllCampaigns`,
      getNewCampaignById: `${collabApi}/campaigns/getCampaignById`,
      editDashboards: `${collabApi}/campaigns/editDashboards`,
      getContentList: `${collabApi}/campaigns/getContentList`,
      editContent: `${collabApi}/campaigns/editContent`,
      getCreatorList: `${collabApi}/campaigns/getCreatorList`,
      addCreatorToCampaign: `${collabApi}/campaigns/addCreatorToCampaign`,
      changeCreator: `${collabApi}/campaigns/changeCreator`,
      getCalendarData: `${collabApi}/campaigns/getCalendarData`,
      addEditCalendarData: `${collabApi}/campaigns/addEditCalendarData`,
      getCampaignGoals: `${collabApi}/campaigns/getCampaignGoals`,
      editCampaignGoals: `${collabApi}/campaigns/editCampaignGoals`,
      createNewCampaign: `${collabApi}/campaigns/createCampaign`,
      editCampaignById: `${collabApi}/campaigns/editCampaignById`,
      getTrackedMetrics: `${collabApi}/campaigns/getTrackedMetrics`,
      editTrackedMetrics: `${collabApi}/campaigns/editTrackedMetrics`,
      getCustomFields: `${collabApi}/campaigns/getCustomFields`,
      editCustomFields: `${collabApi}/campaigns/editCustomFields`,
      addCustomFieldValueManually: `${collabApi}/campaigns/addCustomFieldValueManually`,
      removeContentFromTracking: `${collabApi}/campaigns/removeContentFromTracking`,
      editNotifications: `${collabApi}/campaigns/editNotifications`,
      getNonRecognizedContent: `${collabApi}/campaigns/getNonRecognizedContent`,
      addContentToRecognizedContent: `${collabApi}/campaigns/addContentToRecognizedContent`,
      adjustCPMValues: `${collabApi}/adjustCPMValues`,
      adjustCPMValuesForCampaign: `${collabApi}/campaigns/adjustCPMValuesForCampaign`,
      getPipelineData: `${collabApi}/campaigns/getPipelineData`,
      addEditPipelineColumns: `${collabApi}/campaigns/addEditPipelineColumns`,
      addEditPipelineCards: `${collabApi}/campaigns/addEditPipelineCards`,
      addRemovePipelineComment: `${collabApi}/campaigns/addRemovePipelineComment`,
      addEditPipelineData: `${collabApi}/campaigns/addEditPipelineData`,
      addProfilesToOverlap: `${collabApi}/addProfilesToOverlap`,
      getAllOverlaps: `${collabApi}/getAllOverlaps`,
      removeDemoCampaign: `${collabApi}/campaigns/removeDemoCampaignsForCustomer`,
      requestSignupToken: `${collabApi}/requestSignupToken`,
      verifySignupToken: `${collabApi}/verifySignupToken`,
      addMoreSignupInfo: `${collabApi}/addMoreSignupInfo`,
      createNewTeamMember: `${collabApi}/createNewTeamMember`,
      changeTeamMemberRole: `${collabApi}/changeTeamMemberRole`,
      getContentDownloadLinks: `${collabApi}/campaigns/getContentDownloadLinks`,
      getSuggestedUsersById: `${collabApi}/getSuggestedUsersById`,
      buildLookalike: `${collabApi}/buildLookalike`,
      deleteLookalikeList: `${collabApi}/deleteLookalikeList`,
      catchOMR: `${collabApi}/catchOMR`,
      getCSVDataFromSearch: `${collabApi}/getCSVDataFromSearch`,
      getExploreData: `${collabApi}/campaigns/getExploreData`,
      createCampaignByCopy: `${collabApi}/campaigns/createCampaignByCopy`,
      getSearchBySmartText: `${collabApi}/getSearchBySmartText`,
      receiveSurveyResult: `${collabApi}/receiveSurveyResult`,
      enforceSameAzureTenant: `${collabApi}/enforceSameAzureTenant`,
      getLocationByIp: `${collabApi}/getLocationByIp`,
      searchCampaignContentByLink: `${collabApi}/campaigns/searchCampaignContentByLink`,
      uploadFileGetUrlForSimilarContent: `${collabApi}/uploadFileGetUrlForSimilarContent`,
      pullNotifications: `${collabApi}/pullNotifications`,
      markNotificationsAsSeen: `${collabApi}/markNotificationsAsSeen`,
      getLookalikeLists: `${collabApi}/getLookalikeLists`,
      requestChangeEmail: `${collabApi}/requestChangeEmail`,
      verifyChangeEmail: `${collabApi}/verifyChangeEmail`,
      getTeamMembersAndSeats: `${collabApi}/getTeamMembersAndSeats`,
      getAllOrganizations: `${collabApi}/getAllOrganizations`,
      createNewOrganization: `${collabApi}/createNewOrganization`
    },
  },
}

export default routes
