import { useEffect } from 'react'
import { userCredsSelector } from '../redux/selectors'
import { useSelector } from 'react-redux'

const HubSpotFormWidget = () => {
  const userCreds = useSelector(userCredsSelector)

  const identifyCustomer = () =>
    new Promise((resolve, reject) => {
      const tryIdentification = () => {
        if (window._hsq && userCreds && userCreds.hubspotToken && userCreds.username) {
          console.log('HubSpot queue available, identifying...')
          window._hsq.push(['identify', { email: userCreds.username }])
          window.hsConversationsSettings = {
            identificationEmail: userCreds.username,
            identificationToken: userCreds.hubspotToken,
          }
          resolve()
        } else {
          //console.log('HubSpot queue not available yet, retrying...');
          setTimeout(tryIdentification, 2000)
        }
      }

      tryIdentification()
    })

  const loadWidget = () => {
    if (window.HubSpotConversations && window.HubSpotConversations.widget) {
      console.log('Loading widget...')
      window.HubSpotConversations.widget.load()
    } else {
      console.log('Widget not available, retrying...')
      setTimeout(loadWidget, 1000) // Retry in 1 second
    }
  }

  useEffect(() => {
    if (!userCreds || !userCreds.username || !userCreds.hubspotToken) {
      console.log('NO CREDS')
    }

    // Set loadImmediately to false
    window.hsConversationsSettings = {
      loadImmediately: false,
      inlineEmbedSelector: '#hubSpot-wrapper'
    }

    const scriptAnalytics = document.createElement('script')
    scriptAnalytics.src = 'https://js.hs-analytics.net/analytics/139740319.js'
    scriptAnalytics.async = true

    scriptAnalytics.onload = () => {
      console.log('Analytics script loaded')
      identifyCustomer()
        .then(() => {
          setTimeout(() => {
            const scriptChat = document.createElement('script')
            scriptChat.src = 'https://js.hs-scripts.com/139740319.js'
            scriptChat.async = true

            scriptChat.onload = () => {
              console.log('Chat script loaded')
              setTimeout(loadWidget, 2000) // Introduced a delay of 10 seconds
            }

            document.body.appendChild(scriptChat)
          }, 2000) // Introduced a delay of 2 seconds between analytics and chat script
        })
        .catch(err => {
          console.error('Failed to identify customer:', err)
        })
    }

    document.body.appendChild(scriptAnalytics)

    return () => {
      const scriptAnalytics = document.querySelector(
        'script[src="https://js.hs-analytics.net/analytics/139740319.js"]'
      )
      if (scriptAnalytics) {
        document.body.removeChild(scriptAnalytics)
      }

      const scriptChat = document.querySelector(
        'script[src="https://js.hs-scripts.com/139740319.js"]'
      )
      if (scriptChat) {
        document.body.removeChild(scriptChat)
      }
    }
  }, [userCreds.username, userCreds.hubspotToken])

  return null
}

export default HubSpotFormWidget
