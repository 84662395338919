import React, { useMemo, memo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import useTranslation from '../../../../../localization/useTranslation'
import { FAV_COLLECTION_NAME, UNLOCKED_COLLECTION_NAME } from '../../../../../constants/collections'

import {
  OverflowBreakout,
  Span,
  CollectionLink,
  UnlockedIconWrapper,
} from '../collectionsSidebarStyles'
import { Box } from '@mui/material'
import { CollectionsHeartFullIcon, UnlockedIcon } from '../../../../../components/common/icons'
import { BubbleBlock } from '../../../../../components/common/styledWrappers'
import CollectionAccessStatus from '../../../../../components/collectionsAccess/CollectionAccessStatus'
import WithUpgradeTip from '../../../../../components/common/popups/WithUpgradeTip'

const CollectionItem = (props) => {
  const { 
    collection = {},
    areFavoritesAllowed = true,
    enableItemClick = false,
    isNavType = 'default',
    onHandleOpenViewType = () => {} 
  } = props || {}

  const history = useHistory()
  const { labelStrings, currentLang } = useTranslation()

  const isFavCollection = collection.collectionId?.startsWith(FAV_COLLECTION_NAME)
  const isUnlockedCollection = collection.collectionId === UNLOCKED_COLLECTION_NAME

  const goToCollection = () => {
    enableItemClick && history.push(`/collections/${collection.collectionId}`)
    if (collection.collectionId === 'unlocked') {
      onHandleOpenViewType && onHandleOpenViewType()
    }
  }

  const CollectionItem = useMemo(() => {
    let collectionName = collection.name
    let icon = null

    if (isFavCollection) {
      collectionName = labelStrings.favorites
      icon = <CollectionsHeartFullIcon />
    } else if (isUnlockedCollection) {
      collectionName = labelStrings.unlockedReports
      icon = (
        <UnlockedIconWrapper>
          <UnlockedIcon />
        </UnlockedIconWrapper>
      )
    }

    return (
      <CollectionLink
        onClick={goToCollection}
        isDisabled={isFavCollection && !areFavoritesAllowed}
        isNavType={isNavType}
      >
        {(isNavType !== 'min' || (isNavType === 'min' && collection.collectionId === 'unlocked')) &&
          icon}
        <Span isNavType={isNavType} isBoldText={isFavCollection || isUnlockedCollection}>
          {collectionName}
        </Span>
        {!isUnlockedCollection && (
          <>
            <BubbleBlock isNavType={isNavType}>{collection.count}</BubbleBlock>
            {isNavType !== 'min' && (
              <Box sx={{ ml: 0.5 }}>
                <CollectionAccessStatus access={collection.access} />
              </Box>
            )}
          </>
        )}
      </CollectionLink>
    )
  }, [collection, enableItemClick, currentLang, isNavType])

  const render = useMemo(() => {
    if (!isFavCollection || areFavoritesAllowed) return CollectionItem
    return (
      <WithUpgradeTip tipText="use favorites">
        <div>{CollectionItem}</div>
      </WithUpgradeTip>
    )
  }, [collection, enableItemClick, currentLang, isNavType])

  return <OverflowBreakout isUnlockedCollection={isUnlockedCollection}>{render}</OverflowBreakout>
}

CollectionItem.propTypes = {
  collection: PropTypes.object.isRequired,
  areFavoritesAllowed: PropTypes.bool,
  enableItemClick: PropTypes.bool,
  isNavType: PropTypes.string,
  onHandleOpenViewType: PropTypes.func,
}

export default memo(CollectionItem)
