import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../constants/appSettings'
import { changeAppSocialPlatform } from '../../../../redux/ducks/appSettingsDucks'
import { StyledSocialPlatformTab, StyledSocialPlatformTabs } from './styles'
import { InstagramSwitchIcon, TikTokSwitchIcon, YouTubeSwitchIcon } from './icons'
import { isUserLoggedInSelector, userGrantSelector } from '../../../../redux/selectors'

export const SocialPlatformSwitcher = ({ currentPlatform = '' }) => {
  const dispatch = useDispatch()

  const { enable_ig_search, enable_tt_search, enable_yt_search } = useSelector(
    userGrantSelector,
    shallowEqual
  )
  const isUserLoggedIn = useSelector(isUserLoggedInSelector)

  const [tabValue, setTabValue] = useState(SOCIAL_PLATFORMS_NAMES.instagram.name)

  const onChangeSocialTabs = (e, value) => {
    if (value === tabValue) return
    setTabValue(value)
    // @ts-ignore
    dispatch(changeAppSocialPlatform(value))
  }

  useEffect(() => {
    if (enable_ig_search) {
      setTabValue(SOCIAL_PLATFORMS_NAMES.instagram.name)
    } else if (enable_tt_search) {
      setTabValue(SOCIAL_PLATFORMS_NAMES.tiktok.name)
    } else if (enable_yt_search) {
      setTabValue(SOCIAL_PLATFORMS_NAMES.youtube.name)
    }
  }, [enable_ig_search, enable_tt_search, enable_yt_search])

  useEffect(() => {
    if (tabValue?.toLowerCase() !== currentPlatform?.toLowerCase()) {
      setTabValue(currentPlatform)
    }
  }, [currentPlatform, tabValue])

  return (
    <Box sx={{ backgroundColor: '#FDFCFF', borderRadius: '10px', padding: '3px', zIndex: 1 }}>
      <StyledSocialPlatformTabs value={tabValue} onChange={onChangeSocialTabs}>
        <StyledSocialPlatformTab
          disabled={!isUserLoggedIn || !enable_ig_search}
          sx={{ marginRight: '5px !important' }}
          current_tab={tabValue}
          is_active={`${tabValue === SOCIAL_PLATFORMS_NAMES.instagram.name}`}
          aria-label="Instagram"
          value={SOCIAL_PLATFORMS_NAMES.instagram.name}
          icon={
            <InstagramSwitchIcon
              sx={{ fontSize: 22 }}
              color={tabValue === SOCIAL_PLATFORMS_NAMES.instagram.name ? 'white' : '#A06E6E'}
            />
          }
        />
        <StyledSocialPlatformTab
          disabled={!isUserLoggedIn || !enable_tt_search}
          sx={{ marginRight: '5px !important' }}
          current_tab={tabValue}
          is_active={`${tabValue === SOCIAL_PLATFORMS_NAMES.tiktok.name}`}
          aria-label="TikTok"
          value={SOCIAL_PLATFORMS_NAMES.tiktok.name}
          icon={
            <TikTokSwitchIcon
              sx={{ fontSize: 18 }}
              color={tabValue === SOCIAL_PLATFORMS_NAMES.tiktok.name ? 'white' : '#A06E6E'}
            />
          }
        />
        <StyledSocialPlatformTab
          disabled={!isUserLoggedIn || !enable_yt_search}
          sx={{ marginRight: '0 !important' }}
          current_tab={tabValue}
          is_active={`${tabValue === SOCIAL_PLATFORMS_NAMES.youtube.name}`}
          aria-label="YouTube"
          value={SOCIAL_PLATFORMS_NAMES.youtube.name}
          icon={
            <YouTubeSwitchIcon
              sx={{ fontSize: 22 }}
              color={tabValue === SOCIAL_PLATFORMS_NAMES.youtube.name ? 'white' : '#A06E6E'}
            />
          }
        />
      </StyledSocialPlatformTabs>
    </Box>
  )
}

SocialPlatformSwitcher.propTypes = {
  currentPlatform: PropTypes.string,
}