import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Select, MenuItem, FormControl } from '@mui/material'
import { ADDITIONAL_FIELD_PROP_TYPE } from '../../../../constants/additionalFields'

const AdditionalFieldSelect = ({ additionalFieldData, onChange }) => {
  const { allowedValues: options, value: selectedValueId } = additionalFieldData || {}

  const [selectedValue, setSelectedValue] = useState(selectedValueId || '')

  const handleChange = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    onChange(newValue)
  }

  return (
    <FormControl size="small" fullWidth sx={{ height: '31px' }}>
      <Select
        size="small"
        value={selectedValue}
        onChange={handleChange}
        sx={{
          height: '31px',
          color: '#442424',
          fontWeight: 600,
          fontSize: '15px',
          fontFamily: 'Inter',
          '& svg': {
            fill: '#442424',
          },

          '&:focus': {
            border: '1px solid #F4E8E8',
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #F4E8E8',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #F4E8E8',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #F4E8E8',
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: '#fff9f9',
              color: '#442424',
              fontFamily: 'Inter',
              '& svg': {
                fill: '#442424',
              },
              '& .MuiTypography-root': {
                fontSize: '15px',
                fontFamily: 'Inter',
              },
            },
          },
        }}
      >
        {options?.map(option => (
          <MenuItem sx={{ color: '#442424' }} key={option.id} value={option.id}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

AdditionalFieldSelect.propTypes = {
  additionalFieldData: ADDITIONAL_FIELD_PROP_TYPE.isRequired,
  onChange: PropTypes.func,
}

export default AdditionalFieldSelect
