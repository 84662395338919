import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Divider,
  Box,
  Stack,
  Typography,
  Modal,
  Fade,
  Button,
  Backdrop,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import useTranslation from '../../../localization/useTranslation'
import { NAVBAR_HEIGHT } from '../../../constants/appSettings'
import { useDispatch } from 'react-redux'
import { showNoPlanModal } from '../../../redux/ducks/userDucks'

const CheckedGradientIcon = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient
        id="iconGradient"
        x1="0%"
        y1="0%"
        x2="100%"
        y2="0%"
        gradientTransform="rotate(87)"
      >
        <stop offset="1.15%" style={{ stopColor: '#FFB904', stopOpacity: 1 }} />
        <stop offset="30.19%" style={{ stopColor: '#FF6B00', stopOpacity: 1 }} />
        <stop offset="57.11%" style={{ stopColor: '#FF403B', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: '#FF3B99', stopOpacity: 1 }} />
      </linearGradient>
    </svg>
    <CheckCircleRoundedIcon sx={{ fill: 'url(#iconGradient)' }} />
  </>
)

const modalStyle = {
  position: 'absolute',
  top: '25%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '460px',
  maxWidth: '460px',
  boxShadow: 24,
  userSelect: 'none',
  outline: 'none',
}

const textStyles = {
  fontFamily: 'Inter',
  color: '#000',
}

const gradientBackground =
  'linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)'

export const NoPlanModal = () => {
  const { labelStrings, currentLang } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const onSubscribeNow = () => {
    history?.push('/settings')
    dispatch(showNoPlanModal(false))
  }

  const onSeeAllFeatures = () => {
    const redirectLink =
      currentLang === 'de'
        ? 'https://infludata.com/de/preise-infludata-cloud'
        : 'https://infludata.com/pricing-infludata-cloud'
    window.open(redirectLink)
  }

  return (
    <Modal
      open={true}
      onClose={() => {}}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{
        height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
        top: 'unset',
        zIndex: '999 !important',
        '& > .MuiBackdrop-root': {
          backgroundColor: 'rgba(255, 241, 241, 0.94)',
          height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
          top: 'unset',
        },
      }}
    >
      <Fade in={true}>
        <Box sx={modalStyle}>
          <Stack
            sx={{ position: 'absolute', top: '-130px', alignItems: 'center', outline: 'none' }}
          >
            <Typography
              sx={{
                fontSize: currentLang === 'de' ? '23px' : '30px',
                fontWeight: 700,
                background: gradientBackground,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              {labelStrings.no_plan.thankYouForTesting}
            </Typography>
            <Typography
              textAlign="center"
              sx={{
                ...textStyles,
                maxWidth: currentLang === 'de' ? '400px' : '350px',
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              {labelStrings.no_plan.yourTrial}
            </Typography>
            <Box
              sx={{
                backgroundColor: 'white',
                borderRadius: '24px',
                mt: 3,
                width: '100%',
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  background: gradientBackground,
                  p: 3,
                  borderRadius: '24px 24px 0 0',
                }}
              >
                <Stack>
                  <Typography
                    sx={{
                      ...textStyles,
                      color: '#fff',
                      fontSize: '16px',
                      fontWeight: 700,
                      ml: '6px',
                    }}
                  >
                    influData
                  </Typography>
                  <Typography
                    sx={{
                      color: '#fff',
                      fontSize: '40px',
                      fontWeight: 700,
                      lineHeight: '31px',
                    }}
                  >
                    Cloud
                  </Typography>
                </Stack>
                <Stack>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography
                      sx={{
                        color: '#fff',
                        fontSize: '40px',
                        fontWeight: 700,
                        lineHeight: '31px',
                      }}
                    >
                      €199
                    </Typography>
                    <Typography
                      sx={{
                        maxWidth: '57px',
                        color: 'rgba(255, 255, 255, 0.60)',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        textTransform: 'capitalize',
                      }}
                    >
                      {labelStrings.no_plan.perMonth}
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{
                      color: 'rgba(255, 255, 255, 0.40)',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '22px',
                      textTransform: 'capitalize',
                    }}
                  >
                    {labelStrings.no_plan.cancelAnyTime}
                  </Typography>
                </Stack>
              </Stack>

              <Stack sx={{ px: 3, py: 2 }}>
                <List
                  dense
                  sx={{
                    '& .MuiListItemIcon-root': { mr: '6px' },
                    '& .MuiTypography-root': {
                      ...textStyles,
                      fontSize: '16px',
                      fontWeight: 500,
                    },
                  }}
                >
                  {[
                    labelStrings.no_plan.allPlatformsAccess,
                    labelStrings.no_plan.searchBy,
                    labelStrings.no_plan.fullAccess,
                    labelStrings.no_plan.profileAnalytics,
                    labelStrings.no_plan.searchResults,
                    labelStrings.no_plan.campaignsTracking,
                  ].map(text => (
                    <ListItem key={text}>
                      <ListItemIcon>
                        <CheckedGradientIcon />
                      </ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItem>
                  ))}
                </List>
                <Box sx={{ m: '0 auto', mt: 0.5 }}>
                  <Button
                    onClick={onSeeAllFeatures}
                    sx={{
                      width: 'fit-content',
                      color: '#F50',
                      fontSize: '16px',
                      fontWeight: 500,
                      '& .MuiSvgIcon-root': {
                        fontSize: '16px',
                      },
                    }}
                    variant="text"
                    endIcon={<ArrowForwardIosRoundedIcon />}
                  >
                    {labelStrings.no_plan.seeAllFeatures}
                  </Button>
                </Box>
              </Stack>
              <Divider sx={{ width: '100%', height: '1px', borderColor: '#FFF1F1' }} />
              <Box sx={{ display: 'flex', justifyContent: 'center', px: 3, py: 2 }}>
                <Button
                  onClick={onSubscribeNow}
                  variant="contained"
                  sx={{
                    width: '100%',
                    color: '#fff',
                    background: gradientBackground,
                    fontSize: '16px',
                    fontWeight: 700,
                    borderRadius: '10px',
                    height: '50px',
                    textTransform: 'uppercase',
                  }}
                >
                  {labelStrings.no_plan.subscribeNow}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}
