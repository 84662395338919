import React from 'react'
import { useSelector } from 'react-redux'
import { userGrantSelector, allCampaignsSelector } from '../../../redux/selectors'
import useTranslation from '../../../localization/useTranslation'
import TokensRemainingStat from './TokensRemainingStat'

const CampaignsSeatsTokensStat = () => {
  const { labelStrings } = useTranslation()
  const { max_users_trackable, enable_tracking, in_trial_infludata } = useSelector(userGrantSelector)
  const { trackMax, trackNow } = useSelector(allCampaignsSelector)

  const _max = max_users_trackable || trackMax // trust grants first

  const tokensLimit = trackNow > _max ? trackNow : _max
  const tokensRemain = trackNow > tokensLimit ? 0 : tokensLimit - trackNow

  return (
    enable_tracking && (
      <TokensRemainingStat
        tokensName={labelStrings.influencerTrackingSeats}
        tokensLimit={tokensLimit}
        currentTokens={tokensLimit - tokensRemain}
        tokensRemain={tokensRemain}
        in_trial_infludata={in_trial_infludata}
      />
    )
  )
}

CampaignsSeatsTokensStat.propTypes = {}

export default CampaignsSeatsTokensStat
