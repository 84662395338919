import { useEffect, useState } from 'react'
import httpService from '../services/httpService'

export const useGeoLocation = ({ ip, isSignupOpened }) => {
  const [locationData, setLocationData] = useState(null)

  useEffect(() => {
    if (ip && isSignupOpened) {
      localStorage.removeItem('currentCountryFromIP')
      getLocation()
    }
  }, [ip, isSignupOpened])

  async function getLocation() {
    const locationData = await httpService.getLocationByIp({ ip })
    if (locationData) {
      setLocationData(locationData)
      localStorage.setItem('currentCountryFromIP', locationData.country)
    }
  }

  return {
    city: locationData?.city || '',
    country: locationData?.country_name || locationData?.country || '',
    countryCode: locationData?.country_code || 0,
    lat: locationData?.latitude || '',
    lon: locationData?.longitude || '',
    region: locationData?.region || '',
    regionCode: locationData?.region_code || '',
    timezone: locationData?.timezone || '',
    zip: locationData?.postal || 0,
  }
}
