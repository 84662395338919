// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { TopControls } from '../../sections/custom-fields/TopContorls'
import { FiledList } from '../../sections/custom-fields/FieldsList'
import { EditField } from '../../sections/custom-fields/EditField'
import { useDispatch, useSelector } from 'react-redux'
import { currentOpenedCampaignSelector, customFieldsSelector } from '../../../redux/selectors'
import {
  addValueManually,
  editCustomFields,
  getCustomFields,
} from '../../../redux/ducks/campaignsDucks'
import { AddValueManuallyModal } from '../../sections/custom-fields/AddValueManuallyModal'
import { WebhookToCustomField } from '../../sections/custom-fields/WebhookToCustomField'
import { BitLyModal } from '../../sections/custom-fields/BitLyModal'

export const CustomFields = () => {
  const dispatch = useDispatch()

  const { campaignId, creators } = useSelector(currentOpenedCampaignSelector)
  const { isLoading, data } = useSelector(customFieldsSelector)

  const [openBitLyFieldModal, setOpenBitLyFieldModal] = useState(false)

  const [openEditFieldModal, setOpenEditFieldModal] = useState(false)
  const [editField, setEditField] = useState({})
  const [currRow, setCurrRow] = useState(null)

  const [openAddValueModal, setOpenAddValueModal] = useState(false)
  const [openWebhook, setOpenWebHook] = useState(false)

  const [currPage, setCurrPage] = useState(0)

  const isExistBitlyFields = data?.filter(customField => customField.trackingMethod === 'bitly')

  const onHandleCurrentRow = row => {
    setCurrRow(row ? row : null)
  }

  useEffect(() => {
    if (campaignId && !data?.length) {
      dispatch(getCustomFields({ campaignId }))
    }
  }, [campaignId, data?.length])

  const onCreate = ({
    dataFormat,
    customFieldName,
    metricName,
    applyLevel,
    applyContentArray,
    trackingMethod,
  }) => {
    const payload = {
      method: 'add',
      campaignId,
      dataFormat,
      customFieldName,
      metricName,
      applyLevel,
      applyContentArray,
      trackingMethod,
    }
    dispatch(editCustomFields(payload))
  }

  const onRemove = ({ customFieldId }) => {
    const payload = {
      method: 'remove',
      campaignId,
      customFieldId,
    }
    dispatch(editCustomFields(payload))
  }

  const onEdit = ({
    customFieldId,
    dataFormat,
    customFieldName,
    metricName,
    applyLevel,
    applyContentArray,
    trackingMethod,
  }) => {
    const payload = {
      method: 'change',
      campaignId,
      customFieldId,
      dataFormat,
      customFieldName,
      metricName,
      applyLevel,
      applyContentArray,
      trackingMethod,
    }
    dispatch(editCustomFields(payload))
  }

  const onAddValueManually = data => {
    const payload = {
      campaignId,
      ...data,
      value: data.value ? Number(data.value) : '',
    }
    dispatch(addValueManually(payload))
  }

  return (
    <>
      <Box sx={{ p: 3 }}>
        <TopControls
          openCreateCustomFieldModal={setOpenEditFieldModal}
          setOpenBitLyFieldModal={setOpenBitLyFieldModal}
          isLoading={isLoading}
          isExistBitlyFields={isExistBitlyFields}
        />
        <FiledList
          openModal={setOpenEditFieldModal}
          setEditField={setEditField}
          onRemove={onRemove}
          data={data}
          isLoading={isLoading}
          setOpenAddValueModal={setOpenAddValueModal}
          onHandleCurrentRow={onHandleCurrentRow}
          setCurrPage={setCurrPage}
          setOpenWebHook={setOpenWebHook}
          setOpenBitLyFieldModal={setOpenBitLyFieldModal}
        />
      </Box>
      <EditField
        openEditFieldModal={openEditFieldModal}
        setOpenEditFieldModal={setOpenEditFieldModal}
        editField={editField}
        setEditField={setEditField}
        onCreate={onCreate}
        onEdit={onEdit}
      />
      <AddValueManuallyModal
        setOpenAddValueModal={setOpenAddValueModal}
        openAddValueModal={openAddValueModal}
        setCurrRow={setCurrRow}
        currRow={currRow}
        creatorsList={creators}
        onAddValueManually={onAddValueManually}
      />

      <WebhookToCustomField
        setOpenWebHook={setOpenWebHook}
        openWebhook={openWebhook}
        setCurrRow={setCurrRow}
        currRow={currRow}
      />

      <BitLyModal
        editData={editField}
        setEditField={setEditField}
        setOpenModal={setOpenBitLyFieldModal}
        isOpenModal={openBitLyFieldModal}
      />
    </>
  )
}
