import React, { useState } from 'react'
import Popup from 'reactjs-popup'
import { useDispatch, useSelector } from 'react-redux'
import { isAccountAlreadyExistsSelector, userAuthErrorSelector } from '../../redux/selectors'
import { toggleSignUp, toggleSignIn } from '../../redux/ducks/appSettingsDucks'
import { loginUser, setUserAuthError } from '../../redux/ducks/userDucks'
import { setError } from '../../redux/ducks/errorDucks'
import { ERROR_MSG } from '../../constants/errorMessages'

import { WithPopupWrapper } from '../common/styledWrappers'
import LoginForm from '../forms/LoginForm'
import EmailToGetLinkForm from '../forms/forgetPassword/EmailToGetLinkForm'
import LoadingSpinner from '../common/spinners/LoadingSpinner'
import useTranslation from '../../localization/useTranslation'
import httpService from '../../services/httpService'
import { API_STATUS_CODES } from '../../constants/appSettings'
import { Alert, Box, Button, Card, Divider, IconButton, Stack, Typography } from '@mui/material'
import useResponsive from '../../new-ui/hooks/useResponsive'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

const LoginContainer = () => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()

  const authError = useSelector(userAuthErrorSelector)

  const isMobile = useResponsive('down', 'sm')

  const [forgetPasswordState, changeIsForgetPasswordState] = useState({
    isForgetPassword: false,
    noUserFound: false,
    successSendLink: false,
    isLoading: false,
  })

  const setForgetPasswordState = newValObj =>
    changeIsForgetPasswordState({ ...forgetPasswordState, ...newValObj })

  const resetNoUserFound = () => setForgetPasswordState({ noUserFound: false })

  const toggleForgetPassword = () => {
    dispatch(setUserAuthError(false))
    setForgetPasswordState({
      isForgetPassword: !forgetPasswordState.isForgetPassword,
    })
  }

  const isAccountAlreadyExists = useSelector(isAccountAlreadyExistsSelector)

  const forgotPasswordHandler = async email => {
    try {
      setForgetPasswordState({ isLoading: true })
      await httpService.fetchForgetPassword(email)
      setForgetPasswordState({ isLoading: false, successSendLink: true })
    } catch (err) {
      if (err.response && err.response.status === API_STATUS_CODES.notFound) {
        setForgetPasswordState({ isLoading: false, noUserFound: true })
      } else {
        setForgetPasswordState({ isLoading: false })
        dispatch(setError(ERROR_MSG.sendResetPassLinkFail))
      }
    }
  }

  const signupHandler = () => dispatch(toggleSignUp())
  const closeLoginHandler = () => {
    dispatch(toggleSignIn())
    dispatch(setUserAuthError(false))
  }

  const { isForgetPassword, noUserFound, successSendLink, isLoading } = forgetPasswordState

  return (
    <WithPopupWrapper enableHeader fullScreen isTransparent>
      {isLoading && <LoadingSpinner isTransparent showOnFullPage />}
      <Popup open={true} position="right center" closeOnDocumentClick={false} modal>
        <Card sx={{ maxWidth: isMobile ? '350px' : '100%', borderRadius: '29px', }}>
          <IconButton
            size="small"
            onClick={closeLoginHandler}
            sx={{ position: 'absolute', right: '14px', top: '4px', color: '#784E4E' }}
          >
            <CloseRoundedIcon sx={{ fontSize: '20px' }} />
          </IconButton>
          <Box
            sx={{
              width: '371px',
              padding: isMobile ? '8px' : '0',
              maxWidth: isMobile ? '350px' : '371px',
              borderRadius: '29px',
              backgroundColor: '#FFFFFF',
            }}
          >
            {isForgetPassword ? (
              <Box sx={{ padding: isMobile ? '8px' : '24px' }}>
                {successSendLink ? (
                  <Typography
                    textAlign="center"
                    sx={{
                      letterSpacing: 0,
                      fontSize: '16px',
                      fontFamily: 'Inter',
                      width: '300px',
                      m: '17px auto',
                      color: '#442424',
                    }}
                  >
                    {labelStrings.passRecoveryInstructions}
                  </Typography>
                ) : (
                  <EmailToGetLinkForm
                    sendLinkToEmail={forgotPasswordHandler}
                    noUserFound={noUserFound}
                    resetNoUserFound={resetNoUserFound}
                  />
                )}
              </Box>
            ) : (
              <>
                {(isAccountAlreadyExists || authError) && (
                  <Box sx={{ px: isMobile ? 1 : 3, pt: 4 }}>
                    <Alert
                      sx={{
                        my: 1,
                        fontSize: '12px',
                        padding: '2px 6px',
                        fontFamily: 'Inter',
                        '& .MuiAlert-message': {
                          padding: '2px 0',
                          display: 'flex',
                          alignItems: 'center',
                          whiteSpace: 'pre-line',
                        },
                        '& .MuiAlert-icon': {
                          display: 'flex',
                          alignItems: 'center',
                          marginRight: '8px',
                        },
                      }}
                      variant="outlined"
                      severity="error"
                    >
                      {isAccountAlreadyExists
                        ? labelStrings.alreadyExistAccount
                        : labelStrings.invalidUser}
                    </Alert>
                  </Box>
                )}
                <LoginForm loginUser={loginUser} />

                <Divider sx={{ height: '1px', width: '100%', borderColor: '#FBEBEB' }} />

                <Stack sx={{ pt: 2, p: isMobile ? '8px' : '24px' }} alignItems="center">
                  <Button
                    size="small"
                    variant="text"
                    onClick={toggleForgetPassword}
                    sx={{
                      fontFamily: 'Inter',
                      color: '#FF5500',
                      fontSize: '16px',
                      fontWeight: 400,
                      width: 'fit-content',
                    }}
                  >
                    {labelStrings.forgotPassword}
                  </Button>

                  <Stack direction="row" alignItems="center">
                    <Typography sx={{ color: '#442424', fontFamily: 'Inter', fontSize: '16px' }}>
                      {labelStrings.noAccountYet}
                    </Typography>
                    <Button
                      size="small"
                      variant="text"
                      onClick={signupHandler}
                      sx={{
                        fontFamily: 'Inter',
                        color: '#FF5500',
                        fontSize: '16px',
                        fontWeight: 400,
                      }}
                    >
                      {labelStrings.tryForFree}
                    </Button>
                  </Stack>
                </Stack>
              </>
            )}
          </Box>
        </Card>
      </Popup>
    </WithPopupWrapper>
  )
}

export default LoginContainer
