import React from 'react'
import CommonSettingSection from '../CommonSettingSection'
import useTranslation from '../../../localization/useTranslation'
import { Box, Stack, Typography, Button, Tooltip } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { OrganizationsTableContainer } from './OrganizationsTable/OrganizationsTableContainer'

const Organizations = ({
  organizationLimit = 0,
  limitTeamMemberSeats,
  companyMembers,
  isSeatsUpdateInProgress = false,
  organizationsData,
  onCreateNewOrganizationsModal,
  onSwitchToOrganizations,
}) => {
  const { labelStrings } = useTranslation()

  const { isLoading, organizations, seatsUsed } = organizationsData || {}

  const availableSeats = organizationLimit - seatsUsed <= 0 ? 0 : organizationLimit - seatsUsed
  const canNotCreateNewOrganizations = organizationLimit <= seatsUsed
  const noMoreTeamMemberSeats = companyMembers.seatsUsed >= limitTeamMemberSeats

  let hoverButtonText = null
  if (canNotCreateNewOrganizations) {
    hoverButtonText = labelStrings.hoverNoOrganizationSeats
  } else if (noMoreTeamMemberSeats) {
    hoverButtonText = labelStrings.hoverNoTeamMemberForNewOrganization
  }


  const Content = (
    <>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography sx={{ fontFamily: 'Inter', color: '#212B36', fontWeight: 500 }}>
              {labelStrings.usedOrganizations(seatsUsed, organizationLimit, availableSeats)}
            </Typography>
            <Typography sx={{ fontFamily: 'Inter', fontSize: '12px' }}>
              {labelStrings.pleaseContactUsViaChatToRemoveOrganizations}
            </Typography>
          </Stack>
          <Tooltip
            title={hoverButtonText || ''}
            disableHoverListener={!hoverButtonText} 
            arrow
          >
            <span>
              <Button
                size="small"
                disabled={hoverButtonText}
                onClick={onCreateNewOrganizationsModal}
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '13px',
                  borderRadius: '10px',
                  textTransform: 'uppercase',
                  width: 'fit-content',
                  color: '#FF5C00',
                  borderColor: '#FF5C00',
                  backgroundColor: '#fff',
                  '&:hover': {
                    color: 'white',
                    backgroundColor: '#FF5500',
                  },
                }}
                startIcon={<AddRoundedIcon />}
                variant="outlined"
              >
                {labelStrings.createNewOrganizations}
              </Button>
            </span>
          </Tooltip>
        </Stack>

        <OrganizationsTableContainer
          tableData={organizations}
          isLoading={isLoading}
          onSwitchToOrganizations={onSwitchToOrganizations}
        />
      </Box>
    </>
  )

  const mainTitle = (
    <Stack spacing={0.5} sx={{ maxWidth: '230px' }}>
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontSize: '30px',
          fontWeight: 600,
          minWidth: '20%',
          width: 'fit-content',
        }}
      >
        {labelStrings.organizations}
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontSize: '12px',
          color: '#212B36',
        }}
      >
        {labelStrings.organizationsSubtitle}
      </Typography>
    </Stack>
  )

  return (
    <CommonSettingSection
      title={mainTitle}
      Content={Content}
      withLoading
      isLoading={isSeatsUpdateInProgress}
    />
  )
}

export default Organizations
