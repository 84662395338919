import styled, { css } from 'styled-components'
import { USER_INDEXES } from '../../../constants/appSettings'
import { convertHexToRgba } from '../../../utils'

// Profile Overview
export const ProfileOverviewContainer = styled.div`
  width: 100%;
  height: ${props => (props.isProfileVerified ? '635px' : '480px')};
  padding: 40px 0;
  position: relative;
  font-family: ${props => props.theme.fontFamilyInter};
  background-color: rgba(68, 36, 36, 0.85);
  ${props => props.isPrivate && 'padding-top: 50px'};

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    display: block;
    background-image: url(${props => props.imgSrc});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 96%;
    filter: blur(16.5px);
  }
`

export const ProfileOverviewBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: url(${props => props.avatarSrc});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => {
      switch (props.platform) {
        case USER_INDEXES.instagram:
          return convertHexToRgba(props.theme.colors.ultraLightBrown, 0.95)
        case USER_INDEXES.tiktok:
          return convertHexToRgba(props.theme.color.black, 0.8)
        case USER_INDEXES.youtube:
          return convertHexToRgba(props.theme.colors.ultraLightBrown, 0.95)
      }
    }};
    backdrop-filter: blur(33px);
  }
`

export const GoBackToResultsLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 40px;
  left: 70px;
  cursor: pointer;
  ${props => `
      color: ${props.theme.colors.mauve};
      .icon_go-back {
        path {
          fill: ${props.theme.colors.mauve};
        }
      }
    `}
`

export const GoBackToResultsLabel = styled.span`
  font-family: ${props => props.theme.fontFamilyInter};
  font-size: 13px;
  margin-left: 10px;
`

export const ProfileOverview = styled.div`
  display: flex;
  ${props => props.isProfileVerified && `
    flex-direction: column;
    width: 100%;
  `};
  justify-content: space-between;
  align-items: center;;
  margin: 35px 0;
  ${'' /* padding-left: 20px;

  @media screen and (min-width: 1500px) {
    padding-left: 100px;
  }

  @media screen and (min-width: 1700px) {
    padding-left: 200px;
  } */}

  ${'' /* @media screen and (min-width: 1900px) {
    padding-left: 300px;
  } */}

  ${'' /* @media screen and (min-width: 2000px) {
    justify-content: center;
    margin-right: 135px;
    padding-left: 0;
  } */}
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: 140px;
`

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.withoutPadding ? 'center' : 'flex-start')};
  align-items: flex-start;
  width: ${props => props.width || 'auto'};
  padding: ${props => (props.withoutPadding ? '0' : '0 20px')};
  ${props =>
    props.darkVersion &&
    `
    margin-left: 23px;
    margin-right: 47px;
  `};

  &:last-child {
    margin-right: 0;
  }
`

export const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  & + & {
    margin-top: 15px;
  }

  &:after {
    content: "${props => props.note || ''}";
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    font-size: 11px;
    line-height: 13px;
    color: ${props => props.theme.colors.mauve};
    font-weight: 600;
  }
`

export const InfoGroupName = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.mauve};
  white-space: nowrap;
`

export const AvatarContainer = styled(InfoColumn)`
  justify-content: center;
  align-items: center;
  min-width: 250px;
  max-width: 300px;
  position: relative;
`
export const AvatarVerifiedIcon = styled.div`
  position: absolute;
  top: -10px;
  left: 28px;
`

export const AvatarWrapper = styled.div`
  ${'' /* margin-bottom: 20px; */}

  .rating_meter {
    circle {
      stroke-width: 7px;
    }
  }
  .rating_meter-lg {
    circle {
      stroke-width: 4.5px;
    }
  }
`

const commonTextStyles = css`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.offWhite};
`

const coloredTextStyles = css`
  color: ${props => {
    switch (props.platform) {
      case USER_INDEXES.instagram:
        return props.theme.colors.salmon
      case USER_INDEXES.tiktok:
        return props.theme.colors.aqua
      case USER_INDEXES.youtube:
        return props.theme.colors.red
    }
  }};
`

export const Username = styled.div`
  font-size: 1.7vw;
  line-height: inherit;
  font-weight: bold;
  width: 100%;

  ${props =>
    !props.isForPdf
      ? props.theme.getTextGradientStyles({ platform: props.platform })
      : `color: ${props.theme.getColorPaletteForPlatform(props.platform).main}`};
  text-align: center;

  ${'' /* @media screen and (min-width: 1600px) {
    font-size: 24px;
    line-height: 26px;
  }

  @media screen and (max-width: 1135px) {
    font-size: 18px;
    line-height: 22px;
  } */}
`

export const DisplayName = styled.div`
  font-size: 18px;
  font-weight: 500;
  ${props =>
    !props.isForPdf
      ? props.theme.getTextGradientStyles({ platform: props.platform })
      : `color: ${props.theme.getColorPaletteForPlatform(props.platform).main}`}
`

export const Location = styled.div`
  ${commonTextStyles}
  margin-bottom: 5px;
`

export const Biography = styled.div`
  ${commonTextStyles};
  max-width: 350px;
`

export const CopyIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
  max-width: 100%;
  margin-right: 25px;
  ${commonTextStyles}

  .icon_copy {
    visibility: hidden;
    margin-left: 5px;
    width: 20px;

    path {
      fill: ${props => {
        switch (props.platform) {
          case USER_INDEXES.instagram:
            return props.theme.colors.white
          case USER_INDEXES.tiktok:
            return props.theme.colors.white
          case USER_INDEXES.youtube:
            return props.theme.colors.white
        }
      }};
    }
  }

  &:hover {
    .icon_copy {
      visibility: visible;
    }
  }
`

export const Email = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`

export const PersonalWebsite = styled.a`
  ${commonTextStyles}
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    ${commonTextStyles}
    text-decoration: ${props => (props.isDisabled ? 'none' : 'underline')};
  }
`

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: ${props => (props.withoutMT ? 0 : '15px')};
  padding-right: 20px;

  > div {
    margin-top: 0 !important;
  }
`

export const Table = styled.table`
  white-space: nowrap;
`

export const Tbody = styled.tbody``

export const Tr = styled.tr``

export const Td = styled.td`
  ${props => props.pt && `padding-top: ${props.pt}`}
`

export const ProfileDataFieldName = styled.div`
  ${commonTextStyles}
  padding-right: 20px;
  text-transform: capitalize;
`

export const ProfileDataValue = styled.div`
  font-size: 15px;
  line-height: 19px;
  font-weight: bold;
  max-width: 350px;
  ${coloredTextStyles}
`

// Social Media
export const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 5px;
`

export const SocialMediaItem = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;

  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 0px 4px -2px #784E4E;
  border-radius: 5px;

  & + & {
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.8;
  }

  ${'' /* .yt-classic {
    path {
      &:last-child {
        fill: ${props => props.theme.colors.brown};
      }
    }
  } */}
`

// Profile Overview Tools
export const ProfileOverviewToolsWrapper = styled.div`
  position: absolute;
  top: ${props => (props.isProfileVerified ? '35%' : '50%')};
  right: 0;
  transform: ${props => (props.isProfileVerified ? 'translateY(-35%)' : 'translateY(-50%)')};
`

export const ToolIconWrapper = styled.div`
  margin-right: 15px;
`

export const ToolTitle = styled.div`
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: ${props => props.theme.colors.offWhite};
`

export const ToolItem = styled.button`
  ${props => props.isDisabled && props.theme.disabled}
  width: 135px;
  height: 45px;
  padding: 8px 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
  border: none;
  border-radius: 12px 0px 0 12px;
  background-color: ${props => {
    const activeColor = props.theme.colors.salmon
    let disabledColor = convertHexToRgba(props.theme.colors.darkBrown, 0.5)

    if (props.platform === USER_INDEXES.tiktok) {
      disabledColor = convertHexToRgba(props.theme.colors.aqua, 0.4)
    }

    if (props.isActive) return activeColor
    if (props.isDisabled) return disabledColor
    return convertHexToRgba(props.theme.colors.darkBrown, 0.85)
  }};

  & + & {
    margin-top: 15px;
  }

  &:hover {
    ${props =>
      !props.isActive &&
      `
      background-color: ${props.theme.colors.darkBrown};

      ${ToolTitle} {
        color: ${props.theme.colors.white};
      }
    `}
  }

  ${props =>
    !!props.numberInBubble &&
    `
    &:before {
      content: '${props.numberInBubble}';
      position: absolute;
      top: 50%;
      left: -9px;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: ${props.theme.colors.darkBrown};
      color: ${props.theme.colors.offWhite};
    }
  `}

  ${props => props.isDisabled && 'pointer-events: none;'}
`

export const PrivateAccountMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 5px 0;
  font-size: 15px;
  line-height: 19px;
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.darkBrown};
`
export const DisabledTipWrapper = styled.div`
  margin: 15px 0;
`
export const StatisticsWrapper = styled.div`
  font-size: 15px;
  line-height: 19px;
  max-width: 350px;
  color: ${props => props.theme.colors.white};
  span {
    font-weight: bold;
  }
`
export const EmptyBlock = styled.div`
  height: ${props => (props.isYt ? '12px' : '25px')};
`
export const EmptyValue = styled.span`
  color: ${props => props.theme.colors.white};
`
export const ProfileOverviewVerifiedContainer = styled.div`
  width: 80%;
  @media screen and (max-width: 1400px) {
    width: 90%;
  }
  ${'' /* padding-left: 82px; */}
  ${'' /* padding-right: 70px; */}
  padding-top: 50px;
`
export const VerifiedSectionText = styled.div`
  display: flex;
  align-items: center;
`

export const VerifiedSectionTitleName = styled.div`
  font-size: 15px;
  font-weight: 800;
  line-height: 19px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.green};
  margin-right: 5px;
  margin-left: 8px;
`

export const VerifiedContent = styled.div`
  width: 100%;
  margin-top: 26px;
  display: flex;
  color: ${props => props.theme.colors.white};
`
export const VerifiedInformationColumn = styled.div`
  max-width: ${props => props.maxWidth ? props.maxWidth : '399px'};
  width: 100%;
  h4 {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 11px;
    color: ${props => props.theme.colors.mauve};
    text-transform: uppercase;
  }
  margin-right: 115px;
  .verified-information__message {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 80px;
  }
  .verified-information__contact {
    div {
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .column-left {
      min-width: 121px;
      text-transform: capitalize;
    }
    span {
      font-weight: 700;
      font-size: 15px;
      line-height: 19px;
      color: ${props => props.theme.colors.green};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      a {
        text-decoration: none;
        color: ${props => props.theme.colors.green};

        &:hover {
          opacity: 0.8;
          color: ${props => props.theme.colors.green};
        }
      }
    }
  }
  .verified-information__download {
    max-width: 435px;
    height: 85px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  ${props => props.downloads && `
    margin-left: 40px;
  `};

  &:last-child {
    margin-right: 0;
  }
`
export const VerifiedUploadFile = styled.a`
  border: 1px solid ${props => props.theme.colors.lightMauve};
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.brown};
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  text-decoration: none;
  max-width: 214px;
  height: 37px;
  width: 100%;
  min-height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 9px;

  svg {
    margin-right: 5px;
  }

  &:hover {
    opacity: 0.8;
    color: ${props => props.theme.colors.brown};
    text-decoration: none;
  }
`