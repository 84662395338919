import React from 'react'
import { useHistory } from 'react-router-dom'
import { List, ListItemButton, Stack, Typography, Box } from '@mui/material'
import { userCredsSelector } from '../../../redux/selectors'
import { useDispatch, useSelector } from 'react-redux'
import Scrollbar from '../scrollbar/Scrollbar'
import CircleRoundedIcon from '@mui/icons-material/CircleRounded'
import useTranslation from '../../../localization/useTranslation'
import { fToNow } from '../../utils/formatTime'
import Iconify from '../iconify'
import { cancelAllFetches } from '../search-sidebar/searchSidebar/searchbarTipsFetches'
import {
  changeSuggestionsData,
  resetSearchResults,
  updateSearchTypeStatus,
} from '../../../redux/ducks/searchDucks'
import { searchContentTypes } from '../../../constants/search'
import { changeAdditionalData, getSuggestedUsersById } from '../../../redux/ducks/exploreDucks'

export const Notifications = ({ onCloseNotifications }) => {
  const { labelStrings } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { notifications } = useSelector(userCredsSelector)
  const { notificationArray } = notifications || {}

  const onOpenNotifications = link => {
    if (link) {
      cancelAllFetches()
      // @ts-ignore
      let sublink = link.replace('https://app.infludata.com', '')
      if (link.includes('explore')) {
        const lookalikeId = link.split('/').pop()
        // @ts-ignore
        dispatch([
          resetSearchResults(),
          updateSearchTypeStatus(searchContentTypes.CREATOR),
          changeSuggestionsData({}),
          changeAdditionalData({}),
          history.replace(`/search?lookalikeId=${lookalikeId}&action=lookalike`),
          getSuggestedUsersById({ queryId: lookalikeId }),
        ])
      } else {
        // @ts-ignore
        dispatch([
          changeAdditionalData({}),
          history.replace(sublink),
        ])
  
      }
      onCloseNotifications()
    }
  }

  const topicLabels = notification => {
    const topicMapping = {
      'ADD USER': labelStrings.notificationTopic.addUser,
      AUDIENCE: labelStrings.notificationTopic.audience,
      'LOOKALIKE DONE': labelStrings.notificationTopic.lookalikeDone,
      'NEW OVERLAP CREATED': labelStrings.notificationTopic.newOverlapCreated,
      TAGGED: labelStrings.notificationTopic.tagged,
      'NEW CAMPAIGNS CONTENT': labelStrings.notificationTopic.newCampaignsContent,
      SUGGESTIONS: labelStrings.notificationTopic.suggestions,
    }

    const labelFunction = topicMapping[notification.topic]
    return labelFunction ? labelFunction(notification) : 'Unknown Notification'
  }

  const getTopicIcon = topic => {
    const iconMapping = {
      'ADD USER': 'mdi:account-plus', // User addition
      AUDIENCE: 'mdi:account-group', // Audience or group
      'LOOKALIKE DONE': 'mingcute:ai-fill', // Lookalike completion
      'NEW OVERLAP CREATED': 'mdi:layers-plus', // Overlap creation
      TAGGED: 'mdi:tag', // Tagging
      'NEW CAMPAIGNS CONTENT': 'mdi:folder-open', // New campaign content
      SUGGESTIONS: 'mdi:lightbulb-outline', // Suggestions or ideas
    }

    return iconMapping[topic] || 'mdi:alert-circle-outline' // Default icon for unknown topics
  }

  return (
    <Scrollbar sx={{ maxHeight: '350px', width: '440px', p: 1.5 }}>
      <List disablePadding sx={{ width: '100%' }}>
        {notificationArray?.length ? (
          notificationArray.map(notification => {
            const isHighlighted = notification.isHighlighted
            return (
              <ListItemButton
                key={notification?._id}
                disabled={false}
                onClick={() => onOpenNotifications(notification.link)}
                sx={{
                  borderRadius: '10px',
                  px: 1,
                  backgroundColor: isHighlighted ? '#F4E8E8' : '#fff',
                  '&:hover': {
                    backgroundColor: '#FFF5F5',
                    '& hr': {
                      border: '1px solid transparent !important',
                    },
                  },
                  mt: '6px',
                  '&:last-child': { '& hr': { border: 'unset !important' } },
                  '&:first-of-type': { mt: 0 },
                }}
              >
                <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {isHighlighted ? (
                      <CircleRoundedIcon sx={{ fontSize: '8px', color: '#ff2a00' }} />
                    ) : (
                      <Box sx={{ width: '8px' }}></Box>
                    )}
                    <Iconify
                      icon={getTopicIcon(notification.topic)} // Dynamically select the icon
                      sx={{ color: '#442424', fontSize: '18px' }}
                    />
                  </Stack>
                  <Stack sx={{ px: 2, flexGrow: 1, textAlign: 'left' }}>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontFamily: 'Inter',
                        color: '#442424',
                      }}
                      dangerouslySetInnerHTML={{
                        __html: topicLabels(notification),
                      }}
                    />
                  </Stack>
                  <Stack sx={{ minWidth: '75px' }}>
                    <Typography
                      sx={{
                        fontFamily: 'Inter',
                        color: '#C79C9C',
                        fontSize: '11px',
                      }}
                    >
                      {fToNow(notification.notified || notification.created, true)}
                    </Typography>
                  </Stack>
                </Stack>
              </ListItemButton>
            )
          })
        ) : (
          <ListItemButton
            disabled
            sx={{
              borderRadius: '10px',
              px: 1,
              backgroundColor: '#F9F9F9',
              '&:hover': {
                backgroundColor: '#F9F9F9',
              },
              mt: '6px',
              color: '#442424',
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  textAlign: 'center',
                }}
              >
                {labelStrings.notificationTopic.emptyNotificationList}
              </Typography>
            </Stack>
          </ListItemButton>
        )}
      </List>
    </Scrollbar>
  )
}
