import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../../localization/useTranslation'
import { useSelector } from 'react-redux'
import { selectedProfileSelector } from '../../../redux/selectors'
import { USER_INDEXES } from '../../../constants/appSettings'

const NoDataContainer = styled.div`
  width: 100%;
  height: ${props => props.isSmall ? 'auto' : '100%'};
  ${props => !props.isSmall && 'min-height: 175px'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const NoDataText = styled.div`
  font-family: ${props => props.theme.fontFamilyInter};
  ${props =>
    props.isForPdf
      ? `color: ${props.theme.primaryColor};`
      : props.theme.getTextGradientStyles({ platform: props.platform })}
  font-size: 15px;
  font-weight: bold;
  text-align: center;
`

const NoDataMessage = ({ message = '', isForPdf = false, tabSelect = '', isSmall = false }) => {
  const { labelStrings } = useTranslation()
  const { profile } = useSelector(selectedProfileSelector)

  return (
    <NoDataContainer isSmall={isSmall}>
      <NoDataText
        platform={
          profile
            ? profile.platform
            : tabSelect === 'instagram'
            ? USER_INDEXES.instagram
            : USER_INDEXES.tiktok
        }
        isForPdf={isForPdf}
      >
        {message || labelStrings.noDataForQualityRating}
      </NoDataText>
    </NoDataContainer>
  )
}

NoDataMessage.propTypes = {
  message: PropTypes.string,
  isForPdf: PropTypes.bool,
  tabSelect: PropTypes.string,
}

export default NoDataMessage
