import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Stack,
  Box,
  TextField,
  IconButton,
  Button,
  InputAdornment,
  Chip,
  Paper,
  Tooltip,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import useTranslation from '../../../../localization/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import {
  recentSearchArraySelector,
  searchSuggestionsSelector,
  selectedPlatformSelector,
  suggestedCreatorsArraySelector,
  userCredsSelector,
} from '../../../../redux/selectors'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../constants/appSettings'
import {
  changeSuggestionsData,
  getSearchBySmartText,
  resetSearchResults,
  updateSearch,
  updateSearchTypeStatus,
  uploadFileGetUrlForSimilarContent,
} from '../../../../redux/ducks/searchDucks'
import { searchContentTypes } from '../../../../constants/search'
import { LookalikeHistoryIcon, NavbarLookaLikeIcon, SuggestionContentIcon } from './icons'
import { toast } from 'react-toastify'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import { RecentSearch } from './RecentSearch'
import Scrollbar from '../../../components/scrollbar/Scrollbar'
import { Suggestions } from './Suggestions'
import {
  buildLookalike,
  changeAdditionalData,
  deleteLookalikeList,
  getLookalikeLists,
  getSuggestedUsersById,
} from '../../../../redux/ducks/exploreDucks'
import { LookalikeAiModal } from '../../Explore/LookalikeAi/LookalikeAiModal'
import { cancelAllFetches } from '../../../components/search-sidebar/searchSidebar/searchbarTipsFetches'
import urlQueryService from '../../../../services/urlQueryService'
import { defaultSearchQuery } from '../utils'
import { markNotificationsAsSeen } from '../../../../redux/ducks/startupActions'
import { useLocation } from 'react-router-dom'

const gradientColors = {
  [SOCIAL_PLATFORMS_NAMES.instagram.name]: {
    start: '#FF6B00',
    end: '#FF3B99',
    static: '#FF5500',
  },
  [SOCIAL_PLATFORMS_NAMES.tiktok.name]: {
    start: '#00F2EA',
    end: '#02BDC9',
    static: '#4ad1db',
  },
  [SOCIAL_PLATFORMS_NAMES.youtube.name]: {
    start: '#EA3323',
    end: '#bd1a2e',
    static: '#EA3323',
  },
}

const _RECENT_TYPE = 'RECENT_TYPE'
const _SUGGESTIONS_TYPE = 'SUGGESTIONS_TYPE'

export const SearchSmartBar = ({
  inputValue,
  setInputValue,
  onGetSearchBySmartText,
  searchType,
  dataSuggestions,
  clearSearchMain,
  openModalLookalikeAi,
  setOpenModalLookalikeAi,
  currentQuery,
  tempBrandMentionsData,
  lookalikeId,
}) => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = useLocation()

  const params = new URLSearchParams(search)
  const isActionLookalike = params.get('action') === 'lookalike'
  const isLookalikeId = params.get('lookalikeId')

  const suggestedCreatorsArray = useSelector(suggestedCreatorsArraySelector)
  const recentSearchArray = useSelector(recentSearchArraySelector)
  const platform = useSelector(selectedPlatformSelector)
  const { notifications } = useSelector(userCredsSelector)
  const { showFlashingLookalikeIcon } = notifications || {}

  const inputRef = useRef()
  const fileInputRef = useRef(null)
  const recentSearchRef = useRef(null)

  const [dataFromLookalike, setDataFromLookalike] = useState({
    userList: [],
    platform: '',
  })
  const [isShowItemsList, setIsShowItemsList] = useState({
    show: false,
    type: '',
  })
  const [openLookalikeModalFromSuggestions, setOpenLookalikeModalFromSuggestions] = useState(false)

  useOutsideClick({
    ref: recentSearchRef,
    callback: () => {
      setIsShowItemsList({
        show: false,
        type: '',
      }),
        setOpenLookalikeModalFromSuggestions(false)
    },
  })

  const toInputFocus = () => {
    inputRef?.current?.focus(), onGetSearchBySmartText()
  }

  const onChangeInputValue = e => {
    const value = e.target.value
    setInputValue(value)
  }

  const getInitialSearchBySmartText = () => {
    if (searchType !== searchContentTypes.CREATOR) return
    dispatch([
      getSearchBySmartText({
        inputParam: null,
        platform: null,
        method: 'init',
        withoutSearchLoading: true,
      }),
      changeSuggestionsData({}),
    ])
  }

  const clearSearch = () => {
    setInputValue('')
    getInitialSearchBySmartText()
    clearSearchMain()
  }

  useEffect(() => {
    if (!dataSuggestions?.length) {
      getInitialSearchBySmartText()
    }
  }, [])

  const placeholderLabels = {
    [searchContentTypes.CREATOR]: labelStrings.searchSuggestions.suggestionsPlaceholder,
    [searchContentTypes.BRAND]: labelStrings.searchSuggestions.suggestionsBrandPlaceholder,
    [searchContentTypes.CONTENT]: labelStrings.searchSuggestions.suggestionsContentPlaceholder,
  }

  const onRecentSearch = () => {
    setIsShowItemsList(prev => ({
      show: prev.type === _RECENT_TYPE ? !prev.show : true,
      type: prev.type === _RECENT_TYPE ? '' : _RECENT_TYPE,
    }))
  }

  const onSuggestions = () => {
    dispatch([getLookalikeLists(), markNotificationsAsSeen({ location: 'lookalikes_tab' })])
    setIsShowItemsList(prev => ({
      show: prev.type === _SUGGESTIONS_TYPE ? !prev.show : true,
      type: prev.type === _SUGGESTIONS_TYPE ? '' : _SUGGESTIONS_TYPE,
    }))
    setOpenLookalikeModalFromSuggestions(true)
  }

  const onUploadFileGetUrlForSimilarContent = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = async event => {
    const file = event.target.files?.[0]

    // Validate file type
    const allowedFileTypes = ['image/jpeg', 'image/png']
    if (!allowedFileTypes?.includes(file?.type)) {
      toast.error(`Only JPG, JPEG, and PNG files are allowed.`, {
        theme: 'colored',
      })
      return
    }

    // Validate file size (5 MB = 5 * 1024 * 1024 bytes)
    const maxSizeInBytes = 5 * 1024 * 1024
    if (file.size > maxSizeInBytes) {
      toast.error(`File size must not exceed 5 MB.`, { theme: 'colored' })
      return
    }

    if (!file) return
    dispatch(uploadFileGetUrlForSimilarContent({ file, history }))
    event.target.value = null
  }

  const onApplySearch = data => {
    const { query, formattedString } = data || {}
    setInputValue(formattedString)
    setIsShowItemsList({
      show: false,
      type: '',
    })
    dispatch(
      updateSearch({
        newValuesObj: {
          ...query,
        },
      })
    )
  }

  const onHandleConfirmLookalike = ({ label }) => {
    dispatch(
      // @ts-ignore
      buildLookalike({
        ...dataFromLookalike,
        label,
        userList: [...new Set(dataFromLookalike.userList)],
      })
    )
    setOpenLookalikeModalFromSuggestions(false)
  }

  const onOpenModalLookalikeAi = () => {
    setOpenModalLookalikeAi(true)
    setIsShowItemsList({
      show: false,
      type: '',
    })
  }

  const onOpenSuggestions = data => {
    setInputValue('')
    cancelAllFetches()
    // @ts-ignore
    dispatch([
      updateSearchTypeStatus(searchContentTypes.CREATOR),
      resetSearchResults(),
      changeSuggestionsData({}),
      changeAdditionalData(data),
      getSuggestedUsersById({ queryId: data?.id }),
    ])
    localStorage?.removeItem('searchQuery')
    urlQueryService.setNewSearchQuery({
      latestQuery: defaultSearchQuery,
    })
    setIsShowItemsList({
      show: false,
      type: '',
    })
    history.replace(`/search?action=lookalike&lookalikeId=${data?.id}`)
  }

  const onDeleteSuggestionList = (e, data) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(deleteLookalikeList({ id: data?.id }))
    setIsShowItemsList({
      show: false,
      type: '',
    })

    if (data.id === lookalikeId) {
      clearSearch()
    }
  }

  const onCloseModalLookalikeAi = () => {
    setOpenModalLookalikeAi(false)
    setOpenLookalikeModalFromSuggestions(false)
  }

  const renderBottomInputList = {
    [_RECENT_TYPE]: <RecentSearch data={recentSearchArray} onApplySearch={onApplySearch} />,
    [_SUGGESTIONS_TYPE]: (
      <Suggestions
        data={suggestedCreatorsArray}
        platform={platform}
        onOpenModalLookalikeAi={onOpenModalLookalikeAi}
        onOpenSuggestions={onOpenSuggestions}
        onDeleteSuggestionList={onDeleteSuggestionList}
      />
    ),
  }

  useEffect(() => {
    if (isActionLookalike && isLookalikeId) {
      onOpenSuggestions({ id: isLookalikeId })
    }
  }, [isActionLookalike, isLookalikeId])

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ pt: '10px' }}>
        <Box ref={recentSearchRef} sx={{ maxWidth: '809px', width: '100%', position: 'relative' }}>
          <TextField
            disabled={false}
            inputRef={inputRef}
            size="small"
            variant="outlined"
            value={inputValue}
            onChange={onChangeInputValue}
            placeholder={placeholderLabels[searchType]}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                onGetSearchBySmartText()
              }
            }}
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    disabled={false}
                    sx={{ width: '32px', height: '32px', color: '#6B4545' }}
                    onClick={toInputFocus}
                  >
                    <SearchIcon
                      sx={{
                        fill: gradientColors[platform].static,
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Stack direction="row" alignItems="center" spacing={0}>
                    <Tooltip title={labelStrings.searchSuggestions.recentSearchTooltip}>
                      <div>
                        <IconButton
                          disabled={!recentSearchArray?.length}
                          disableRipple
                          sx={{
                            width: '32px',
                            height: '32px',
                            '&:hover': { opacity: 0.7 },
                            ...(isShowItemsList.type === _RECENT_TYPE && {
                              background:
                                'linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)',
                              '& svg': {
                                fill: '#fff',
                                '& path': {
                                  fill: '#fff',
                                },
                              },
                            }),
                            borderRadius: '9px',
                          }}
                          onClick={onRecentSearch}
                        >
                          <LookalikeHistoryIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title={labelStrings.searchSuggestions.lookalikeTooltip}>
                      <div>
                        <IconButton
                          disabled={false}
                          disableRipple
                          sx={{
                            width: '38px',
                            height: '38px',
                            borderRadius: '9px',
                            background:
                              showFlashingLookalikeIcon ||
                              isShowItemsList.type === _SUGGESTIONS_TYPE
                                ? 'linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)'
                                : undefined,
                            animation: showFlashingLookalikeIcon
                              ? 'flashing 2s infinite' // Add animation
                              : undefined,
                            '& svg': {
                              fill:
                                showFlashingLookalikeIcon ||
                                isShowItemsList.type === _SUGGESTIONS_TYPE
                                  ? '#fff'
                                  : undefined,
                              '& path': {
                                fill:
                                  showFlashingLookalikeIcon ||
                                  isShowItemsList.type === _SUGGESTIONS_TYPE
                                    ? '#fff'
                                    : undefined,
                              },
                            },
                            '&:hover': { opacity: 0.7 },
                          }}
                          onClick={onSuggestions}
                        >
                          <NavbarLookaLikeIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <>
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />
                      <Tooltip title={labelStrings.searchSuggestions.uploadTooltip}>
                        <div>
                          <IconButton
                            disabled={false}
                            disableRipple
                            sx={{
                              width: '35px',
                              height: '35px',
                              '&:hover': { opacity: 0.7 },
                            }}
                            onClick={onUploadFileGetUrlForSimilarContent}
                          >
                            <SuggestionContentIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </>
                  </Stack>
                </InputAdornment>
              ),
            }}
            sx={{
              width: '100%',
              pointerEvents: 'auto',
              '.MuiInputBase-root': {
                borderRadius: '10px',
                height: '46px',
                color: gradientColors[platform].static,
                backgroundColor: 'white',
                fontSize: '16px',
                fontWeight: 600,
                fontFamily: `Inter, sans-serif`,
              },
              '& .MuiOutlinedInput-root': {
                outline: 'unset',
                '& fieldset': {
                  borderColor: gradientColors[platform].static,
                  borderRadius: '10px',
                },
                '&:hover fieldset': {
                  borderWidth: '2px',
                  borderColor: gradientColors[platform].static,
                  borderRadius: '10px',
                },
                '&.Mui-focused fieldset': {
                  borderWidth: '2px',
                  borderColor: gradientColors[platform].static,
                  borderRadius: '10px',
                },
              },
              '& input::placeholder': {
                fontSize: '16px',
                fontWeight: 400,
                fontFamily: `Inter, sans-serif`,
                color: '#BC9797',
              },
            }}
          />
          <Chip
            size="small"
            label={labelStrings.searchSuggestions.promptsByChatGPT}
            sx={{
              position: 'absolute',
              top: '-10px',
              left: '15px',
              fontSize: '9px',
              fontFamily: `Inter, sans-serif`,
              color: '#442424',
              height: '18px',
              fontWeight: 700,
              backgroundColor: '#F6DBDB',
            }}
          />
          {isShowItemsList.show && (
            <Paper
              elevation={3}
              sx={{
                position: 'absolute',
                top: '52px',
                left: 0,
                backgroundColor: 'white',
                width: '100%',
                height: 'auto',
                maxHeight: '350px',
                zIndex: 999,
                borderRadius: '10px',
              }}
            >
              <Scrollbar sx={{ maxHeight: '350px', p: 1 }}>
                {renderBottomInputList[isShowItemsList.type]}
              </Scrollbar>
            </Paper>
          )}
        </Box>
        <Button
          sx={{
            height: '46px',
            borderRadius: '10px',
            borderWidth: '1px',
            borderColor: gradientColors[platform].static,
            color: gradientColors[platform].static,
            backgroundColor: 'white',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 600,
            fontFamily: `Inter, sans-serif`,
            '&:hover': {
              borderWidth: '1px',
            },
          }}
          disabled={false}
          variant="outlined"
          onClick={clearSearch}
        >
          {labelStrings.searchSuggestions.resetSearch}
        </Button>
      </Stack>
      <LookalikeAiModal
        openModalLookalikeAi={openModalLookalikeAi}
        onCloseModalLookalikeAi={onCloseModalLookalikeAi}
        onHandleConfirmLookalike={onHandleConfirmLookalike}
        setDataFromLookalike={setDataFromLookalike}
        currentQuery={currentQuery}
        openLookalikeModalFromSuggestions={openLookalikeModalFromSuggestions}
        tempBrandMentionsData={tempBrandMentionsData}
      />
    </>
  )
}
