// @ts-nocheck
import React, { Fragment, useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Divider, Box, Stack, Typography } from "@mui/material"
import { TopControls } from "../../sections/dashboard/TopControls"
import { Overview } from "../../sections/dashboard/cards/Overview"
// import { CpmCpe } from '../../sections/dashboard/cards/CpmCpe'
import { SentimentGoals } from "../../sections/dashboard/SentimentGoals"
import { LatestPublishedContentAndReach } from "../../sections/dashboard/LatestPublishedContentAndReach"
import { ContentCharts } from "../../sections/dashboard/ContentCharts"
import { EditDashboardFields } from "../../sections/dashboard/EditDashboardFields"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import {
  changeDataDashboard,
  changeErrorDashboard,
  editDashboard,
  getCampaignDashboardData,
  removeDemoCampaign,
} from "../../../redux/ducks/campaignsDucks"
import {
  campaignDashboard,
  currentOpenedCampaignSelector,
  getAllCampaignsSelector,
  userGrantSelector,
} from "../../../redux/selectors"
import {
  filteredData,
  getAlwaysVisibleDashboardFields,
  getCurrentDashboardFields,
  processDashboardData,
} from "../../sections/dashboard/utils"
import { AttentionModal } from "../../sections/dashboard/AttentionModal"
import { WrongCampaign } from "../../sections/dashboard/WrongCampaing"
import { NAVBAR_FOOTER_SUM_HEIGHT } from "../../../constants/appSettings"
import useTranslation from "../../../localization/useTranslation"
import InactiveCampaignImg from "../../../images/inactiveCampaign.png"
import { DemoCampaignModal } from "../../sections/dashboard/DemoCampaignModal"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { TableViewGraph } from "../../sections/dashboard/TableViewGraph/TableViewGraph"
import { BitlyTrackingTable } from "../../sections/dashboard/BitlyTrackingTable/TableViewGraph"

export const Dashboard = ({ isPreview }) => {
  const { search } = useLocation()
  const history = useHistory()
  const { labelStrings } = useTranslation()

  const params = new URLSearchParams(search)
  const dashboardIdFromQueryURL = params.get("dashboardId")

  const dispatch = useDispatch()

  const { campaigns_bitly_fields, campaigns_audience_analytics_bitly } =
    useSelector(userGrantSelector, shallowEqual)

  const {
    data: dashboardStartupData,
    currentDashboardId,
    error: dashboardError,
    isLoading: isLoadingDashboard,
    isEmpty,
  } = useSelector(campaignDashboard)
  const currentCampaign = useSelector(currentOpenedCampaignSelector)
  const {
    campaignId,
    dashboards,
    creators,
    settings,
    isNotTracking,
    startingDate,
    isDemoCampaign,
  } = currentCampaign
  const campaignsList = useSelector(getAllCampaignsSelector)

  const [openAttentionModal, setOpenAttentionModal] = useState(false)

  const [isOpenDemoCampaignModal, setIsOpenDemoCampaignModal] = useState(false)
  const [isNotOpenedDemoCampaign] = useLocalStorage("demo-campaign-modal")

  const [openWrongCampaignModal, setOpenWrongCampaignModal] = useState(false)
  const [errorSelectedCampaign, setErrorSelectedCampaign] = useState("")

  const [openEditDashboardModal, setOpenEditDashboardModal] = useState(false)
  const [dashboardSelectValue, setDashboardSelectValue] = useState("")
  const [creatorsSelectValue, setCreatorsSelectValue] = useState([])
  const [datesValue, setDatesValue] = useState([null, null])

  const filterCurrentDashboard = filteredData(dashboards, dashboardSelectValue)
  const editDashboardFields = filteredData(dashboards, currentDashboardId)
  const reducedDashboardData = processDashboardData(dashboardStartupData)
  const dashboardCurrentFieldsData =
    getCurrentDashboardFields(dashboardStartupData)
  const dashboardAlwaysVisibleFieldsData = getAlwaysVisibleDashboardFields(
    editDashboardFields?.visibleGraphElements,
  )

  const bitlyTrackingTablesArray = dashboardStartupData?.filter((el) =>
    el.graphId.startsWith("bitly_tracking_table_"),
  )

  useEffect(() => {
    setDatesValue([null, null])
    changeDataDashboard([])
  }, [campaignId])

  useEffect(() => {
    if (!dashboardStartupData?.length && dashboards?.length && campaignId) {
      dispatch(
        getCampaignDashboardData({
          campaignId,
          dashboardId: dashboards[0]?.dashboardId
            ? dashboards[0]?.dashboardId
            : dashboardIdFromQueryURL,
        }),
      )
    }
  }, [
    campaignId,
    dashboardStartupData?.length,
    dashboards?.length,
    dashboardIdFromQueryURL,
  ])

  useEffect(() => {
    if (isNotTracking && dashboardStartupData?.length) {
      setOpenAttentionModal(true)
    }

    if (
      isDemoCampaign &&
      dashboardStartupData?.length &&
      !isNotOpenedDemoCampaign
    ) {
      setIsOpenDemoCampaignModal(true)
    }
  }, [
    isNotTracking,
    isDemoCampaign,
    dashboardStartupData?.length,
    isNotOpenedDemoCampaign,
  ])

  const onEditDashboard = (payload) => {
    dispatch([
      changeDataDashboard([]),
      editDashboard({
        ...payload,
        selectedCreators:
          creatorsSelectValue?.length === creators?.length
            ? []
            : creatorsSelectValue,
        startingDate: datesValue[0] || "",
        endingDate: datesValue[1] || "",
        dashboardId: dashboardSelectValue,
        campaignId,
        withRefetch: payload?.withRefetch ? payload.withRefetch : false,
        method: "change",
        history,
      }),
    ])
  }

  const onChangeSelectedDates = (payload) => {
    dispatch([
      changeDataDashboard([]),
      editDashboard({
        ...payload,
        selectedCreators:
          creatorsSelectValue?.length === creators?.length
            ? []
            : creatorsSelectValue,
        startingDate:
          payload?.startingDate === null ? "" : payload.startingDate,
        endingDate: payload?.endingDate === null ? "" : payload.endingDate,
        dashboardId: dashboardSelectValue,
        campaignId,
        withRefetch: payload?.withRefetch ? payload.withRefetch : false,
        method: "change",
        history,
      }),
    ])
  }

  const onChangeDashboard = ({ dashboardId }) => {
    setDatesValue([null, null])
    setCreatorsSelectValue([])
    if (startingDate) {
      history.replace(
        `/campaigns/${campaignId}/dashboard?dashboardId=${dashboardId}`,
      )
      dispatch([
        changeDataDashboard([]),
        getCampaignDashboardData({
          campaignId,
          dashboardId,
        }),
      ])
    }
  }

  useEffect(() => {
    if (dashboardError === "Bad Request" && dashboards?.length) {
      history.replace(
        `/campaigns/${campaignId}/dashboard?dashboardId=${dashboards[0]?.dashboardId}`,
      )
      onChangeDashboard({ dashboardId: dashboards[0]?.dashboardId })
    }

    if (dashboardError?.message === "Campaign not found") {
      setOpenWrongCampaignModal(true)
    }
  }, [dashboardError, dashboards?.length])

  const handleChangeCampaign = ({ target }) => {
    setErrorSelectedCampaign(target.value)
    history.replace(`/campaigns/${target.value}`)
    dispatch(changeErrorDashboard(null))
    location.reload()
  }

  const onHandleCloseWrongCampaignModal = () => {
    setOpenWrongCampaignModal(false)
    history.replace(`/campaigns`)
    dispatch(changeErrorDashboard(null))
    location.reload()
  }

  const onHandleCloseDemoCampaignModal = () => {
    setIsOpenDemoCampaignModal(false)
    window.localStorage.setItem("demo-campaign-modal", true)
  }

  const onRemoveDemoCampaigns = () => {
    onHandleCloseDemoCampaignModal()
    dispatch(removeDemoCampaign({ history }))
  }

  return (
    <>
      {isEmpty.isEmptyCampaign ? (
        <Stack
          sx={{ width: "100%", height: "500px" }}
          justifyContent='center'
          alignItems='center'
          spacing={1}
        >
          <Typography
            textAlign='center'
            sx={{ fontSize: "26px", fontWeight: 500 }}
          >
            {labelStrings.yourCampaignIsNowSetUp} 🎉
          </Typography>
          <Stack>
            <Typography textAlign='center'>
              {labelStrings.weAreAlreadyStarted}
            </Typography>
            <Typography textAlign='center'>
              {labelStrings.yourDashboardWillAppearHere}
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <Box
          sx={{
            p: 3,
            height: !startingDate
              ? `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)`
              : "100%",
          }}
        >
          {!startingDate &&
          !isLoadingDashboard &&
          !dashboardStartupData?.length ? (
            <Stack
              justifyContent='center'
              alignItems='center'
              spacing={2}
              sx={{ width: "100%", height: "100%" }}
            >
              <Box sx={{ width: "200px", height: "200px" }}>
                <img src={InactiveCampaignImg} alt='' />
              </Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  whiteSpace: "pre-wrap",
                  textAlign: "center",
                }}
              >
                {labelStrings.inactiveCampaignText}
              </Typography>
            </Stack>
          ) : (
            !isPreview && (
              <TopControls
                openEditModal={openEditDashboardModal}
                setOpenEditModal={setOpenEditDashboardModal}
                dashboardsData={dashboards}
                currentDashboard={filterCurrentDashboard}
                creatorsData={creators}
                editDashboard={onEditDashboard}
                dashboardIdFromQueryURL={dashboardIdFromQueryURL}
                campaignId={campaignId}
                //
                dashboardSelectValue={dashboardSelectValue}
                setDashboardSelectValue={setDashboardSelectValue}
                creatorsSelectValue={creatorsSelectValue}
                setCreatorsSelectValue={setCreatorsSelectValue}
                datesValue={datesValue}
                setDatesValue={setDatesValue}
                //
                onChangeDashboard={onChangeDashboard}
                currentCampaign={currentCampaign}
                onChangeSelectedDates={onChangeSelectedDates}
              />
            )
          )}

          <Overview
            countsWidget={reducedDashboardData?.count_widgets}
            customTracking={reducedDashboardData?.custom_tracking}
          />

          {reducedDashboardData?.main_metrics_table?.graphId && (
            <TableViewGraph data={reducedDashboardData.main_metrics_table} />
          )}

          {!!bitlyTrackingTablesArray?.length &&
            campaigns_bitly_fields >= 1 &&
            bitlyTrackingTablesArray?.map((bitlyTable) => (
              <Fragment key={bitlyTable?.graphId}>
                <BitlyTrackingTable
                  data={bitlyTable}
                  isShowOnlyOverview={!campaigns_audience_analytics_bitly}
                />
              </Fragment>
            ))}

          {/*<CpmCpe
          cpmOrganicData={reducedDashboardData?.cpm_organic}
          cpeOrganicData={reducedDashboardData?.cpe_organic}
          emvData={reducedDashboardData?.emv}
        />

        {!!dashboardStartupData?.length && <Divider sx={{ borderStyle: 'dashed', py: 2 }} />}
        */}

          <SentimentGoals
            publishedContentChartData={
              reducedDashboardData.published_content_reach_linechart
            }
            latestCommentsWithBrandData={
              reducedDashboardData?.latest_comments_with_brand
            }
            mostLikedCommentsData={reducedDashboardData?.most_liked_comments}
            sentimentBrandFocusGraph={
              reducedDashboardData?.semantic_graph_brandfocus
            }
            sentimentControversialGraph={
              reducedDashboardData?.semantic_graph_controversial
            }
            sentimentPositivityGraph={
              reducedDashboardData?.semantic_graph_positivity
            }
            goalsData={reducedDashboardData?.goal_reached}
            latestContentWithSummariesData={
              reducedDashboardData?.latest_comment_summaries_with_brand
            }
          />

          {!!dashboardStartupData?.length && (
            <Divider sx={{ borderStyle: "dashed" }} />
          )}

          {reducedDashboardData?.published_content_elements?.graphId && (
            <LatestPublishedContentAndReach
              title='Latest Published Content & Reach'
              subheader={`${reducedDashboardData?.published_content_elements?.value?.publishedCount} content pieces in the ${reducedDashboardData?.published_content_elements?.value?.textLabel}`}
              data={reducedDashboardData?.published_content_elements?.value}
              currency={settings?.campaignCurrency || "EUR"}
            />
          )}

          {!!dashboardStartupData?.length && (
            <Divider sx={{ borderStyle: "dashed" }} />
          )}

          <ContentCharts
            reachedChartData={reducedDashboardData?.reached_audience}
            mostSuccessfulCreatorsByReachData={
              reducedDashboardData?.most_successful_creators_reach_stacked_bar
            }
            publishedByTimeAndFormatData={
              reducedDashboardData?.published_content_time_stacked_bar
            }
            mostUsedKeywordBarData={
              reducedDashboardData?.most_used_keywords_bar
            }
            uploadedContentNewsData={
              reducedDashboardData?.uploaded_content_news
            }
            contentByReachBucketData={
              reducedDashboardData?.content_by_reach_bucket_graph
            }
            geolocationMapData={reducedDashboardData?.geolocation_map}
          />

          <EditDashboardFields
            dashboardCurrentFieldsData={dashboardCurrentFieldsData}
            dashboardAlwaysVisibleFieldsData={dashboardAlwaysVisibleFieldsData}
            openEditModal={openEditDashboardModal}
            setOpenEditModal={setOpenEditDashboardModal}
            onEditDashboard={onEditDashboard}
          />
        </Box>
      )}
      <AttentionModal
        isOpenModal={openAttentionModal}
        closeModal={() => setOpenAttentionModal(false)}
        campaignId={campaignId}
      />
      <WrongCampaign
        isOpenModal={openWrongCampaignModal}
        closeModal={onHandleCloseWrongCampaignModal}
        campaignsList={campaignsList}
        handleChangeCampaign={handleChangeCampaign}
        errorSelectedCampaign={errorSelectedCampaign}
      />
      <DemoCampaignModal
        isOpenModal={isOpenDemoCampaignModal}
        closeModal={() => setIsOpenDemoCampaignModal(false)}
        onHandleCloseDemoCampaignModal={onHandleCloseDemoCampaignModal}
        onRemoveDemoCampaigns={onRemoveDemoCampaigns}
      />
    </>
  )
}
