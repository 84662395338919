import { useDispatch, useSelector } from 'react-redux'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import useTranslation from '../../../../localization/useTranslation'
import AudienceReportsTokensStat from '../../../../components/tokens/stats/AudienceReportsTokensStat'
import CampaignsSeatsTokensStat from '../../../../components/tokens/stats/CampaignsSeatsTokensStat'
import { NavbarProfileIcon } from '../navbarIcons'
import { userCredsSelector, userDetailsSelector } from '../../../../redux/selectors'
import {
  Button,
  Box,
  Divider,
  Stack,
  Typography,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from '@mui/material'
import PopupState, { bindPopover, bindHover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import {
  changeSelectedOrganization,
  switchOrganizationalAccount,
} from '../../../../redux/ducks/userDucks'

const ProfileNavMenu = ({
  onLogoutClick,
  goToSettings,
  showTokensStats,
  showCampaignsTrackStats,
  isTrial,
}) => {
  const { labelStrings } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()

  const { profile_data } = useSelector(userDetailsSelector) || {}
  const { organizations } = useSelector(userCredsSelector) || {}
  const { dataForProfileSelect, selectedOrganization } = organizations || {}

  const isActive = location?.pathname?.includes('settings')

  const [isHovered, setIsHovered] = useState(false)
  const isActiveProfileIcon = isHovered || isActive


  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const onChangeOrganization = ({ target: { value } }) => {
    const { orgId } = value || {}
    // @ts-ignore
    dispatch([
      changeSelectedOrganization(value),
      switchOrganizationalAccount({
        orgId,
      }),
    ])
  }

  return (
    <Box
      sx={{
        width: '100%',
        fontFamily: 'Inter',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <PopupState variant="popover" popupId="profile-popup-menu">
        {popupState => (
          <Box>
            <Button
              sx={{
                minWidth: '40px',
                '&:hover': { backgroundColor: 'transparent' },
              }}
              {...bindHover(popupState)}
              onClick={goToSettings}
            >
              <NavbarProfileIcon is_active={`${isActiveProfileIcon}`} />
            </Button>
            <HoverPopover
              {...bindPopover(popupState)}
              // open={true}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              slotProps={{
                paper: {
                  sx: {
                    backgroundColor: 'transparent !important',
                    borderRadius: '16px !important',
                    fontFamily: 'Inter !important',
                  },
                },
              }}
            >
              <Box
                sx={{
                  border: `1px solid #e3c5c5`,
                  borderRadius: `16px`,
                  backgroundColor: `white`,
                  width: '237px',
                }}
              >
                {showTokensStats && (
                  <Box
                    sx={{
                      overflow: 'hidden',
                      position: 'relative',
                      borderTopLeftRadius: '16px',
                      borderTopRightRadius: '16px',
                    }}
                  >
                    <AudienceReportsTokensStat isTrial={isTrial} />
                    {showCampaignsTrackStats && (
                      <>
                        <Divider sx={{ width: '100%', borderColor: '#E3C5C5' }} />
                        <CampaignsSeatsTokensStat />
                      </>
                    )}
                  </Box>
                )}
                <Box>
                  <Divider sx={{ width: '100%', borderColor: '#E3C5C5' }} />
                  <Box sx={{ p: '5px', px: 1, minHeight: '50px' }}>
                    <Button
                      disableRipple
                      variant="text"
                      onClick={goToSettings}
                      sx={{
                        color: '#442424',
                        fontWeight: 700,
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        '&:hover': {
                          backgroundColor: 'unset',
                          textDecoration: 'underline #6B4545',
                        },
                      }}
                    >
                      {labelStrings.editYourAccount}
                    </Button>
                  </Box>
                  <Divider sx={{ width: '100%', borderColor: '#E3C5C5' }} />

                  {!!dataForProfileSelect?.length && (
                    <>
                      <Box
                        sx={{
                          px: 1,
                          minHeight: '56px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FormControl size="small" fullWidth>
                          <InputLabel
                            focused={false}
                            sx={{
                              fontWeight: 'bold',
                              color: '#442424',
                              fontFamily: 'Inter',
                              fontSize: '14px',
                            }}
                            size="small"
                          >
                            {labelStrings.currentOrganization}
                          </InputLabel>
                          <Select
                            size="small"
                            value={selectedOrganization}
                            label={labelStrings.currentOrganization}
                            onChange={onChangeOrganization}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  fontFamily: 'Inter',
                                  maxHeight: '318px',
                                  color: '#442424',
                                  fontSize: '14px',
                                },
                              },
                            }}
                            sx={{
                              minWidth: '120px',
                              color: '#442424',
                              fontWeight: 'bold',
                              background: 'white',
                              '& .MuiSelect-icon': {
                                color: '#442424',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#E3C5C5',
                              },
                              '& .Mui-selected': {
                                backgroundColor: '#FFF1F1',
                                color: '#442424',
                              },
                            }}
                          >
                            {dataForProfileSelect?.map(el => (
                              <MenuItem
                                sx={{
                                  backgroundColor: el.isActive ? '#FFF1F1' : 'inherit',
                                  cursor: el.isActive ? 'not-allowed' : 'pointer',
                                  userSelect: el.isActive ? 'none' : 'auto',
                                  '&:hover': {
                                    backgroundColor: el.isActive ? '#FFF1F1' : 'inherit',
                                  },
                                  maxWidth: '220px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                key={el.orgId}
                                value={el}
                              >
                                {el.orgName}
                              </MenuItem>
                            ))}
                            <Typography
                              textAlign="center"
                              sx={{
                                fontFamily: 'Inter',
                                maxWidth: '220px',
                                fontSize: '12px',
                                color: '#442424',
                                px: 1,
                                pt: 1,
                              }}
                            >
                              {labelStrings.youCanAddNewOrganizations}
                            </Typography>
                          </Select>
                        </FormControl>
                      </Box>
                      <Divider sx={{ width: '100%', borderColor: '#E3C5C5' }} />
                    </>
                  )}

                  <Box sx={{ p: '5px', px: 1, minHeight: '50px' }}>
                    <Button
                      disableRipple
                      variant="text"
                      onClick={onLogoutClick}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'unset',
                          textDecoration: 'underline #6B4545',
                        },
                        '& .MuiTypography-root': {
                          fontSize: '14px',
                          color: '#442424',
                          fontWeight: 700,
                          fontFamily: 'Inter',
                          textTransform: 'capitalize',
                        },
                      }}
                    >
                      <Stack alignItems="start">
                        <Typography noWrap sx={{ maxWidth: '200px' }}>{`${labelStrings.logout} ${
                          profile_data?.email ? `(${profile_data?.email})` : ''
                        }`}</Typography>
                      </Stack>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </HoverPopover>
          </Box>
        )}
      </PopupState>
    </Box>
  )
}

export default ProfileNavMenu
