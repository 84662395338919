import React, { useMemo } from 'react'
import { Box, IconButton, Button, Stack, Typography } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import useTranslation from '../../../localization/useTranslation'
import { useHistory } from 'react-router-dom'
import { daysUntilDate } from '../../../utils'

export const TopMessageBanner = ({
  setIsShowTopBannerMessage,
  setIsCancellationScheduled,
  trialsEndsDays,
  isInTrial,
  isSelfService,
  isNonRenewing,
  subscriptionsEndsDays,
  countProfileAnalytics,
  countSearchRequests,
  nextResetDate,
  isAdmin,
}) => {
  const { labelStrings } = useTranslation()
  const history = useHistory()

  const limitToShow = isInTrial ? 5 : 25

  const goToSettings = () => {
    history.push('/settings')
    localStorage.setItem('hiddenTopMessageBanner', 'true')
    setIsShowTopBannerMessage(false)
  }

  const onRemoveScheduledCancellation = () => {
    setIsCancellationScheduled(true)
    localStorage.setItem('hiddenTopMessageBanner', 'true')
    setIsShowTopBannerMessage(false)
  }

  const onCloseBanner = () => {
    setIsShowTopBannerMessage(false)
  }

  const daysLeft = useMemo(() => {
    return isInTrial ? daysUntilDate(trialsEndsDays) : daysUntilDate(subscriptionsEndsDays)
  }, [isInTrial, trialsEndsDays, subscriptionsEndsDays])

  // @ts-ignore
  const { mainText, buttonText, buttonFunction } = () => {
    const limitToShowTrial = 10 // For trial conditions
    const limitToShowSelfService = 25 // For self-service conditions

    const createReturnObject = (text, buttonText, buttonFunction) => ({
      mainText: text,
      buttonText,
      buttonFunction,
    })

    // Check for trial conditions
    if (isInTrial && isSelfService) {
      if (countProfileAnalytics && countProfileAnalytics < limitToShowTrial) {
        return createReturnObject(
          labelStrings.trialEndsSoonProfileAnalytics(countProfileAnalytics),
          labelStrings.generalClickHereSettings,
          goToSettings
        )
      }
      if (countSearchRequests && countSearchRequests < limitToShowTrial) {
        return createReturnObject(
          labelStrings.trialEndsSoonSearchRequests(countSearchRequests),
          labelStrings.generalClickHereSettings,
          goToSettings
        )
      }
      if (nextResetDate && nextResetDate < 24 * 60 * 60 * 1000) {
        // Check if less than 24 hours
        return createReturnObject(
          labelStrings.trialEndsSoonHours(nextResetDate),
          labelStrings.generalClickHereSettings,
          goToSettings
        )
      }
    }

    // Check for self-service conditions when not in trial
    if (isSelfService && !isInTrial) {
      if (countProfileAnalytics && countProfileAnalytics < limitToShowSelfService) {
        return createReturnObject(
          labelStrings.limitsAlmostReachedProfileAnalytics(countProfileAnalytics),
          labelStrings.generalClickHereSettings,
          goToSettings
        )
      }
      if (countSearchRequests && countSearchRequests < limitToShowSelfService) {
        return createReturnObject(
          labelStrings.limitsAlmostReachedSearchRequests(countSearchRequests),
          labelStrings.generalClickHereSettings,
          goToSettings
        )
      }
    }

    // Existing logic for other messages
    if (
      (countProfileAnalytics || countSearchRequests) &&
      (countProfileAnalytics < limitToShow || countSearchRequests < limitToShow)
    ) {
      const isSearchRequestsLower = countProfileAnalytics > countSearchRequests
      return createReturnObject(
        isSearchRequestsLower
          ? labelStrings.searchRequestsAlmostOut(countSearchRequests)
          : labelStrings.profilesAlmostOut(countProfileAnalytics),
        labelStrings.generalClickHereSettings,
        goToSettings
      )
    }

    if (isNonRenewing) {
      if (isInTrial && isSelfService && daysLeft < 2) {
        return createReturnObject(
          labelStrings.yourTrialsEnds(daysLeft),
          labelStrings.yourTrialsEndsSubtitle,
          goToSettings
        )
      }
      if (!isInTrial && daysLeft < 14) {
        return createReturnObject(
          labelStrings.yourSubscriptionsEnds(daysLeft),
          labelStrings.removeCancellationScheduledButton,
          onRemoveScheduledCancellation
        )
      }
    }

    return {} // Default return if no conditions are met
  }

  if (!buttonText) return null

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '52px',
        color: 'white',
        fontWeight: 700,
        background:
          'linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)',
        p: 1,
        zIndex: 8888,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box />
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Inter', color: 'white' }}
          >
            {mainText}
          </Typography>
          {isAdmin && (
            <Button
              variant="text"
              sx={{
                fontWeight: '500',
                fontFamily: 'Inter',
                color: 'white',
                textDecoration: 'none',
                fontSize: '16px',
                lineHeight: 'inherit',
                '&:hover': {
                  color: 'white',
                  textDecoration: 'underline',
                },
              }}
              onClick={buttonFunction}
              disableTouchRipple
            >
              {buttonText}
            </Button>
          )}
        </Stack>
        <IconButton size="small" onClick={onCloseBanner}>
          <CloseRoundedIcon sx={{ color: 'white' }} />
        </IconButton>
      </Stack>
    </Box>
  )
}
