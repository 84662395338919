// @ts-nocheck
import React from 'react'
import { Button, Stack, Typography } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'

export const ThankYouPage = ({ isTrialCampaigns = false }) => {
  const { labelStrings } = useTranslation()

  const onSetupFirstCampaign = () => {
    window.location.replace('/campaigns')
  }

  return (
    <Stack sx={{ width: '100%', height: '100%' }}>
      <Typography
        sx={{ fontFamily: `Lato, sans-serif`, fontSize: '26px', fontWeight: 600, color: '#1F2937' }}
      >
        {labelStrings.signupLocalization.thankYouForSigningUp}
      </Typography>
      <Stack spacing={3} sx={{ mt: 3, pb: 5 }}>
        <Typography
          sx={{
            fontFamily: `Lato, sans-serif`,
            fontSize: '16px',
            fontWeight: 600,
            color: '#4B5563',
          }}
        >
          {labelStrings.signupLocalization.weAreLookingForward}
        </Typography>

        {!isTrialCampaigns && (
          <>
            <Typography
              sx={{
                fontFamily: `Lato, sans-serif`,
                fontSize: '16px',
                fontWeight: 600,
                color: '#4B5563',
              }}
            >
              {labelStrings.signupLocalization.followingPersonalDemo}
            </Typography>

            {/*<Typography
              sx={{
                fontFamily: `Lato, sans-serif`,
                fontSize: '16px',
                fontWeight: 600,
                color: '#4B5563',
              }}
            >
              {labelStrings.signupLocalization.inTheMeanTimePlease}
            </Typography>*/}

            {/*<Typography
              sx={{
                fontFamily: `Lato, sans-serif`,
                fontSize: '16px',
                fontWeight: 600,
                color: '#4B5563',
              }}
            >
              {labelStrings.signupLocalization.weAlreadyAddedSomeDemoCamp}
            </Typography>*/}
          </>
        )}
      </Stack>

      {/* <Stack
        direction="row"
        justifyContent={isTrialCampaigns ? 'start' : 'center'}
        spacing={3}
        sx={{ mt: 3 }}
      >
        {/*<Button
        <Button
          sx={{
            height: '46px',
            fontSize: '17px',
            lineHeight: '16px',
            fontFamily: `Lato, sans-serif`,
            textDecoration: 'none',
          }}
          variant="outlined"
          size="medium"
          onClick={onSetupFirstCampaign}
        >
          {labelStrings.signupLocalization.setUpMyFirstCampaign}
      </Stack> */}
    </Stack>
  )
}
