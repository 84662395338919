import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  styled,
  Modal,
  Typography,
  Card,
  Fade,
  Backdrop,
  Stack,
} from "@mui/material";
import Scrollbar from "../scrollbar/Scrollbar";

const ContentWrapper = styled(Card)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1158px",
  height: "calc(100vh / 1.25)",
  overflow: "inherit",
});

export const ModalComponent = ({
  isOpenModal = false,
  closeModal,
  children = null,
  title = "",
  contentWidth = "1158px",
  contentHeight = "calc(100vh / 1.25)",
  smallPadding = false,
  smallPaddingContent = false,
  isCenterTitle = false,
  isFixedContentHeight = true,
  withoutScrollWrapper,
  disableEscapeKeyDown,
  maxHeightForScrollbarContent = "",
  customIcon,
  isGradientTitle,
  isGradientBg,
  isFilledTitle
}) => {
  return (
    <div>
      <Modal
        keepMounted
        open={isOpenModal}
        onClose={closeModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 350,
          },
        }}
        disableEscapeKeyDown={disableEscapeKeyDown}
        sx={{ 
          ...(isGradientBg && { 
            "& > .MuiBackdrop-root" : {
              background: `linear-gradient(90deg, rgba(255, 188, 0, 0.8) 0.35%, rgba(255, 93, 90, 0.8) 100%)`,
              backdropFilter: `blur(10px)`
            }
          }),
          outline: 'none'
        }}
      >
        <Fade in={isOpenModal}>
          <ContentWrapper
            sx={{
              width: contentWidth,
              height: isFixedContentHeight ? contentHeight : "auto",
              outline: 'none'
            }}
          >
            {customIcon && <Box>{customIcon}</Box>}
            {!!title?.length && (
              <Box
                sx={{
                  p: smallPadding ? 2 : 4,
                  textAlign: isCenterTitle ? "center" : "start",
                  ...(isFilledTitle && {
                    backgroundColor: 'rgb(120, 78, 78)',
                    borderTopLeftRadius: '16px',
                    borderTopRightRadius: '16px',
                  })
                }}
              >
                <Typography
                  variant='h6'
                  component='h2'
                  sx={
                    isGradientTitle
                      ? {
                          fontFamily: "Inter",
                          fontSize: "24px !important",
                          fontWeight: 700,
                          background:
                            "linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }
                      : {
                          color: isFilledTitle ? "#fff" : "#212B36",
                          fontWeight: 700,
                          fontSize: "18px",
                          lineHeight: "28px",
                          fontFamily: "Inter",
                        }
                  }
                >
                  {title}
                </Typography>
              </Box>
            )}
            {withoutScrollWrapper ? (
              <Stack sx={{ width: "100%", px: smallPaddingContent ? 2 : 5 }}>
                {children}
              </Stack>
            ) : (
              <Scrollbar
                sx={{
                  maxHeight: maxHeightForScrollbarContent
                    ? maxHeightForScrollbarContent
                    : `calc(100vh / 1.5)`,
                  width: "100%",
                  px: smallPaddingContent ? 2 : 5,
                }}
              >
                {children}
              </Scrollbar>
            )}
          </ContentWrapper>
        </Fade>
      </Modal>
    </div>
  );
};

ModalComponent.propTypes = {
  isOpenModal: PropTypes.bool,
  closeModal: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  contentWidth: PropTypes.string,
  contentHeight: PropTypes.string,
  smallPadding: PropTypes.bool,
  smallPaddingContent: PropTypes.bool,
  isCenterTitle: PropTypes.bool,
  isFixedContentHeight: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  maxHeightForScrollbarContent: PropTypes.string,
  customIcon: PropTypes.object,
  isGradientTitle: PropTypes.bool,
};
