import React, { useMemo, useRef, useState } from 'react'
import useTranslation from '../../../../localization/useTranslation'
import { Box, Stack, Card } from '@mui/material'
import {
  ContentTypeTypography,
  DateTypography,
  DescriptionBox,
  ImageContainer,
  ImgWrapper,
  InfoHeaderBox,
  KeywordsBox,
  LabelTypography,
  MoreButton,
} from '../../../../components/profileCommon/profileListTable/profileContentUser/styles'
import Image from '../../../components/image'
import { PlayingVideo } from '../../../components/carousel/published-content/PlayingVideo'

export const QueryMatchedContentCard = ({ data = {} }) => {
  const { labelStrings } = useTranslation()

  const ref = useRef(null)

  const [showMore, setShowMore] = useState(false)
  const [startVideo, setStartVideo] = useState(false)

  const imgWrapperHeight = ref?.current?.clientHeight

  const foundCaptions = useMemo(() => {
    return data?.foundCaptions
      ?.replace(/([.,!?;:])(?=\s|$)|[.\n]+/g, ' ')
      ?.replace(/\s+/g, ' ')
      ?.trim()
  }, [data?.foundCaptions])

  const btnShowMoreAction = e => {
    e.preventDefault()
    e.stopPropagation()
    setShowMore(!showMore)
  }

  const onHandlePlayVideo = e => {
    e.preventDefault()
    setStartVideo(!startVideo)
  }

  const onHandleLinkContent = e => {
    const { localName, className, nodeName } = e.target
    if (
      ['video', 'path', 'rect'].includes(localName) ||
      className === 'react-player__preview' ||
      nodeName === 'rect'
    )
      return

    const url =
      data?.contentType === 'story' && data?.user_id
        ? `${process.env.REACT_APP_PREFIX + 'profile/' + data.user_id}`
        : data.platformLink

    window.open(url, '_blank')
  }

  return (
    <Box
      className="query-matched-content-card"
      onClick={onHandleLinkContent}
      sx={{
        backgroundColor: '#FFF5F5 !important',
        borderRadius: '13px',
        width: '170px',
        m: '0 10px',
      }}
    >
      <ImageContainer sx={{ pb: '2px !important' }}>
        {data?.videoUrl ? (
          <Box sx={{ height: '270px' }}>
            <Card sx={{ width: '100%', height: '100%' }}>
              <PlayingVideo
                singleVideo={data?.videoUrl}
                singleVideoPreview={data?.imageUrl}
                contentImg={data?.imageUrl}
                startVideo={startVideo}
                setStartVideo={setStartVideo}
                onHandlePlayVideo={onHandlePlayVideo}
              />
            </Card>
          </Box>
        ) : (
          <ImgWrapper className="img-wrapper" ref={ref}>
            <Image
              sx={{
                maxWidth: '100%',
                borderRadius: '8px',
                width: '100%',
                height: 'auto',
              }}
              src={data.imageUrl}
              alt={data.username}
            />
          </ImgWrapper>
        )}
      </ImageContainer>

      <InfoHeaderBox>
        <Box sx={{ width: '50%' }}>
          <ContentTypeTypography sx={{ fontSize: '14px !important' }}>
            {data.contentType}
          </ContentTypeTypography>
        </Box>
      </InfoHeaderBox>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          py: 1,
          px: 2,
          borderTop: '1px solid #F6DBDB',
          borderBottom: '1px solid #F6DBDB',
        }}
      >
        <LabelTypography component="span">{labelStrings.uploaded}</LabelTypography>
        <DateTypography sx={{ pb: '0 !important' }}>
          {data.uploaded?.substring(0, 10)}
        </DateTypography>
      </Stack>
      <DescriptionBox>
        {imgWrapperHeight > 160 && data?.foundCaptions?.length > 260 ? (
          <>
            <KeywordsBox
              dangerouslySetInnerHTML={{
                __html: showMore ? foundCaptions : `${foundCaptions?.substring(0, 265)}...`,
              }}
            />
            <MoreButton variant="text" onClick={e => btnShowMoreAction(e)}>
              {showMore ? labelStrings.showLess : labelStrings.showMore}
            </MoreButton>
          </>
        ) : (
          <KeywordsBox dangerouslySetInnerHTML={{ __html: foundCaptions }} />
        )}
      </DescriptionBox>
    </Box>
  )
}
