import React from 'react'
import useTranslation from '../../../localization/useTranslation'
import { IG_SCORE_NAMES } from '../../../constants/profile'
import { USER_INDEXES } from '../../../constants/appSettings'
import InfludataRating from '../../qualityScores/InfludataRating'
import ComparisonToSimilarInfluencers from '../../qualityScores/ComparisonToSimilarInfluencers'
import RatingDescriptionTip from './ratingDescriptionTip/RatingDescriptionTip'
import SuspiciousActivities from './suspiciousActivities/SuspiciousActivities'
import NoDataMessage from './NoDataMessage'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../commonProfilePageStyles'
import {
  RatingSectionContainer,
  RatingSectionGrid,
  RatingSectionGridItem,
  RatingTitleWrapper,
  RatingTitle,
  RatingWrapper,
} from './commonStyles'
import { IconButton, Stack } from '@mui/material'
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded'

const Authenticity = ({ platform, suspiciousFollowersObject, suspiciousActivities }) => {
  const { labelStrings, scoreStrings } = useTranslation()
  const igScoreStrings = scoreStrings[USER_INDEXES.instagram]

  const {
    benchmarkArray,
    label: suspiciousFollowersLabel,
    score: suspiciousFollowersScore,
    xAxisHigh,
    xAxisMid,
    xAxisLow,
  } = suspiciousFollowersObject || {
    benchmarkArray: [],
    label: '',
    score: 0,
    xAxisHigh: null,
    xAxisLow: null,
    xAxisMid: null,
  }
  const xAxisValues = [xAxisHigh, xAxisMid, xAxisLow]

  const onScrollToUnlock = () => {
    const element = document.querySelector(`#profile-audience-analysis`)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <RatingSectionContainer>
      <SectionTitleWrapper>
        <SectionTitleName platform={platform}>{labelStrings.authenticity}</SectionTitleName>
        <SectionTitleBorder />
      </SectionTitleWrapper>

      <RatingSectionGrid columnsCount={2}>
        <RatingSectionGridItem>
          <RatingTitleWrapper>
            <RatingTitle>{igScoreStrings.suspiciousActivitiesFollowers.title}</RatingTitle>
            <RatingDescriptionTip
              scoreDesc={igScoreStrings[IG_SCORE_NAMES.suspiciousActivitiesFollowers].description}
            />
          </RatingTitleWrapper>

          {!!suspiciousFollowersObject ? (
            <RatingWrapper>
              <ComparisonToSimilarInfluencers
                label={labelStrings.nOfFollowersSusp(suspiciousFollowersLabel)}
                benchmarkArray={benchmarkArray}
                xAxisValues={xAxisValues}
              />
              <InfludataRating scoreValue={suspiciousFollowersScore} platform={platform} />
            </RatingWrapper>
          ) : (
            <Stack alignItems="center" justifyContent="center" sx={{ height: '200px' }} spacing={1}>
              <NoDataMessage message={labelStrings.unlockReportToShowScore} isSmall />
              <IconButton size="small" onClick={onScrollToUnlock} sx={{ width: 'fit-content' }}>
                <ArrowCircleDownRoundedIcon sx={{ color: '#784E4E', fontSize: '32px' }} />
              </IconButton>
            </Stack>
          )}
        </RatingSectionGridItem>

        <RatingSectionGridItem>
          <RatingTitleWrapper>
            <RatingTitle>{igScoreStrings.suspiciousActivities.title}</RatingTitle>
            <RatingDescriptionTip
              scoreDesc={igScoreStrings[IG_SCORE_NAMES.suspiciousActivities].description}
            />
          </RatingTitleWrapper>

          <SuspiciousActivities suspiciousActivities={suspiciousActivities} />
        </RatingSectionGridItem>
      </RatingSectionGrid>
    </RatingSectionContainer>
  )
}

export default Authenticity
